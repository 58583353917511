import { SubmittedAppData } from "types/SubmittedAppData";
import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { Offcanvas } from "react-bootstrap";
import { MessagesSection } from "./MessagesSection";

export const MobileViewMessages = ({
    applicationNumber,
    appData,
    onChanged,
    onClose,
}: {
    applicationNumber: string;
    appData: SubmittedAppData;
    onChanged?: () => void;
    onClose: () => void;
}) => {
    return (
        <Offcanvas className="messages-sidebar" show placement="end" onHide={onClose} aria-labelledby="messages-sidebar-title">
            <Offcanvas.Header className="justify-content-start border-bottom">
                <Button variant="link" className="d-flex opacity-50 text-black" title="Back" onClick={onClose} size="sm">
                    <Icon icon={["fal", "arrow-left"]} />
                </Button>
                <Offcanvas.Title id="messages-sidebar-title" as="h6">
                    Application {appData.projectName ?? appData.projectNumber}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column p-0">
                <MessagesSection appData={appData} applicationNumber={applicationNumber} onChanged={onChanged} isMobile />
            </Offcanvas.Body>
        </Offcanvas>
    );
};
