import { ContactForm } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/ContactForm";
import { PremiseContactFormProps } from "./types";
import { ContactType } from "components/utils/contacts";
import { RequireContact } from "../../../../../types/RequireContact";
import { getHiddenFieldsByContactType, getRequiredFieldsByContactType } from "components/utils/contacts";

export const PremiseContactForm = ({
    formRef,
    formData,
    extraErrors,
    requirements,
    isAppSubmitted,
    noTitle,
    onSaveClick,
    onUseCustomerContact,
    onChange,
    readonly,
}: PremiseContactFormProps) => {
    const requiredFields = getRequiredFieldsByContactType(requirements.requiredFields, ContactType.Premise);
    const hiddenFields = getHiddenFieldsByContactType(requirements.hiddenFields, ContactType.Premise);

    const hideContact = requirements.requirePremise === RequireContact.Hidden;
    if (hideContact && !isAppSubmitted) {
        return null;
    }
    return (
        <ContactForm
            idPrefix="premise-contact"
            title={noTitle ? "" : requirements.premiseDescription}
            description={requirements.premiseDescription ? "" : "Where is the work being completed?"}
            formRef={formRef}
            formData={formData}
            requiredFields={requiredFields}
            hiddenFields={hiddenFields}
            extraErrors={extraErrors}
            onUseCustomerContact={onUseCustomerContact}
            onSaveClick={onSaveClick}
            onChange={onChange}
            readonly={readonly}
        />
    );
};
