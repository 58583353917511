import { useCallback, useState } from "react";
import { isEmpty, isString } from "lodash";
import { WidgetProps } from "@rjsf/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUrl, httpPost, httpPostAuthorized } from "components/utils/http";
import { useToast } from "components/Toast";
import { Form } from "react-bootstrap";
import { isInIframe } from "components/utils/dom";
import { mask } from "components/utils/string";

export const EncryptedTextWidget: React.FC<WidgetProps> = (props) => {
    const { TextWidget } = props.registry.widgets;

    const encryptState: { current: Map<string, boolean> } = props.formContext?.encryptState;
    const { applicationNumber, taskNumber, codeNumber } = props.formContext ?? {};
    const fieldNumber = props.options?.fieldNumber;

    const encrypted = isEncrypted(props.value);
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState<string | undefined>(props.value);
    const [isMasked, setIsMasked] = useState<boolean>(encrypted);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    if (encryptState && fieldNumber && isString(fieldNumber)) {
        const isBusy = !isEmpty(inputValue) && (isLoading || !encrypted || inputValue !== props.value);

        if (isBusy) {
            encryptState.current.set(fieldNumber, true);
        } else {
            encryptState.current.delete(fieldNumber);
        }
    }

    const onChange = (value: string | undefined) => {
        if (isFocused && isMasked) {
            setIsMasked(false);
        }

        setInputValue(value);
    };

    const onBlur = (id: string, value: string) => {
        setIsFocused(false);

        if (props.readonly || props.disabled || isMaskedValue(value)) {
            return;
        }

        if ((value ?? "").trim().length === 0) {
            setIsMasked(true);
            props.onChange(undefined);

            return;
        }

        setIsLoading(true);

        encryptValue(value, applicationNumber, taskNumber, codeNumber)
            .then((result) => {
                setInputValue(result);
                setIsMasked(true);
                props.onChange(result);
            })
            .catch((error) => {
                setInputValue(undefined);
                props.onChange(undefined);
                toast.error(error.responseMessage ?? "Failed to encrypt the value");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onFocus = (id: string, value: string) => {
        setIsFocused(true);

        if (props.readonly || props.disabled) {
            return;
        }

        /* Do a onChange with the existing value to update the form save button state
         * to be disabled while editing the encrypted field.
         */
        props.onChange(props.value);
    };

    const onRef = useCallback(
        (ref: HTMLDivElement) => {
            const onInputClick = (e: MouseEvent) => {
                // select all on every click within the input if the value is only the mask
                if (e.target instanceof HTMLInputElement && e.target.id === props.id && isMaskedValue(e.target.value)) {
                    e.preventDefault();
                    e.target.select();
                }
            };

            const onInputMouseDown = (e: MouseEvent) => {
                if (e.target instanceof HTMLInputElement && e.target.id === props.id && isMaskedValue(e.target.value)) {
                    e.preventDefault();
                }
            };

            if (ref) {
                document.addEventListener("click", onInputClick);
                document.addEventListener("mousedown", onInputMouseDown);
            } else {
                document.removeEventListener("click", onInputClick);
                document.addEventListener("mousedown", onInputMouseDown);
            }
        },
        [props.id]
    );

    return (
        <div ref={onRef} className="d-flex flex-column gap-1">
            <TextWidget
                {...props}
                options={{
                    ...props.options,
                    defaultAriaDescribedBy: encrypted ? `encrypted-value-${props.id}` : undefined,
                }}
                disabled={isLoading}
                value={isMasked ? mask(inputValue ?? "") : inputValue}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                autoComplete="off"
            />
            {(encrypted || isLoading) && (
                <Form.Text className="text-muted" id={`encrypted-value-${props.id}`} role="status" aria-atomic="true">
                    <span>
                        {isFocused && !isEncrypted(inputValue ?? "") ? (
                            <>
                                <FontAwesomeIcon icon={["far", "unlock"]} fixedWidth /> Enter value to encrypt
                            </>
                        ) : (
                            <>
                                {isLoading ? (
                                    <>
                                        <FontAwesomeIcon icon={["fal", "arrows-rotate"]} spin fixedWidth /> Encrypting...
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={["far", "lock"]} fixedWidth /> Value is encrypted
                                    </>
                                )}
                            </>
                        )}
                    </span>
                </Form.Text>
            )}
        </div>
    );
};

const isEncrypted = (value: string) => {
    return (value ?? "").trim().startsWith("===");
};

const isMaskedValue = (value: string) => {
    return value.length > 0 && value.split("•").join("") === "";
};

const encryptValue = async (value: string, applicationNumber?: string, taskNumber?: number, codeNumber?: number): Promise<string> => {
    // Simulate a request in preview mode.
    if (isInIframe()) {
        return await new Promise((resolve) => setTimeout(() => resolve("===" + value), 1000));
    }

    if (applicationNumber && taskNumber && codeNumber) {
        const url = getUrl(process.env.REACT_APP_ANONYMOUS_APPLICATION_ENCRYPT_ENDPOINT, { applicationNumber, taskNumber, codeNumber });
        if (!url) throw new Error("Invalid anonymous application url");

        return httpPost(url + `?stringValue=${value}`, undefined, "encrypt_form_field");
    }

    return httpPostAuthorized(process.env.REACT_APP_APPLICATION_ENCRYPT_ENDPOINT + `?stringValue=${value}`);
};
