import cn from "classnames";
import { Button } from "components/Button";
import { createContext, useContext, useMemo, useRef, useState } from "react";
import { EquipmentCatalog, EquipmentCategory } from "./types";
import { EquipmentAddSubmitItem } from "types/EquipmentAddSubmitItem";
import { CategoriesBreadcrumb } from "./CategoriesBreadcrumb";
import { useEquipmentCategories } from "./useEquipmentCategories";
import { useEquipmentCatalogs } from "./useEquipmentCatalogs";
import { Catalogs } from "./Catalogs";
import { Categories } from "./Categories";
import { useEquipmentDetailsFromCatalog } from "./useEquipmentDetails";
import { getBreadcrumbItems } from "./utils";
import { Equipment } from "./Equipment";
import Form from "@rjsf/core";
import { ErrorSummary } from "components/ErrorSummary";
import { submitByRefPromise } from "components/JsonForm/utils";
import { isNil, omit } from "lodash";
import { Offcanvas, Spinner, Stack } from "react-bootstrap";
import { createId } from "components/utils/string";
import { useApprovedEquipmentCategories } from "./useApprovedEquipmentList";
import { ApprovedEquipmentSearch } from "./ApprovedEquipmentSearch";
import { SubmitButton } from "components/Button/SubmitButton";
import { EquipmentBlockContext } from "components/utils/contexts";
import { useCatalogNavigationAnalytics } from "./useCatalogNavigationAnalytics";
import { EquipmentLabel } from "components/Page/SubmittedApplicationV2/ApplicationEquipment/EquipmentLabel";
import useMediaQuery from "components/utils/useMediaQuery";

export const OffcanvasContext = createContext<{
    setIsWidePanel: (isWide: boolean) => void;
}>({
    setIsWidePanel: (isWide: boolean) => {},
});

export const EquipmentAdd = () => {
    const { applicationNumber, onEquipmentAddSubmit, onEquipmentAddClose, isWorkflowTask, task } = useContext(EquipmentBlockContext);
    const [activeCatalog, setActiveCatalog] = useState<EquipmentCatalog>();
    const [parentCategories, setParentCategories] = useState<EquipmentCategory[]>([
        {
            category: "Catalog Home",
            categorynumber: undefined,
            childcount: 1,
            parentcategorynumber: null,
        },
    ]);
    const isMobile = useMediaQuery("(max-width: 768px)");

    const [isWidePanel, setIsWidePanel] = useState<boolean>(false);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorSummary, setErrorSummary] = useState<any>();
    const formRef = useRef<Form<any>>();

    const activeCategory = parentCategories[parentCategories.length - 1];
    const catalogCategoryNumber = activeCategory.childcount === 0 ? activeCategory.categorynumber : undefined;
    const breadcrumb = getBreadcrumbItems(parentCategories, activeCategory);

    const [categories = [], isLoadingCategories] = useEquipmentCategories(applicationNumber, activeCategory.categorynumber);
    const [catalogs = [], isLoadingCatalogs] = useEquipmentCatalogs(applicationNumber, catalogCategoryNumber);
    const [approvedEquipmentCategories = [], isLoadingApprovedEquipmentCategories] = useApprovedEquipmentCategories(
        applicationNumber,
        activeCatalog?.catalogNumber
    );
    const [equipmentDetails, isLoadingEquipmentDetails, , isValidating] = useEquipmentDetailsFromCatalog(
        applicationNumber,
        activeCatalog?.catalogNumber
    );
    const isLoading =
        isLoadingCategories || isLoadingCatalogs || isLoadingApprovedEquipmentCategories || (isLoadingEquipmentDetails && !isValidating);

    const showApprovedEquipmentSearch = !isLoading && equipmentDetails && approvedEquipmentCategories.length > 0;
    const showEquipmentDetails = !isLoading && !showApprovedEquipmentSearch && !isNil(equipmentDetails);
    const showCatalog = !isLoading && !showEquipmentDetails && !showApprovedEquipmentSearch;

    const onBreadcrumbClick = (index: number) => {
        if (index + 1 === breadcrumb.length) {
            return;
        }

        setParentCategories((prev) => prev.slice(0, index + 1));
        setActiveCatalog(undefined);
        onCatalogNavigation();
    };

    const onCategoryClick = (category: EquipmentCategory) => {
        setParentCategories((prev) => prev.concat([category]));
        setActiveCatalog(undefined);
        onCatalogNavigation();
    };

    const onCatalogClick = (catalog: EquipmentCatalog) => {
        setActiveCatalog(catalog);
        onCatalogNavigation();
    };

    const onApprovedEquipmentSearchClose = () => {
        setActiveCatalog(undefined);
    };

    const onCatalogNavigation = () => {
        let element;
        if (showApprovedEquipmentSearch) {
            element = document.getElementsByClassName("approved-equipment-search")[0] as HTMLElement;
        } else {
            element = document.getElementsByClassName("offcanvas-body")[0] as HTMLElement;
        }
        if (element) {
            element.setAttribute("tabindex", "-1");
            element.focus();
        }
    };

    const onAddEquipment = async () => {
        try {
            // Validate equipment form
            const formData = await submitByRefPromise(formRef);
            const catalogNumber = activeCatalog?.catalogNumber;

            if (applicationNumber && catalogNumber) {
                const equipmentItem: EquipmentAddSubmitItem = {
                    equipID: createId(),
                    catalogNumber,
                    quantity: formData.quantity,
                    attributes: Object.keys(omit(formData, "quantity")).map((key: string) => ({
                        attributename: key,
                        attributevalue: String(formData[key] ?? ""),
                    })),
                };

                setIsSubmitting(true);
                if (onEquipmentAddSubmit) {
                    await onEquipmentAddSubmit(equipmentItem);
                }
            }
        } catch (error) {
            setErrorSummary(error);
            setIsSubmitting(false);
        }
    };

    const contextValue = useMemo(
        () => ({
            setIsWidePanel,
        }),
        []
    );

    useCatalogNavigationAnalytics(breadcrumb, activeCatalog);

    return (
        <OffcanvasContext.Provider value={contextValue}>
            <Offcanvas
                className={cn("equipment-panel", {
                    "offcanvas-75": isWidePanel && !isMobile,
                })}
                show
                placement="end"
                backdrop="false"
                onHide={onEquipmentAddClose}
                aria-labelledby="add-equipment-title"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title aria-hidden="true" id="add-equipment-title" className="fs-4">
                        {isWorkflowTask ? "My to-do task" : "Add Equipment"}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                {isWorkflowTask && (
                    <>
                        <p className="m-0 px-3 py-2 fw-medium">Add equipment measure</p>
                        <p className="m-0 px-3 py-2 fw-medium">{task?.step}</p>
                        {task?.content && <p className="m-0 px-3 py-2">{task.content}</p>}
                    </>
                )}
                {!isLoading && (
                    <div className="px-3">
                        <CategoriesBreadcrumb aria-hidden="true" items={breadcrumb} onClick={onBreadcrumbClick} />
                    </div>
                )}
                {showApprovedEquipmentSearch ? (
                    <ApprovedEquipmentSearch
                        equipmentTypes={approvedEquipmentCategories}
                        equipmentDetails={equipmentDetails}
                        catalogNumber={activeCatalog?.catalogNumber!}
                        onClose={onApprovedEquipmentSearchClose}
                        activeCatalog={activeCatalog}
                    />
                ) : (
                    <Offcanvas.Body className="d-flex flex-column">
                        <ErrorSummary errorSummary={errorSummary} />
                        {activeCatalog && !isLoading && (
                            <div className="pb-2">
                                <EquipmentLabel equipment={activeCatalog} />
                                <hr />
                            </div>
                        )}
                        {showCatalog && (
                            <div className="equipment-categories mb-2">
                                <Categories items={categories} onClick={onCategoryClick} />
                                <Catalogs items={catalogs} onClick={onCatalogClick} />
                            </div>
                        )}
                        {isLoading && (
                            <Spinner className="align-self-center flex-shrink-0" animation="border" role="status">
                                <span className="visually-hidden">Loading equipment catalog...</span>
                            </Spinner>
                        )}
                        {showEquipmentDetails && <Equipment formRef={formRef} item={equipmentDetails} isNewEquipment />}
                        <Stack className="justify-content-start mt-auto equipment-controls mt-4" direction="horizontal">
                            {showEquipmentDetails && (
                                <SubmitButton
                                    onClick={onAddEquipment}
                                    isSubmitting={isSubmitting}
                                    spinnerText={isWorkflowTask ? "Submitting measure..." : "Adding equipment..."}
                                    className="me-3"
                                >
                                    {isWorkflowTask ? "Submit measure" : "Add Equipment"}
                                </SubmitButton>
                            )}
                            <Button secondaryButtonType="filled" variant="secondary" disabled={isSubmitting} onClick={onEquipmentAddClose}>
                                Cancel
                            </Button>
                        </Stack>
                    </Offcanvas.Body>
                )}
            </Offcanvas>
        </OffcanvasContext.Provider>
    );
};
