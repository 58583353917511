import { Heading } from "components/Heading";
import { Button } from "components/Button";
import { formatMoney } from "components/utils/money";
import cn from "classnames";
import { CustomerDashboardModel, FilterButtonState } from "./types";
import NotificationsMenu from "./NotificationsMenu";
import { useColor } from "components/utils/color";
import Color from "color";
import { useIsMobileFilters } from "./useIsMobileFilters";
import { AppContext } from "components/App/AppContext";
import { useContext } from "react";

export const DashboardHeader = ({ model }: { model: CustomerDashboardModel }) => {
    const isMobile = useIsMobileFilters();

    return isMobile ? <DashboardHeaderForMobile model={model} /> : <DashboardHeaderForDesktop model={model} />;
};

export const DashboardHeaderForMobile = ({ model }: { model: CustomerDashboardModel }) => {
    return (
        <div className="dashboard-header bg-gray-200">
            <div className="d-flex flex-column gap-3 py-3 container-xxl overflow-hidden">
                <div className="d-flex flex-row align-items-center">
                    <span className="total-payments-money h6 fs-1 fw-light m-0 text-body">{formatMoney(model.totalRebate)}</span>
                    <p className="total-payments-label m-0 ms-2 lh-1">
                        Total <br />
                        payments
                    </p>
                </div>
                <hr className="m-0" />
                <Heading className="flex-shrink-0">My Applications</Heading>
                <ToggleButtons className="align-self-start mw-100 overflow-auto" model={model} />
            </div>
        </div>
    );
};

export const DashboardHeaderForDesktop = ({ model }: { model: CustomerDashboardModel }) => {
    const { settings } = useContext(AppContext);
    return (
        <div
            className="dashboard-header d-flex align-items-center gap-2 py-3 bg-gray-200 container-xxl px-md-4"
            style={{ maxWidth: settings.maxWidth }}
        >
            <div className="d-flex flex-column w-100">
                <div className="d-flex flex-row justify-content-between align-items-end mb-2 flex-grow-1">
                    <Heading className="mb-0 flex-shrink-0">My Applications</Heading>
                    <NotificationsMenu
                        notificationCount={model.totalNotifications}
                        totalNotes={model.totalNotes}
                        totalTasks={model.totalTasks}
                    />
                </div>

                <div className="d-flex flex-row justify-content-between align-items-center flex-grow-1">
                    <ToggleButtons model={model} />

                    <div className="d-flex align-items-center">
                        <p className="m-0 text-end me-2 lh-1">
                            Total <br />
                            payments
                        </p>
                        <div className="h6 fs-1 fw-light m-0 text-body p-0 lh-1">{formatMoney(model.totalRebate)}</div>
                    </div>
                </div>
            </div>
            {model.totalNotifications > 0 && (
                <span
                    aria-hidden="true"
                    className="dashboard-notifications-counter translate-middle-y badge rounded-pill fs-7 bg-danger text-white mb-auto mt-2"
                >
                    {model.totalNotifications}
                </span>
            )}
        </div>
    );
};

const ToggleButtons = ({ className, model }: { className?: string; model: CustomerDashboardModel }) => {
    return (
        <div className={cn(className, "filter-toggle-buttons d-flex flex-row gap-1 align-items-center bg-white p-2 rounded-pill")}>
            <ToggleButton
                text="All"
                count={model.applicationCount}
                active={model.filterButtonState === FilterButtonState.AllApps}
                onClick={() => model.onFilterButtonStateChange(FilterButtonState.AllApps)}
            />
            <ToggleButton
                text="Bookmarked"
                count={model.favoriteApplicationsCount}
                active={model.filterButtonState === FilterButtonState.FavoriteApps}
                onClick={() => model.onFilterButtonStateChange(FilterButtonState.FavoriteApps)}
            />
            <ToggleButton
                text="Incomplete"
                count={model.savedApplicationsCount}
                active={model.filterButtonState === FilterButtonState.IncompleteApps}
                onClick={() => model.onFilterButtonStateChange(FilterButtonState.IncompleteApps)}
            />
        </div>
    );
};

const ToggleButton = ({
    text,
    count,
    active,
    onClick,
}: {
    text: string;
    count: number;
    active?: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
    const primaryColor = useColor("primary");
    const isPrimaryColorLight = Color(primaryColor).isLight();
    const activeTextColor = isPrimaryColorLight ? "var(--bs-dark)" : "var(--bs-white)";
    const textColor = isPrimaryColorLight ? "var(--bs-dark)" : "var(--bs-primary)";

    return (
        <Button
            variant="outline-primary"
            size="sm"
            className={cn("rounded-pill d-flex flex-row gap-2 align-items-center", {
                active,
            })}
            style={
                {
                    "--bs-btn-border-color": "var(--bs-primary)",
                    "--bs-btn-color": textColor,
                    "--bs-btn-hover-bg": "var(--bs-primary)",
                    "--bs-btn-hover-color": activeTextColor,
                    "--bs-btn-active-bg": "var(--bs-primary)",
                    "--bs-btn-active-color": activeTextColor,
                    "--bs-btn-active-border-color": "var(--bs-primary)",
                    "--bs-btn-hover-border-color": "var(--bs-primary)",
                    "--bs-btn-focus-shadow-rgb": "var(--bs-primary-rgb)",
                } as React.CSSProperties
            }
            aria-pressed={active ? "true" : "false"}
            onClick={onClick}
            data-metadata={JSON.stringify({ name: text })}
        >
            {text}
            <span className={cn("badge bg-gray-200 text-body fs-8")}>{count}</span>
        </Button>
    );
};
