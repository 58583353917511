import { isEmpty, pick } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { DataGridFilter } from "./types";
import { isFilterChanged, isFilterEmpty } from "./utils";

export const useFilter = (
    filterString: string = "",
    onFilterChange: (filterString: string) => void,
    notClearableFilterKeys: string[] | undefined
) => {
    const [filter, setFilter] = useState<DataGridFilter>(getFilterObject(filterString));

    useEffect(() => {
        setFilter(getFilterObject(filterString));
    }, [filterString]);

    return useMemo(() => {
        const onFilterSubmit = (filterValue?: DataGridFilter) => {
            onFilterChange(getFilterString(filterValue ?? filter));
        };

        const onFilterClear = () => {
            const filterObj = pick(filter, notClearableFilterKeys ?? []);
            setFilter(filterObj);
            onFilterChange(getFilterString(filterObj));
        };

        const onFilterCancel = () => {
            setFilter(getFilterObject(filterString));
        };

        const onFilterValueChange = (key: string, value: string, submitFilter: boolean = false) => {
            const filterValue: DataGridFilter = {
                ...(filter ?? {}),
                [key]: value,
            };

            setFilter(filterValue);

            if (submitFilter) {
                onFilterSubmit(filterValue);
            }
        };

        const onFilterValueKeyDown = (key: string, event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (event.key === "Enter") {
                event.preventDefault();
                const value = (event.target as HTMLInputElement).value;

                const filterValue: DataGridFilter = {
                    ...(filter ?? {}),
                    [key]: value,
                };

                setFilter(filterValue);
                onFilterSubmit(filterValue);
            }
        };

        const getFilterValue = (key: string) => {
            return filter[key] ?? "";
        };

        return {
            isFilterEmpty: isFilterEmpty(filter, notClearableFilterKeys),
            isFilterChanged: isFilterChanged(filter, notClearableFilterKeys),
            getFilterValue,
            onFilterValueChange,
            onFilterValueKeyDown,
            onFilterSubmit,
            onFilterClear,
            onFilterCancel,
        };
    }, [filter, filterString, notClearableFilterKeys, onFilterChange]);
};

const getFilterObject = (filterString: string): DataGridFilter => {
    if (isEmpty(filterString)) {
        return {};
    }

    return filterString
        .split("|")
        .filter((p: string) => !isEmpty(p))
        .reduce((result, next) => {
            const [key, value] = next.split("=");

            return {
                ...result,
                [key]: value,
            };
        }, {});
};

const getFilterString = (filterObj: DataGridFilter) => {
    if (isEmpty(filterObj)) {
        return "";
    }

    return Object.entries(filterObj)
        .filter(([key, value]) => !isEmpty(value))
        .map(([key, value]) => `${key}=${value}`)
        .join("|");
};
