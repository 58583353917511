import { useContext, useRef, useState, useMemo, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { capitalize, isEmpty, isNil } from "lodash";
import { Container } from "components/Layout/Container";
import { AppContext } from "components/App/AppContext";
import { ErrorSummary } from "components/ErrorSummary";
import { httpPostAuthorized } from "components/utils/http";
import { usePageTopAndBottomRows } from "components/utils/page";
import { PageLink } from "components/App/utils";
import { PageComponentsList } from "..";
import { JsonForm } from "components/JsonForm";
import { AnalyticsEventType, sendAnalyticsEvent } from "components/utils/analytics";
import { submitByRef } from "components/JsonForm/utils";
import { Heading } from "components/Heading";
import { HeadingLevel } from "types/HeadingLevel";

import "./PrescreenPage.scss";

const formName = "Prescreen";

export function PrescreenPage() {
    const {
        programNumber,
        requirements: { prescreenRequiredFields },
    } = useContext(AppContext);

    const location = useLocation();
    const history = useHistory();
    const { topRows: introductionRows, bottomRows: errorRows } = usePageTopAndBottomRows();
    const formRef = useRef();
    const [errorSummary, setErrorSummary] = useState<any>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const redirectTimeoutRef = useRef<number>();

    const showDefaultHeading = isEmpty(introductionRows);

    const onRefSet = useCallback((ref: HTMLDivElement | null) => {
        if (ref) {
            sendAnalyticsEvent(AnalyticsEventType.FORM_OPEN, { formName, isApplicationForm: true });
        }
    }, []);
    useEffect(() => {
        return () => {
            if (!isNil(redirectTimeoutRef.current)) {
                clearInterval(redirectTimeoutRef.current);
            }
        };
    }, []);

    const onSubmit = async (form: any) => {
        try {
            setIsSubmitting(true);
            const { formData } = form;

            const pageNumber = new URLSearchParams(location.search).get("pageNumber");
            const query = new URLSearchParams();
            query.append("programNumber", String(programNumber));
            query.append("pageNumber", String(pageNumber));

            const response = await httpPostAuthorized(`${process.env.REACT_APP_APPLICATION_PRESCREEN_ENDPOINT}?${query.toString()}`, {
                searchList: Object.keys(formData).map((k) => ({
                    columnName: k,
                    columnValue: formData[k],
                })),
                page: 1,
                rows: 5,
            });
            setIsSubmitting(false);
            sendAnalyticsEvent(AnalyticsEventType.FORM_SUBMIT, { formName, isApplicationForm: true });

            history.replace(`${PageLink.Application}?applicationNumber=${response.number}&pageNumber=${pageNumber}`);
        } catch (error: any) {
            if (!isNil(error) && error.responseMessage?.includes("Users must wait 30 seconds")) {
                let timeleft = 30;

                redirectTimeoutRef.current = window.setInterval(function () {
                    const element = document?.getElementById("countdowntimer");
                    timeleft--;

                    if (element?.textContent) {
                        element.textContent = String(timeleft);
                    }

                    if (timeleft <= 0 && !isNil(element)) {
                        submitByRef(formRef);
                        clearInterval(redirectTimeoutRef.current);
                    }
                }, 1000);
            }

            setErrorSummary(error);
            setIsSubmitting(false);
            sendAnalyticsEvent(AnalyticsEventType.FORM_ERROR, { formName, isApplicationForm: true });
        }
    };

    const onError = (error: any) => {
        sendAnalyticsEvent(AnalyticsEventType.FORM_ERROR, { formName, isApplicationForm: true });
    };

    const getLabel = (fieldName: string) => {
        switch (fieldName) {
            case "acct_number":
                return "Account Number";
            case "address":
            case "addressnosuffix":
                return "Address";
            case "meterid":
                return "Meter ID";
            case "phone":
                return "Phone Number";
            case "zip":
                return "Postal Code";
            default:
                return (fieldName || "").split("_").map(capitalize).join(" ");
        }
    };

    const userNameSchema = useMemo(
        () => ({
            type: "object",
            required: prescreenRequiredFields?.map((f) => f.requiredField),
            properties: prescreenRequiredFields?.reduce(
                (result, currentValue) => ({
                    ...result,
                    [currentValue?.requiredField]: { type: "string", title: getLabel(currentValue.requiredField) },
                }),
                {}
            ),
        }),
        [prescreenRequiredFields]
    );

    const showRedirectBlock = !isNil(errorSummary) && errorSummary.responseMessage?.includes("Users must wait 30 seconds");

    const RedirectBlock = () => (
        <p>
            Redirecting to Application Form after <span id="countdowntimer">30 </span> Seconds
        </p>
    );

    return (
        <Container className="p-0">
            {showDefaultHeading && (
                <Heading className="text-center" level={HeadingLevel.H1}>
                    Account Information
                </Heading>
            )}
            <ErrorSummary errorSummary={errorSummary} />
            {!errorSummary && <PageComponentsList components={introductionRows} />}
            {errorSummary && <PageComponentsList components={errorRows} />}
            {showRedirectBlock && <RedirectBlock />}
            <div ref={onRefSet} className="standard-form m-auto" style={showRedirectBlock ? { display: "none" } : {}}>
                <JsonForm
                    formRef={formRef}
                    disabled={isSubmitting}
                    schema={userNameSchema}
                    onSubmit={onSubmit}
                    onError={onError}
                    isSubmitting={isSubmitting}
                    noAnalytics
                />
            </div>
        </Container>
    );
}
