import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Heading } from "components/Heading";
import { ErrorSummary } from "components/ErrorSummary";
import { JsonForm } from "components/JsonForm";
import { usePasswordRequirements, userResetPassword } from "components/utils";
import { SuccessPage } from "components/Page/SuccessPage";
import { Container } from "components/Layout/Container";
import { usePageTopAndBottomRows } from "components/utils/page";
import { PageComponentsList } from ".";

export function ResetPasswordPage(props) {
    const history = useHistory();
    const submitted = history?.location?.state?.submitted === true;
    const pathParts = (window.location.pathname ?? "").split("/");
    const customerNumber = pathParts[2];
    const temporaryPassword = pathParts[3];
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorSummary, setErrorSummary] = useState(undefined);
    const { topRows, bottomRows } = usePageTopAndBottomRows();
    const [passwordRequirements, isLoading] = usePasswordRequirements();

    const schema = {
        type: "object",
        required: ["newPassword", "repeatedPassword"],
        properties: {
            newPassword: {
                type: "string",
                title: "New Password",
            },
            repeatedPassword: {
                type: "string",
                title: "Confirm New Password",
            },
        },
    };

    const uiSchema = {
        newPassword: {
            "ui:widget": "password",
            "ui:help": !isLoading && passwordRequirements,
        },
        repeatedPassword: {
            "ui:widget": "password",
        },
    };

    function validatePassword(formData, errors) {
        if (formData.newPassword !== formData.repeatedPassword) {
            errors.repeatedPassword.addError("Passwords don't match");
        }
        return errors;
    }

    const onSubmit = useCallback(
        ({ formData }) => {
            setErrorSummary(undefined);
            setIsSubmitting(true);

            userResetPassword({
                id: customerNumber,
                temporaryPassword: temporaryPassword,
                newPassword: formData.newPassword,
            })
                .then(() => history.replace({ pathName: "customerReset", state: { submitted: true } }))
                .catch((error) => {
                    setErrorSummary(error);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        },
        [history, customerNumber, temporaryPassword]
    );

    return submitted ? (
        <SuccessPage text="Password successfully changed" />
    ) : (
        <>
            <Container className="p-0">
                <Heading>Reset Password</Heading>
            </Container>
            <PageComponentsList components={topRows} />
            <Container className="p-0">
                <ErrorSummary errorSummary={errorSummary} />
                <JsonForm
                    schema={schema}
                    uiSchema={uiSchema}
                    validate={validatePassword}
                    onSubmit={onSubmit}
                    isSubmitting={isSubmitting}
                ></JsonForm>
            </Container>
            <PageComponentsList components={bottomRows} />
        </>
    );
}
