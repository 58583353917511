import { getHostName, PageLink } from "components/App/utils";
import { ErrorSummary } from "components/ErrorSummary";
import { Heading } from "components/Heading";
import { JsonForm } from "components/JsonForm";
import { isEmail, isPhoneNumber } from "components/utils/validation";
import { Container } from "components/Layout/Container";
import { getSessionStorageItem } from "components/utils";
import { httpPost } from "components/utils/http";
import { usePageTopAndBottomRows } from "components/utils/page";
import { isEmpty } from "lodash";
import { useCallback, useContext, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { PageComponentsList, PageContext } from ".";
import { REQUIRED_FIELD_ERROR_TEXT } from "components/utils/validation";

export function ContactUsPage() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorSummary, setErrorSummary] = useState();
    const history = useHistory();
    const submitted = history?.location?.state?.submitted === true;
    const isUserLoggedIn = !isEmpty(getSessionStorageItem("user"));
    const initialData = useMemo(
        () => ({ email: isUserLoggedIn ? JSON.parse(getSessionStorageItem("user"))?.email : undefined }),
        [isUserLoggedIn]
    );
    const { props } = useContext(PageContext);

    const contactUsFormOn = props?.contactUsFormOn ?? true;
    const { contactUsText, contactUsTextOn } = props ?? {};
    const { topRows, bottomRows } = usePageTopAndBottomRows();

    const customValidate = (formData, errors) => {
        const message = "Either email or phone needs to be provided";
        if (!formData.email?.trim() && !formData.phone?.trim()) {
            errors.email.addError(message);
            errors.phone.addError(message);
        }
        if (formData.phone && !isPhoneNumber(formData.phone)) {
            const message = "Enter phone number in the correct format, like (000) 000-000 or 000-000-0000";
            errors.phone.addError(message);
        }
        if (formData.name && isEmpty(formData.name?.trim())) {
            const message = REQUIRED_FIELD_ERROR_TEXT;
            errors.name.addError(message);
        }
        if (formData.contactMessage && isEmpty(formData.contactMessage?.trim())) {
            const message = REQUIRED_FIELD_ERROR_TEXT;
            errors.contactMessage.addError(message);
        }
        if (!isEmpty(formData.email?.trim()) && !isEmail(formData.email?.trim())) {
            const message = "Enter an email address in the correct format, like name@example.com";
            errors.email.addError(message);
        }
        return errors;
    };

    const schema = {
        type: "object",
        required: ["name", "contactMessage"],
        properties: {
            name: {
                type: "string",
                title: "Your Name",
            },
            email: {
                type: "string",
                title: "Email Address",
            },
            phone: {
                type: "string",
                title: "Phone",
            },
            contactMessage: {
                type: "string",
                title: "Question/Comment",
                maxLength: 1000,
            },
        },
    };

    const uiSchema = {
        contactMessage: {
            "ui:widget": "textarea",
        },
    };

    const onSubmit = useCallback(
        async ({ formData }) => {
            try {
                setErrorSummary(undefined);
                setIsSubmitting(true);

                const body = {
                    ...formData,
                    contactMessage: formData.contactMessage?.trim(),
                    email: formData.email?.trim(),
                    name: formData.name?.trim(),
                    phone: formData.phone?.trim(),
                    hostname: getHostName(),
                };

                await httpPost(process.env.REACT_APP_CONTACT_US_ENDPOINT, body, "contact_us");

                history.replace({ pathName: PageLink.ContactUs, state: { submitted: true } });
            } catch (error) {
                setErrorSummary(error);
            } finally {
                setIsSubmitting(false);
            }
        },
        [history]
    );

    if (submitted) {
        return (
            <Container className="p-0">
                <div className="m-auto text-center thank-you-text mt-4">
                    {!!props?.thankYouTitle && <Heading>{props?.thankYouTitle}</Heading>}
                    <div
                        className="mb-4 mt-2"
                        dangerouslySetInnerHTML={{
                            __html:
                                props?.thankYouContent ??
                                "Your query has been received. Please note, we are experiencing a high volume of enquiries. We will aim to respond to you within 5 working days.",
                        }}
                    ></div>
                    <Link to="/">Return to Home Page</Link>
                </div>
            </Container>
        );
    }

    return (
        <>
            <Container className="p-0">
                <Heading>Contact us</Heading>
            </Container>
            <PageComponentsList components={topRows} />
            <Container className="p-0">
                <ErrorSummary errorSummary={errorSummary} />
                {contactUsTextOn && contactUsText && <div className="mb-4 mt-4" dangerouslySetInnerHTML={{ __html: contactUsText }}></div>}
                {contactUsFormOn && (
                    <JsonForm
                        validate={customValidate}
                        schema={schema}
                        formData={initialData}
                        formName="Contact us"
                        uiSchema={uiSchema}
                        disabled={isSubmitting}
                        onSubmit={onSubmit}
                        isSubmitting={isSubmitting}
                    />
                )}
            </Container>
            <PageComponentsList components={bottomRows} />
        </>
    );
}
