import { toArray } from "components/utils/array";
import { dateToJson, localizeJsonDate } from "components/utils/date";
import { dispatchEvent } from "components/utils/dom";
import { CURRENCY_SYMBOL } from "components/utils/money";
import { cloneDeep, get, isEmpty, isFunction, isNil, isObject, isString, orderBy, set, unset } from "lodash";
import {
    validateCheckboxesField,
    validateCheckboxField,
    validateDateField,
    validateEquipmentBlock,
    validateInputTypeNumber,
    validatePattern,
    validateSignatureField,
    validateWorkflowField,
} from "./validation";
import rulesRunner from "./conditionals/rulesRunner";
import Engine from "json-rules-engine-simplified";

export const DEFAULT_DROPDOWN_PLACEHOLDER = "-- SELECT --";

export const ElementTypes = {
    PAGE: "page",
    SECTION: "section",
    COLUMN: "column",
    ROW: "row",
    FIELD: "field",
    UNKNOWN: "unknown",
};

export const SUBMITTED_APP_FORM_WIDGETS_TO_REMOVE = [
    "applicationcontacts",
    "additionalcontacts",
    "equipmentblock",
    "auditequipmentblock",
    "workflow",
];

/**
 * Construct form data object with property keys from from uiSchema.
 *
 * @param {{ schema: object, uiSchema: object, formData: object }} form Form configuration and data
 * @param {string} [keyName="ui:key"] Element key in uiSchema to use as a property key for returned data
 * @returns {Object.}
 */
export const flattenFormData = (form, keyName = "ui:key") => {
    const { schema, uiSchema, formData } = form;

    let keysFound = false;
    const fieldKeys = getSchemaIds(schema).reduce((result, next) => {
        const path = `${next}.${keyName}`;
        const key = get(uiSchema, path);

        if (key !== undefined) {
            keysFound = true;
            const value = get(formData, next);

            // Add undefined values to know which fields were cleared
            if (value !== undefined || keyName === "af:fieldNumber") {
                result[key] = value;
            }
        }

        return result;
    }, {});

    return keysFound ? fieldKeys : formData;
};

/**
 * Get list of all form element identifiers.
 *
 * @param {object} schema Form schema
 * @param {boolean} [fieldsOnly=false] Return only field ids, otherwise include sections and columns.
 * @returns {{ type: string, title: string, id: string }[]}
 */
export const getSchemaIds = (schema, fieldsOnly = false) => {
    if (!isObject(schema) || !isObject(schema.properties)) {
        return [];
    }

    const properyNames = (properties) => {
        return isObject(properties)
            ? Object.keys(properties)
                  .map((key) => {
                      const childNames = properyNames(properties[key].properties || {}).map((name) => `${key}.${name}`);

                      return [].concat([key], childNames);
                  })
                  .reduce((result, next) => result.concat(next), [])
            : [];
    };

    let result = properyNames(schema.properties);

    if (fieldsOnly) {
        result = result
            .map((id) => {
                const pathParts = id.split(".");

                const path = `properties.${pathParts.join(".properties.")}`;
                const elementSchema = get(schema, path);

                if (elementSchema.type && elementSchema.type !== "object") {
                    return {
                        type: elementSchema.type,
                        title: elementSchema.title,
                        id: id,
                    };
                }

                return null;
            })
            .filter((id) => id !== null);
    }

    return result;
};

/**
 * Removes disabled and administrative fields, empty columns and empty section from the form.
 *
 * @param {{ schema: object, uiSchema: object, rules: [object], visibleFieldIds: [string], removeWidgets: [string] }}
 * @returns {{ schema: object, uiSchema: object, rules: [object], formFieldCount: number }}
 */
export const removeDisabledFields = ({ schema, uiSchema, rules = [], visibleFieldIds = [], removeWidgets = [] }) => {
    let updatedSchema = cloneDeep(schema);
    let updatedUiSchema = cloneDeep(uiSchema);
    let updatedRules = cloneDeep(rules);

    let formFieldCount = 0;

    const removeEmptyContainers = ({ elementId, form }) => {
        const elementSchema = getElementSchema({ schema: form.schema, elementId });

        // Remove if element is empty
        if (Object.keys(elementSchema?.properties ?? {}).length === 0) {
            form = removeElementById({ elementId, ...form });

            const parent = getParentSchemasById({ elementId, ...form });
            const parentElementType = parent?.uiSchema["ui:elementType"];

            // Do not remove whole page. Just sections and columns
            if ([ElementTypes.COLUMN, ElementTypes.SECTION].includes(parentElementType)) {
                form = removeEmptyContainers({
                    elementId: elementId.split(".").slice(0, -1).join("."),
                    form,
                });
            }
        }

        return form;
    };

    getSchemaIds(schema).forEach((id) => {
        const fieldId = get(uiSchema, `${id}.af:fieldId`);
        const fieldNumber = get(uiSchema, `${id}.af:fieldNumber`);
        const elementType = get(uiSchema, `${id}.ui:elementType`);
        const isDisabled = get(uiSchema, `${id}.af:status`) === "disabled";
        // Due to V4 and V5 incompatibilities, af:fieldGroup could be a string or an integer
        const isAdministrative = parseInt(get(uiSchema, `${id}.af:fieldGroup`), 10) === 42;
        const widget = get(uiSchema, `${id}.ui:widget`);

        // Remove field if disabled or administrative, not in list of visible fields or is in widgets to remove list
        const removeField =
            isDisabled ||
            isAdministrative ||
            (visibleFieldIds.length > 0 &&
                elementType === ElementTypes.FIELD &&
                !visibleFieldIds.includes(fieldId) &&
                !visibleFieldIds.includes(fieldNumber)) ||
            (removeWidgets.length > 0 && elementType === ElementTypes.FIELD && removeWidgets.includes(widget));

        if (!removeField && elementType === ElementTypes.FIELD) {
            // Increment field Count if field stays in form
            formFieldCount = formFieldCount + 1;
        }

        // Remove field
        if (removeField) {
            let updatedForm = removeElementById({
                elementId: id,
                schema: updatedSchema,
                uiSchema: updatedUiSchema,
                rules: updatedRules,
            });

            // Remove empty parent elements
            updatedForm = removeEmptyContainers({
                elementId: id.split(".").slice(0, -1).join("."),
                form: updatedForm,
            });

            updatedSchema = updatedForm.schema;
            updatedUiSchema = updatedForm.uiSchema;
            updatedRules = updatedForm.rules;
        }

        // Remove empty container elements
        if ([ElementTypes.COLUMN, ElementTypes.SECTION].includes(elementType)) {
            let updatedForm = removeEmptyContainers({
                elementId: id,
                form: {
                    schema: updatedSchema,
                    uiSchema: updatedUiSchema,
                },
            });

            updatedSchema = updatedForm.schema;
            updatedUiSchema = updatedForm.uiSchema;
        }
    });

    return {
        schema: updatedSchema,
        uiSchema: updatedUiSchema,
        rules: updatedRules,
        formFieldCount,
    };
};

/**
 * Create form data object from list of field numbers and values.
 *
 * @param {object} schema Form schema.
 * @param {object} uiSchema Form uiSchema.
 * @param {[{ number: string, value: *}]} [values=[]] List of form field values.
 * @returns {object} form data object.
 */
export const setInitialValuesByFieldNumber = (schema, uiSchema, values = []) => {
    return getSchemaIds(schema).reduce((result, path) => {
        const fieldNumber = getFieldNumber(uiSchema, path);

        if (fieldNumber) {
            const field = values.filter((v) => v.number === fieldNumber)[0];
            const fieldWidget = getFieldWidget(uiSchema, path);

            if (field) {
                const fieldSchema = getElementSchema({ schema, elementId: path });
                const fieldType = fieldSchema?.type;

                let value;

                switch (fieldWidget) {
                    case "checkbox":
                    case "largecheckbox":
                        value = `${field.value}`.toLowerCase() === "true";
                        break;
                    case "generalprocedure":
                        value = String(field.value);
                        break;
                    default:
                        if (["number", "integer"].includes(fieldType)) {
                            value = Number(field.value);

                            if (isNaN(value) && isString(field.value) && field.value[0] === CURRENCY_SYMBOL) {
                                value = Number(field.value.slice(1));
                            }
                        } else if (["array"].includes(fieldType)) {
                            value = getCommaSeparatedValueAsArray(field.value, fieldSchema);
                        } else {
                            value = field.value;
                        }

                        break;
                }

                set(result, path, value);
            } else {
                // set todays date if no date is set for defaultdate widget
                if (fieldWidget === "defaultdate") {
                    set(result, path, localizeJsonDate(dateToJson(new Date())));
                }
            }
        }

        return result;
    }, {});
};

export const getApplicationFormSubmitValues = (formData, initialFields) => {
    const normalizeValue = (value, key) => {
        if (isString(value)) {
            return value;
        }

        if (Array.isArray(value)) {
            return value.join(",");
        }

        return JSON.stringify(value);
    };

    const formDataKeys = Object.keys(formData);

    let submitValues = formDataKeys
        .map((key) => ({
            fieldNumber: key,
            fieldValue: normalizeValue(formData[key], key),
        }))
        .filter((f) => f.fieldValue !== undefined);

    (initialFields || []).forEach((field) => {
        // Leave disabled fields as is
        if (!formDataKeys.includes(field.number)) {
            submitValues.push({
                fieldNumber: field.number,
                fieldValue: field.value,
            });
        }
        // Add cleared values
        else if (isNil(formData[field.number]) && !isNil(field.value)) {
            submitValues.push({
                fieldNumber: field.number,
                fieldValue: undefined,
            });
        }
    });

    return submitValues;
};

/**
 * Update select lists in form schema with items that are present in form data but not in form schema.
 *
 * @param {{ schema: object, initialValues: object }} params Form schema and initial form data.
 * @returns {object} Updated form schema.
 */
export const addNonExistingValueOptionsToSchema = ({ schema, initialValues }) => {
    let updatedSchema = cloneDeep(schema);

    getSchemaIds(schema).forEach((path) => {
        const schemaPath = `properties.${path.split(".").join(".properties.")}`;
        const fieldSchema = get(schema, schemaPath);

        let availableOptions = [];
        let optionsPath = "";

        if (fieldSchema?.type === "array") {
            availableOptions = fieldSchema?.items?.anyOf ?? [];
            optionsPath = ".items.anyOf";
        }

        // Radio buttons has list of values in anyOf property
        if (!isNil(fieldSchema?.anyOf)) {
            availableOptions = fieldSchema?.anyOf ?? [];
            optionsPath = ".anyOf";
        }

        if (availableOptions.length > 0) {
            const fieldValue = get(initialValues, path);
            const availableValues = availableOptions.map((item) => item.enum[0]);
            const notAvailableOptions = toArray(fieldValue)
                .filter((item) => !availableValues.includes(item))
                .map((item) => ({
                    title: item,
                    enum: [item],
                }));

            if (notAvailableOptions.length > 0) {
                set(updatedSchema, schemaPath + optionsPath, [].concat(notAvailableOptions, availableOptions));
            }
        }
    });

    return updatedSchema;
};

const getElementSchema = ({ schema, elementId }) => {
    let elementSchema = schema;

    if (!isEmpty(elementId)) {
        const path = `properties.${elementId.split(".").join(".properties.")}`;
        elementSchema = get(schema, path, {});
    }

    return elementSchema;
};

const removeElementById = ({ elementId, schema, uiSchema, rules = [] }) => {
    const parts = (elementId || "").split(".");

    if (parts.length === 0 || elementId === "") {
        uiSchema = {};
        schema = {};
        rules = [];
    } else {
        // schema
        const schemaPath = `properties.${parts.join(".properties.")}`;
        unset(schema, schemaPath);

        // uiSchema
        const schemaUiPath = parts.join(".");
        unset(uiSchema, schemaUiPath);

        const fieldId = parts[parts.length - 1];
        const parent = getParentSchemasById({ elementId, schema, uiSchema });

        // Required
        if ((parent.schema?.required || []).some((i) => i === fieldId)) {
            const pathToRequired = `properties.${parts.slice(0, -1).join(".properties.")}.required`;
            const updatedRequired = parent.schema.required.filter((i) => i !== fieldId);
            set(schema, pathToRequired, updatedRequired);
        }

        // Order
        if (!isEmpty(parent.uiSchema)) {
            if (parts.length < 2) {
                uiSchema = {
                    ...parent.uiSchema,
                };

                if (parent.uiSchema["ui:order"]) {
                    uiSchema["ui:order"] = parent.uiSchema["ui:order"].filter((e) => e !== fieldId);
                }
            } else {
                const parentSchemaUiPath = parts.slice(0, -1).join(".");
                const updatedUiSchema = {
                    ...parent.uiSchema,
                };

                if (parent.uiSchema["ui:order"]) {
                    updatedUiSchema["ui:order"] = parent.uiSchema["ui:order"].filter((e) => e !== fieldId);
                }

                set(uiSchema, parentSchemaUiPath, updatedUiSchema);
            }
        }

        schema = cloneDeep(schema);
        uiSchema = cloneDeep(uiSchema);

        // rules
        rules = (rules || []).filter((r) => !Object.keys(r.conditions).includes(schemaUiPath) && r.event.params.field !== schemaUiPath);
    }

    return {
        schema,
        uiSchema,
        rules,
    };
};

const getParentSchemasById = ({ elementId, schema, uiSchema }) => {
    let result = {
        schema: {},
        uiSchema: {},
    };

    const parts = (elementId || "").split(".");

    if (parts.length > 1) {
        const path = `properties.${parts.slice(0, -1).join(".properties.")}`;
        const uiPath = parts.slice(0, -1).join(".");

        result.schema = {
            ...get(schema, path),
        };

        result.uiSchema = {
            ...get(uiSchema, uiPath),
        };
    } else {
        result.schema = {
            ...schema,
        };

        result.uiSchema = {
            ...uiSchema,
        };
    }

    return result;
};

export const getFieldNumber = (uiSchema, path) => {
    return get(uiSchema, path + ".af:fieldNumber");
};

export const getFieldWidget = (uiSchema, path) => {
    return get(uiSchema, path + ".ui:widget");
};

const getCommaSeparatedValueAsArray = (value, fieldSchema) => {
    const processCommaSeparatedString = (input) => {
        let result = [];
        const availableValues = (fieldSchema?.items?.anyOf ?? []).map((item) => item.enum[0]);

        let valuesList = (input || "").split(",").map((i) => i.trim());
        let nonExistingValues = [];

        // Return index offest to add to processed values list
        const processValue = (availableValue, valueListIndex) => {
            const valueParts = (availableValue ?? "").split(",").map((i) => i.trim());
            let isCorrectValue = true;

            // check if we have all value parts as separate values
            for (let valuePartIndex = 0; valuePartIndex < valueParts.length; valuePartIndex++) {
                if (valueParts[valuePartIndex] !== valuesList[valueListIndex + valuePartIndex]) {
                    isCorrectValue = false;
                }
            }

            // Update values list if correct value found
            if (isCorrectValue) {
                result.push(availableValue);
            }

            return isCorrectValue ? valueParts.length : 0;
        };

        for (let valueIndex = 0; valueIndex < valuesList.length; valueIndex++) {
            const item = valuesList[valueIndex];

            // If value is not in the list of available values then process it
            if (!availableValues.includes(item)) {
                let isExistingValue = false;

                for (let availableValueIndex = 0; availableValueIndex < availableValues.length; availableValueIndex++) {
                    const availableValue = availableValues[availableValueIndex];
                    const offset = processValue(availableValue, valueIndex);

                    // If value was found jump to next unprocessed value in list
                    if (offset > 0) {
                        valueIndex += offset - 1;
                        isExistingValue = true;
                        break;
                    }
                }

                // Store as nonexsiting value if it is not in available values list
                if (!isExistingValue) {
                    nonExistingValues.push(item);
                }
            } else {
                // Set value as is if exists in available values list
                result.push(item);
            }
        }

        // Add nonexisting values to result
        if (nonExistingValues.length) {
            result.push(nonExistingValues.join(", "));
        }

        return result;
    };

    let result = [];

    try {
        var arrayValue = toArray(JSON.parse(value)).join(",");
        result = processCommaSeparatedString(arrayValue);
    } catch {
        result = processCommaSeparatedString(value);
    }

    return result;
};

export const submitByRefPromise = (formRef) => {
    return new Promise((resolve, reject) => {
        submitByRef(formRef, (errors, formData) => (errors ? reject(errors) : resolve(formData)));
    });
};

export const submitByRef = (formRef, callback) => {
    // Perform some additional actions like out of form validation when trying to submit the form
    if (isFunction(formRef?.current?.preSubmit)) {
        // In case when preSubmit causes rerender the form does not show errors without using setTimeout.
        setTimeout(() => {
            if (isFunction(formRef?.current?.preSubmit)) {
                formRef.current.preSubmit();
            }
        }, 0);
    }

    if (formRef.current) {
        const submitCallback = (errors, formData) => {
            formRef.current.submitCallback = undefined;

            if (callback) {
                callback(errors, formData);
            }
        };

        formRef.current.submitCallback = submitCallback;

        // Do we have requestSubmit method? (will not reload page on Firefox, not supported on IE11)
        if (formRef.current.formElement.requestSubmit) {
            formRef.current.formElement.requestSubmit();
        }
        // Dispatch event (will reload page on Firefox)
        else {
            dispatchEvent(formRef.current.formElement, "submit");
        }
    } else {
        const error = {
            message: "Invalid Form Reference",
        };

        if (callback) {
            callback(error);
        }
    }
};

export const validateForm = (formData, errors, schema, uiSchema) => {
    getSchemaIds(schema, true).forEach((item) => {
        const schemaId = item.id;
        const schemaPath = `properties.${schemaId.split(".").join(".properties.")}`;
        const fieldSchema = get(schema, schemaPath);
        const fieldUiSchema = get(uiSchema, schemaId);

        if (["date", "defaultdate"].includes(fieldUiSchema?.["ui:widget"]) && fieldSchema) {
            const value = get(formData, schemaId);
            validateDateField(fieldSchema, schemaId, value, errors, uiSchema["ui:rootFieldId"]);
        }

        if (["checkbox", "largecheckbox"].includes(fieldUiSchema?.["ui:widget"]) && fieldSchema) {
            const value = get(formData, schemaId);
            const parent = getParentSchemasById({ elementId: schemaId, schema, uiSchema });
            validateCheckboxField(fieldSchema, schemaId, value, errors, parent.schema);
        }

        if (["checkboxes", "largecheckboxes"].includes(fieldUiSchema?.["ui:widget"]) && fieldSchema) {
            const value = get(formData, schemaId);
            const parent = getParentSchemasById({ elementId: schemaId, schema, uiSchema });
            validateCheckboxesField(fieldSchema, schemaId, value, errors, parent.schema);
        }

        if (fieldUiSchema?.["ui:widget"] === "workflow") {
            const value = get(formData, schemaId);
            validateWorkflowField(fieldSchema, schemaId, value, errors);
        }

        if (["equipmentblock", "auditequipmentblock"].includes(fieldUiSchema?.["ui:widget"])) {
            const value = get(formData, schemaId);
            validateEquipmentBlock(fieldSchema, schemaId, value, errors);
        }
        // Add min/max validation for field type number and integer,ticket:V50-8091
        if (["text"].includes(fieldUiSchema?.["ui:widget"]) && (item.type === "number" || item.type === "integer")) {
            const value = get(formData, schemaId);
            validateInputTypeNumber(fieldSchema, schemaId, value, errors);
        }

        // Validate pattern for array fields
        if (fieldSchema.type === "array" && fieldSchema.items) {
            const value = get(formData, schemaId);
            validatePattern(fieldSchema, schemaId, value, errors);
        }

        // Validate signature field
        if (fieldUiSchema?.["ui:widget"] === "signature") {
            const value = get(formData, schemaId);
            const parent = getParentSchemasById({ elementId: schemaId, schema, uiSchema });
            validateSignatureField(fieldUiSchema, schemaId, value, errors, parent.schema);
        }
    });

    return errors;
};

export const transformApplicationFormErrors = (errors, schema, uiSchema, formContext) => {
    const valueLengthErrorNames = ["minLength", "maxLength"];

    // Allow value length validation only in listed widgets
    const valueLengthValidationWidgets = ["text"];

    errors = errors.filter((error) => {
        const fieldPath = (error.property || "").slice(1);
        const fieldWidget = get(uiSchema, fieldPath + ".ui:widget");

        if (valueLengthErrorNames.includes(error.name)) {
            // Keep error if present in allowed widgets list
            return valueLengthValidationWidgets.includes(fieldWidget);
        }

        // ignore this error if we are setting date value in localized format
        if (error.name === "format" && ["date", "date-time", "time"].includes(error.params?.format) && formContext?.localizeDateValues) {
            return false;
        }

        if (fieldWidget === "signature") {
            return false;
        }

        return true;
    });

    return errors;
};

export const listToAnyOf = ({ list, map, sort = "asc", emptyItem = undefined }) => {
    const emptyItemTitle = isString(emptyItem) && emptyItem.length > 0 ? emptyItem : " ";
    const empty = [{ title: emptyItemTitle, enum: [undefined] }];

    const normalizeItem = (item) => {
        const title = isString(item.title) ? item.title : isNil(item.title) ? "" : String(item.title);

        return {
            ...item,
            title,
        };
    };

    let optionList = list.length > 0 ? list.map(map).map(normalizeItem) : empty;

    if (["asc", "desc"].includes(sort)) {
        optionList = orderBy(optionList, [(item) => (item.title ?? "").toLowerCase()], [sort]);
    }

    if (emptyItem && list.length > 0) {
        return empty.concat(optionList);
    }

    return optionList;
};

/**
 * Returns a rules runner function that applies the specified rules to the form config.
 * @param {object} schema - The JSON schema object.
 * @param {object} uiSchema - The UI schema object.
 * @param {object} rules - The rules object containing validation rules.
 * @returns {function} - The rules runner function.
 */
export const getRulesRunner = (schema, uiSchema, rules) => {
    const normalizedRules = normalizeRules(rules);

    return rulesRunner(schema, uiSchema, normalizedRules, Engine);
};

/**
 * Invert condition of rules "show" and change to rule "remove".
 */
export const normalizeRules = (rules) => {
    const newRules = cloneDeep(rules);
    newRules.forEach((rule) => {
        if (rule?.event?.type === "show" && rule?.event?.params?.field) {
            rule.event.type = "remove";
            rule.conditions = {
                not: {
                    ...rule.conditions,
                },
            };
        }
    });

    return newRules;
};

/**
 * Get form name by looking at props or use page heading as a fallback.
 * @param {string?} formName
 * @param {object?} formRef
 */
export const getFormName = (formName = null, formRef = null) => {
    if (formName) {
        return formName;
    }

    const formElement = formRef?.current?.formElement;

    // Get offcanvas title as a form name if form is in side panel.
    if (formElement) {
        const offcanvas = formElement.closest("[role='dialog']");
        if (offcanvas) {
            // Return side panel title
            return offcanvas.querySelector(".offcanvas-header .offcanvas-title")?.textContent;
        }
    }

    // Return page title
    return document.getElementsByTagName("h1")[0]?.textContent;
};

/**
 * Retrieves the form name from the given form configuration object.
 * The form name is obtained from the "ui:title" property in the uiSchema,
 * or from the "title" property in the schema, if the "ui:title" property is not present.
 * If neither property is found, an empty string is returned.
 *
 * @param {object | undefined} formConfig - The form configuration object.
 * @returns {string} The form name.
 */
export const getFormNameFromConfig = (formConfig) => {
    return formConfig?.uiSchema?.["ui:title"] || formConfig?.schema?.title || "";
};

export const getFormPageNumber = (formConfig) => {
    return formConfig?.uiSchema?.["af:pageNumber"];
};
