import { AppContext } from "components/App/AppContext";
import { Heading } from "components/Heading";
import { useContext } from "react";

export const ApplicationPageContainer: React.FC<{
    hidden?: boolean;
    children: React.ReactNode;
    title?: string;
}> = ({ hidden, children, title }) => {
    const { settings } = useContext(AppContext);

    return (
        <div className="application-page px-4 py-3 container-xxl bg-gray-100" hidden={hidden} style={{ maxWidth: settings.maxWidth }}>
            {title && <Heading className="mb-4">{title}</Heading>}
            {children}
        </div>
    );
};
