import { isEmpty, isNil, omitBy } from "lodash";
import { useMemo } from "react";
import { BorderRadiusStyles } from "types/BorderRadiusStyles";
import { BorderStyles } from "types/BorderStyles";
import { Margin } from "types/Margin";
import { Padding } from "types/Padding";
import { WidgetStylesProps } from "types/WidgetStylesProps";
import { useColor, useGradient } from "./color";
import { isNumeric } from "./validation";

const useWidgetStyles = (props: WidgetStylesProps) => {
    const { maxHeight, minWidth, maxWidth, opacity, width, textDecoration, style } = props;

    const height = isNumeric(props.height) ? props.height + "px" : props.height;
    const minHeight = isNumeric(props.minHeight) ? props.minHeight + "px" : props.minHeight;
    const backgroundColor = useColor(Boolean(props.backgroundOff) ? undefined : props.backgroundColor, props.backgroundColorOpacity);
    const color = useColor(props.textColor, props.textColorOpacity);

    const firstGradient = useGradient(props.firstGradientColor, props.firstGradientValue, props.backgroundColorOpacity);
    const secondGradient = useGradient(props.secondGradientColor, props.secondGradientValue, props.backgroundColorOpacity);

    let background: string | undefined;

    if (Boolean(!props.backgroundOff)) {
        if (props.colorType === "linearGradient") {
            background = `linear-gradient(${props.gradientDirection}, ${firstGradient}, ${secondGradient})`;
        } else {
            background = backgroundColor;
        }
    }

    const borderStyles = useBorderStyles(props);
    const shadowStyles = useShadowStyles(props);
    const borderRadiusStyles = useBorderRadiusStyles(props);

    const paddingSizes = usePaddingStyles(props);
    const marginSizes = useMarginStyles(props);

    return useMemo(() => {
        let backgroundImageStyles = {};
        const backgroundImage = !isEmpty(props.backgroundImage) ? `url(${props.backgroundImage})` : undefined;
        if (backgroundImage) {
            backgroundImageStyles = {
                backgroundImage,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
            };
        }

        return {
            background,
            color,
            height,
            minHeight,
            maxHeight,
            minWidth,
            maxWidth,
            ...backgroundImageStyles,
            ...borderStyles,
            ...shadowStyles,
            ...borderRadiusStyles,
            opacity,
            ...paddingSizes,
            ...marginSizes,
            width,
            textDecoration,
            ...style,
        };
    }, [
        props.backgroundImage,
        background,
        color,
        height,
        minHeight,
        maxHeight,
        minWidth,
        maxWidth,
        borderStyles,
        shadowStyles,
        borderRadiusStyles,
        opacity,
        paddingSizes,
        marginSizes,
        width,
        textDecoration,
        style,
    ]) as any;
};

const useBorderStyles = (props: WidgetStylesProps): BorderStyles => {
    const {
        borderStyle,
        borderWidth,

        borderTopStyle,
        borderRightStyle,
        borderBottomStyle,
        borderLeftStyle,

        borderTopWidth,
        borderRightWidth,
        borderBottomWidth,
        borderLeftWidth,
    } = props;

    const isBorderDefined = [borderWidth, borderTopWidth, borderRightWidth, borderBottomWidth, borderLeftWidth].some((v) => !isEmpty(v));

    const borderColor = useColor(isBorderDefined ? props.borderColor : undefined, props.borderColorOpacity);
    const borderTopColor = useColor(isBorderDefined ? props.borderTopColor : undefined, props.borderColorOpacity);
    const borderRightColor = useColor(isBorderDefined ? props.borderRightColor : undefined, props.borderColorOpacity);
    const borderBottomColor = useColor(isBorderDefined ? props.borderBottomColor : undefined, props.borderColorOpacity);
    const borderLeftColor = useColor(isBorderDefined ? props.borderLeftColor : undefined, props.borderColorOpacity);

    if (!isBorderDefined) {
        return {};
    }

    // Take only styles with values.
    const borderStyles = omitBy(
        {
            borderStyle,
            borderWidth,
            borderColor,

            borderTopStyle,
            borderRightStyle,
            borderBottomStyle,
            borderLeftStyle,

            borderTopWidth,
            borderRightWidth,
            borderBottomWidth,
            borderLeftWidth,

            borderTopColor,
            borderRightColor,
            borderBottomColor,
            borderLeftColor,
        },
        isNil
    );

    return borderStyles;
};

const useShadowStyles = (props: WidgetStylesProps) => {
    const { shadowType, shadowSize } = props;
    const isShadowDefined = [shadowType, shadowSize, props.shadowColor, props.shadowColorOpacity].some((v) => !isEmpty(v));

    const shadowColor = useColor(isShadowDefined ? props.shadowColor : undefined, props.shadowColorOpacity);

    if (!isShadowDefined) {
        return {};
    }

    const boxShadow = shadowType + " " + shadowSize + " " + shadowColor;
    const shadowStyles = { boxShadow };

    return shadowStyles;
};

const useBorderRadiusStyles = (props: BorderRadiusStyles) => {
    const { borderRadius, borderBottomLeftRadius, borderBottomRightRadius, borderTopLeftRadius, borderTopRightRadius } = props;

    const isDefined = [borderRadius, borderBottomLeftRadius, borderBottomRightRadius, borderTopLeftRadius, borderTopRightRadius].some(
        (v) => !isEmpty(v)
    );

    if (!isDefined) {
        return {};
    }

    // Take only styles with values.
    return omitBy(
        {
            borderRadius,
            borderBottomLeftRadius,
            borderBottomRightRadius,
            borderTopLeftRadius,
            borderTopRightRadius,
        },
        isNil
    );
};

export const usePaddingStyles = (props: WidgetStylesProps): Padding => {
    const {
        padding,

        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
    } = props;

    const isDefined = [padding, paddingBottom, paddingLeft, paddingRight, paddingTop].some((v) => !isEmpty(v));

    if (!isDefined) {
        return {};
    }

    // Return only padding if it is the same as all other paddings.
    if (padding && padding === paddingBottom && padding === paddingLeft && padding === paddingRight && padding === paddingTop) {
        return { padding };
    }

    const paddingSizes = omitBy(
        {
            padding,

            paddingBottom,
            paddingLeft,
            paddingRight,
            paddingTop,
        },
        isNil
    );

    return paddingSizes;
};

export const useMarginStyles = (props: WidgetStylesProps): Margin => {
    const {
        margin,

        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
    } = props;

    const isDefined = [margin, marginBottom, marginLeft, marginRight, marginTop].some((v) => !isEmpty(v));

    if (!isDefined) {
        return {};
    }

    // Return only margin if it is the same as all other margins.
    if (margin && margin === marginBottom && margin === marginLeft && margin === marginRight && margin === marginTop) {
        return { margin };
    }

    const marginSizes = omitBy(
        {
            margin,

            marginBottom,
            marginLeft,
            marginRight,
            marginTop,
        },
        isNil
    );

    return marginSizes;
};

export default useWidgetStyles;
