import { WidgetProps } from "@rjsf/core";
import { DEFAULT_DROPDOWN_PLACEHOLDER } from "components/JsonForm/utils";
import { useStates } from "components/utils/useStates";

export const StatesWidget = (props: WidgetProps) => {
    const { registry } = props;
    const { SelectWidget } = registry.widgets;

    const [states = [], isLoading] = useStates();

    const options = {
        ...(props.options || {}),
        enumOptions: states.map((item) => ({
            label: item.state,
            value: item.abbreviation,
        })),
    };

    const placeholderValue = isLoading ? "Loading..." : props.placeholder ?? props.options?.placeholder ?? DEFAULT_DROPDOWN_PLACEHOLDER;

    return <SelectWidget {...props} options={options} placeholder={placeholderValue} />;
};
