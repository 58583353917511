export const AvatarColors = {
    BLUE: "blue",
    PURPLE: "purple",
    PINK: "pink",
    ORANGE: "orange",
    TEAL: "teal",
    CYAN: "cyan",
} as const;

export type AvatarColor = typeof AvatarColors[keyof typeof AvatarColors];
