import { Breadcrumb } from "react-bootstrap";
import { BreadcrumbItem } from "./types";

export const CategoriesBreadcrumb = ({ items, onClick }: CategoriesBreadcrumbProps) => {
    return (
        <Breadcrumb>
            {items.map((item, index) => {
                return (
                    <Breadcrumb.Item key={index} href="#" active={item.active} onClick={() => onClick(index)}>
                        {item.name}
                    </Breadcrumb.Item>
                );
            })}
        </Breadcrumb>
    );
};

interface CategoriesBreadcrumbProps {
    items: BreadcrumbItem[];
    onClick: (index: number) => void;
}
