import { SubmittedAppData } from "types/SubmittedAppData";
import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { useAppCorrespondence } from "components/Page/SubmittedApplicationV2/useAppCorrespondence";
import { MobileViewMessages } from "./MobileViewMessages";
import { MobileViewCorrespondenceList } from "./MobileViewCorrespondenceList";
import { Sections } from "./types";
import { useSectionChange } from "./useSectionChange";

export const MobileView = ({
    applicationNumber,
    appData,
    onChanged,
}: {
    applicationNumber: string;
    appData: SubmittedAppData;
    onChanged?: () => void;
}) => {
    const [correspondence = [], isLoadingCorrespondence] = useAppCorrespondence(applicationNumber, 1, 20);

    const hasNewMessages = appData?.applicationNotes?.some((message) => message.isUnread);

    const [activeSection, setActiveSection] = useSectionChange();

    if (isLoadingCorrespondence) {
        return <div className="d-flex flex-column w-100 align-items-start gap-4">Loading...</div>;
    }

    return (
        <>
            {activeSection === Sections.Messages && (
                <MobileViewMessages
                    appData={appData}
                    applicationNumber={applicationNumber}
                    onChanged={onChanged}
                    onClose={() => setActiveSection(Sections.Correspondence)}
                />
            )}
            <div className="d-flex flex-column w-100 align-items-start gap-4">
                <h2 className="m-0">Messages</h2>
                <Button
                    variant="outline-secondary"
                    className="position-relative d-flex align-items-center gap-2"
                    onClick={() => setActiveSection(Sections.Messages)}
                    aria-haspopup="dialog"
                >
                    <Icon icon={["fal", "messages"]} />
                    <span>Messages</span>
                    {hasNewMessages && (
                        <span className="position-absolute top-0 start-100 translate-middle p-2 bg-primary border border-light rounded-circle">
                            <span className="visually-hidden">New messages</span>
                        </span>
                    )}
                </Button>
                <hr className="my-0 w-100"></hr>

                <div className="d-flex flex-column w-100 align-items-start">
                    <h2 className="m-0">Correspondence</h2>
                </div>
                <MobileViewCorrespondenceList correspondence={correspondence} />
            </div>
        </>
    );
};
