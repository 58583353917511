import { useCallback, useEffect, useRef, useState } from "react";
import { withTheme } from "@rjsf/core";
import { debounce, isEqual, isFunction, isNil } from "lodash";
import ErrorBoundary from "components/ErrorBoundary";
import { theme } from "./theme";
import { flattenFormData, getFormName, getRulesRunner, validateForm } from "./utils";
import { scrollToFirstError } from "./validation";
import { SubmitButton } from "components/Button/SubmitButton";
import { AnalyticsEventType, sendAnalyticsEvent } from "components/utils/analytics";
import { REQUIRED_FIELD_ERROR_TEXT } from "components/utils/validation";

const Form = withTheme(theme);

export function JsonForm(props) {
    const [liveValidate, setLiveValidate] = useState();
    const [formData, setFormData] = useState(props.formData || {});

    // Hold latest error list in ref to detect if errors changed before scrolling to errors. Fixes unintentional scroll to errors on dropdown value change.
    const errorRef = useRef(props.extraErrors);
    const {
        noAnalytics,
        formName,
        isApplicationForm = false,
        buttonClassName = "action-buttons mt-4",
        onChange,
        onSubmit,
        onError,
        readonly,
    } = props;

    let formRef = useRef();
    if (props.formRef) {
        formRef = props.formRef;
    }

    const onRefSet = useCallback(
        (ref) => {
            if (ref) {
                if (isFunction(formRef)) {
                    formRef(ref);
                } else {
                    formRef.current = ref;
                }

                !noAnalytics &&
                    sendAnalyticsEvent(AnalyticsEventType.FORM_OPEN, { formName: getFormName(formName, formRef), isApplicationForm });
            }
        },
        [noAnalytics, formName, isApplicationForm]
    );

    // Update form data from props
    useEffect(() => {
        setFormData((prevValue) => {
            if (!isEqual(prevValue, props.formData)) {
                return props.formData;
            }

            return prevValue;
        });
    }, [props.formData]);

    useEffect(() => {
        if (!isEqual(errorRef.current, props.extraErrors)) {
            errorRef.current = props.extraErrors;
            scrollToFirstError(formRef?.current);
        }
    }, [props.extraErrors, formRef]);

    const handleSubmit = useCallback(
        (form) => {
            !noAnalytics &&
                sendAnalyticsEvent(AnalyticsEventType.FORM_SUBMIT, { formName: getFormName(formName, formRef), isApplicationForm });
            setLiveValidate(true);
            onSubmit?.(form);
            setTimeout(() => {
                setLiveValidate(false);
            });

            if (formRef?.current?.submitCallback) {
                formRef.current.submitCallback(null, form.formData);
            }
        },
        [noAnalytics, formName, isApplicationForm, onSubmit]
    );

    const handleChange = useCallback(
        (event) => {
            setFormData(event.formData);
            onChange?.(event);
        },
        [onChange]
    );

    const transformErrors = useCallback(
        (errors) => {
            const transformedErrors = props.transformErrors ? props.transformErrors(errors) : errors;
            return transformedErrors?.map((e) => {
                if (e.name === "required") {
                    e.message = REQUIRED_FIELD_ERROR_TEXT;
                }
                return e;
            });
        },
        [props]
    );

    const handleError = useCallback(
        (form) => {
            !noAnalytics &&
                sendAnalyticsEvent(AnalyticsEventType.FORM_ERROR, { formName: getFormName(formName, formRef), isApplicationForm });
            scrollToFirstError(formRef?.current);

            onError?.(form);

            if (formRef?.current?.submitCallback) {
                formRef.current.submitCallback(form, null);
            }
        },
        [noAnalytics, formName, isApplicationForm, onError]
    );

    const validate = useCallback(
        (formData, errors) => {
            errors = validateForm(formData, errors, props.schema, props.uiSchema);

            if (isFunction(props.validate)) {
                errors = props.validate(formData, errors);
            }

            return errors;
        },
        [props]
    );

    return (
        <ErrorBoundary>
            <Form
                noHtml5Validate
                showErrorList={false}
                liveValidate={liveValidate}
                {...props}
                formData={formData}
                onChange={handleChange}
                onSubmit={handleSubmit}
                transformErrors={transformErrors}
                onError={handleError}
                ref={onRefSet}
                validate={validate}
                readonly={readonly}
            >
                <div className={buttonClassName}>
                    {props.children
                        ? props.children
                        : !props.noSubmit && (
                              <SubmitButton
                                  type="submit"
                                  variant="primary"
                                  isSubmitting={props.isSubmitting}
                                  spinnerText="Loading..."
                                  disabled={props.disabled}
                              >
                                  {props.submitButtonText ?? "Submit"}
                              </SubmitButton>
                          )}
                </div>
            </Form>
        </ErrorBoundary>
    );
}

export function JsonFormWithConditionals(props) {
    const { fieldKey, onChange, onSubmit } = props;

    const [config, setConfig] = useState();
    const rulesRunnerRef = useRef(getRulesRunner(props.schema, props.uiSchema, props.rules));

    // Init form with running rules once
    const isInitialized = useRef(false);
    if (!isInitialized.current) {
        isInitialized.current = true;
        rulesRunnerRef.current(props.formData).then((config) => {
            setConfig({
                schema: config.schema,
                uiSchema: config.uiSchema,
            });
        });
    }

    const onFormChange = useCallback(
        (form) => {
            debouncedOnChange({ onChange, runRules: rulesRunnerRef.current, form, fieldKey, setConfig });
        },
        [fieldKey, onChange]
    );

    const onFormSubmit = useCallback(
        (form) => {
            if (onSubmit) {
                onSubmit(flattenFormData(form, fieldKey));
            }
        },
        [fieldKey, onSubmit]
    );

    if (isNil(config) || isNil(config.schema)) {
        return null;
    }

    return (
        <JsonForm
            {...props}
            schema={config.schema}
            uiSchema={config.uiSchema}
            formData={props.formData}
            onChange={onFormChange}
            onSubmit={onFormSubmit}
        />
    );
}

/* For programmatically submitted form need a hidden submit button for form submit to work. */
export const HiddenSubmitButton = () => <button type="submit" aria-hidden="true" style={{ display: "none" }}></button>;

// Debounce form onChange callback
const debouncedOnChange = debounce(({ onChange, runRules, form, fieldKey, setConfig }) => {
    runRules(form.formData).then((config) => {
        // Update form schemas if changed by rules
        if (!isEqual(form.schema, config.schema) || !isEqual(form.uiSchema, config.uiSchema)) {
            setConfig({
                schema: config.schema,
                uiSchema: config.uiSchema,
            });
        }

        if (onChange) {
            const data = flattenFormData(
                {
                    schema: config.schema,
                    uiSchema: config.uiSchema,
                    formData: config.formData,
                },
                fieldKey
            );

            onChange({
                formData: data,
                errorSchema: form.errorSchema,
            });
        }
    });
}, 500);
