import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { Link } from "react-router-dom";

export const QuickLink = (props: QuickLinkProps) => {
    return (
        <Link to={props.href} className="quick-link-item menu-link d-flex flex-row align-items-center justify-content-between ps-md-5">
            <span className="d-flex align-items-center">{props.text}</span>
            <FontAwesomeIcon className={cn(props.iconClassName ?? "d-md-none")} icon={props.icon ?? "chevron-right"} />
        </Link>
    );
};

interface QuickLinkProps {
    text: string;
    href: string;
    icon?: IconName;
    iconClassName?: string;
}
