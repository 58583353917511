import cn from "classnames";
import { Icon } from "components/Icon";

import "./style.scss";

export const AppTaskToCompleteBadge = ({ className, onClick }: { className?: string; onClick: () => void }) => {
    return (
        <div
            role="button"
            onClick={onClick}
            className={cn(
                className,
                "app-badge app-badge--tasks d-inline-flex flex-row align-items-center text-nowrap px-2 py-1 w-fit-content"
            )}
        >
            <Icon icon="table-list" size={"sm"} />
            <span className="ms-2">task to complete</span>
        </div>
    );
};

export const AppTaskCompletedBadge = ({ className }: { className?: string }) => {
    return (
        <div
            className={cn(
                className,
                "app-badge app-badge--tasks-completed d-inline-flex flex-row align-items-center text-nowrap px-2 py-1 w-fit-content"
            )}
        >
            <Icon icon="table-list" size={"sm"} />
            <span className="ms-2">task completed</span>
        </div>
    );
};
