export interface Padding {
    padding?: string;

    paddingBottom?: string;
    paddingLeft?: string;
    paddingRight?: string;
    paddingTop?: string;
}

export const PADDING_PROPERTY_KEYS: (keyof Padding)[] = ["padding", "paddingBottom", "paddingLeft", "paddingRight", "paddingTop"];
