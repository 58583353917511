import { getCustomerNumber } from "components/utils/user";
import { EventProps } from "types/EventProps";

// Do nothing if this is portal preview or localhost
if (!["localhost", "portal-preview"].some((host) => window.location.hostname.startsWith(host)) && process.env.REACT_APP_ANALYTICS_SCRIPT) {
    const domains = [window.location.hostname];

    if (process.env.REACT_APP_ANALYTICS_BASE_DOMAIN) {
        domains.push(process.env.REACT_APP_ANALYTICS_BASE_DOMAIN);
    }

    loadTrackingScript(process.env.REACT_APP_ANALYTICS_SCRIPT, domains.join(","));
    trackPageViews();
    trackLinkClicks();
    trackButtonClicks();
    trackLanguages();
}

function loadTrackingScript(src: string, domain: string) {
    const script = document.createElement("script");
    script.src = src;
    script.defer = true;
    script.setAttribute("data-domain", domain);
    document.head.appendChild(script);
    window.plausible =
        window.plausible ||
        function () {
            // @ts-ignore
            (window.plausible.q = window.plausible.q || []).push(arguments);
        };
}

function trackPageViews() {
    let lastPage: string;
    function sendPageViewEvent() {
        if (lastPage === window.location.pathname) return;
        lastPage = window.location.pathname;

        const queryParams = new URLSearchParams(window.location.search);
        const applicationNumber = queryParams.get("applicationNumber") ?? "";
        const pageNumber = queryParams.get("pageNumber") ?? "";
        const projectNumber = queryParams.get("projectNumber") ?? "";

        const props: EventProps = { hostname: window.location.hostname, applicationNumber, pageNumber, projectNumber };

        addCustomerNumberToEventProps(props);
        window.plausible("pageview", { props });
    }

    var his = window.history;
    if (his.pushState) {
        var originalPushState = his["pushState"];
        his.pushState = function (...args) {
            originalPushState.apply(this, args);
            sendPageViewEvent();
        };
        window.addEventListener("popstate", sendPageViewEvent);
    }

    // Register page view event for replaceState as well. For example on router redirects.
    if (his.replaceState) {
        var originalReplaceState = his["replaceState"];
        his.replaceState = function (...args) {
            originalReplaceState.apply(this, args);
            sendPageViewEvent();
        };
    }

    function handleVisibilityChange() {
        if (!lastPage && document.visibilityState === "visible") {
            sendPageViewEvent();
        }
    }

    if (document.visibilityState !== "visible") {
        document.addEventListener("visibilitychange", handleVisibilityChange);
    } else {
        sendPageViewEvent();
    }
}

function trackLinkClicks() {
    var MIDDLE_MOUSE_BUTTON = 1;
    var fileTypesToTrack = [
        "pdf",
        "xlsx",
        "docx",
        "txt",
        "rtf",
        "csv",
        "exe",
        "key",
        "pps",
        "ppt",
        "pptx",
        "7z",
        "pkg",
        "rar",
        "gz",
        "zip",
        "avi",
        "mov",
        "mp4",
        "mpeg",
        "wmv",
        "midi",
        "mp3",
        "wav",
        "wma",
    ];

    document.addEventListener("click", handleLinkClickEvent);
    document.addEventListener("auxclick", handleLinkClickEvent);

    function getLinkEl(link: any): HTMLAnchorElement | null {
        while (
            link &&
            (typeof link.tagName === "undefined" || link.tagName.toLowerCase() !== "a" || !link.href || link.role === "button")
        ) {
            link = link.parentNode;
        }
        return link;
    }

    function shouldFollowLink(event: MouseEvent, link: HTMLAnchorElement) {
        // If default has been prevented by an external script, Plausible should not intercept navigation.
        if (event.defaultPrevented) {
            return false;
        }

        var targetsCurrentWindow = !link.target || link.target.match(/^_(self|parent|top)$/i);
        var isRegularClick = !(event.ctrlKey || event.metaKey || event.shiftKey) && event.type === "click";
        return targetsCurrentWindow && isRegularClick;
    }

    function handleLinkClickEvent(event: MouseEvent) {
        if (event.type === "auxclick" && event.button !== MIDDLE_MOUSE_BUTTON) {
            return;
        }

        var link = getLinkEl(event.target);
        var hrefWithoutQuery = link && link.href && link.href.split("?")[0];
        var eventName;
        var eventProps;

        if (link && isOutboundLink(link)) {
            eventName = "Outbound Link: Click";
            eventProps = { url: link.href, hostname: window.location.hostname };
            return sendLinkClickEvent(event, link, eventName, eventProps);
        }

        if (link && hrefWithoutQuery && isDownloadToTrack(hrefWithoutQuery)) {
            eventName = "File Download";
            eventProps = { url: hrefWithoutQuery, hostname: window.location.hostname };
            return sendLinkClickEvent(event, link, eventName, eventProps);
        }

        // If the link is not an outbound link or a file download, it's a regular link click
        if (link && hrefWithoutQuery) {
            var linkName = link.innerText || link.textContent || link.title || (link.getAttribute("aria-label") ?? "");
            eventName = "Link: Click";
            eventProps = { url: hrefWithoutQuery, hostname: window.location.hostname, name: linkName };
            return sendLinkClickEvent(event, link, eventName, eventProps);
        }
    }

    function sendLinkClickEvent(event: MouseEvent, link: HTMLAnchorElement, eventName: string, eventProps: EventProps) {
        var followedLink = false;

        function followLink() {
            if (!followedLink) {
                followedLink = true;
                window.location.href = link.href;
            }
        }

        addCustomerNumberToEventProps(eventProps);

        // take metadata if available
        const metadata = link.getAttribute("data-metadata");

        // parse metadata
        if (metadata) {
            try {
                const parsedMetadata = JSON.parse(metadata || "{}");
                Object.assign(eventProps, parsedMetadata);
            } catch (e) {
                console.error("Failed to parse metadata", e, link);
            }
        }

        if (shouldFollowLink(event, link)) {
            window.plausible(eventName, { props: eventProps, callback: followLink });
            setTimeout(followLink, 5000);
            event.preventDefault();
        } else {
            window.plausible(eventName, { props: eventProps });
        }
    }

    function isOutboundLink(link: HTMLAnchorElement) {
        return link && link.href && link.host && link.host !== window.location.host;
    }

    function isDownloadToTrack(url: string) {
        if (!url) {
            return false;
        }

        var fileType = url.split(".").pop();
        return fileTypesToTrack.some(function (fileTypeToTrack) {
            return fileTypeToTrack === fileType;
        });
    }
}

function trackButtonClicks() {
    document.addEventListener("click", handleButtonClickEvent, true);

    function getButtonEl(button: any): HTMLButtonElement | null {
        // Find the button element that was clicked. Exclude date picker day buttons.
        while (button && (typeof button.tagName === "undefined" || !isButtonElement(button) || isDatePickerButton(button))) {
            button = button.parentNode;
        }
        return button;
    }

    function isButtonElement(element: any) {
        return element.tagName.toLowerCase() === "button" || element.role === "button";
    }

    function isDatePickerButton(button: any) {
        return button.classList.contains("rdp-day") || button.classList.contains("rdp-day_selected");
    }

    function handleButtonClickEvent(event: MouseEvent) {
        const button = getButtonEl(event.target);

        if (button) {
            const props: EventProps = {
                name: button.innerText || button.title || (button.getAttribute("aria-label") ?? ""),
                hostname: window.location.hostname,
            };

            // take metadata if available
            const metadata = button.getAttribute("data-metadata");

            // parse metadata
            if (metadata) {
                try {
                    const parsedMetadata = JSON.parse(metadata || "{}");
                    Object.assign(props, parsedMetadata);
                } catch (e) {
                    console.error("Failed to parse metadata", e, button);
                }
            }

            addCustomerNumberToEventProps(props);
            window.plausible("Button: Click", { props });
        }
    }
}

function trackLanguages() {
    if (navigator.language) {
        const props: EventProps = {
            language: navigator.language.split("-")[0],
            hostname: window.location.host,
        };

        addCustomerNumberToEventProps(props);
        window.plausible("Language", { props });
    }
}

// Add customer number if available in session storage
function addCustomerNumberToEventProps(props: EventProps) {
    var customerNumber = getCustomerNumber();
    if (customerNumber) {
        props["customer"] = customerNumber;
    }
}

export {};
