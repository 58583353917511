import { httpGetAuthorized } from "components/utils/http";
import useSWR from "swr";
import { ReferenceResult } from "types/ReferenceResult";

export const useEquipmentValidationTypes = (): [validationTypes: ReferenceResult[], isLoading: boolean, error: any] => {
    const url = process.env.REACT_APP_REFERENCE_ENDPOINT + "?type=equipmentValidation&outputType=id";
    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data;

    return [data?.referenceResults, isLoading, error];
};
