import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

export function Paragraph(props) {
    const ref = useRef();
    const history = useHistory();

    // Prevent page reload on internal navigation hyperlink clicks
    useEffect(() => {
        const paragraph = ref.current;

        const onClick = (event) => {
            const element = event.target;

            if (
                element.nodeName === "A" && // is hyperlink
                !event.defaultPrevented && // onClick prevented default
                event.button === 0 && // ignore everything but left clicks
                element.host === window.location.host && // is internal link
                ["", "_self"].includes(element.target) && // let browser handle "target=_blank" etc.
                !isModifiedEvent(event) // ignore clicks with modifier keys
            ) {
                event.preventDefault();
                history.push(element.pathname);
            }
        };

        if (paragraph) {
            paragraph.addEventListener("click", onClick, false);
        }

        return () => {
            if (paragraph) {
                paragraph.removeEventListener("click", onClick);
            }
        };
    }, [history]);

    return <p ref={ref} className="page-paragraph" dangerouslySetInnerHTML={{ __html: props.text ?? props.children }} />;
}

function isModifiedEvent(event) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}
