import React from "react";
import { DateRange, DayPicker, SelectRangeEventHandler } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import cn from "classnames";
import { Icon } from "components/Icon";
import { dateToJson, formatJsonDate } from "components/utils/date";
import { useColor } from "components/utils/color";
import Color from "color";
import useMediaQuery from "components/utils/useMediaQuery";

import "./DateRange.scss";

interface DateRangeProps {
    id?: string;
    style?: React.CSSProperties;
    className?: string;
    variant?: string;
    disabled?: boolean;
    vertical?: boolean;
    "aria-label"?: string;
    value?: DateRange;
    onSelect?: SelectRangeEventHandler;
}

export const DatePickerWithRange: React.FC<DateRangeProps> = ({
    id,
    style,
    className,
    variant,
    disabled,
    vertical,
    value,
    "aria-label": label,
    onSelect,
}) => {
    const isMobile = useMediaQuery("(max-width: 768px)");

    const onToggle = (isOpen: boolean) => {
        if (!isOpen) return;

        // the focus doesn't work without a timeout
        setTimeout(() => {
            // focus on the selected day
            const selectedDay = document.querySelector(".rdp-day_selected") as HTMLButtonElement;
            if (selectedDay) {
                selectedDay.focus();
                return;
            }

            // focus on the current day
            const currentDay = document.querySelector(".rdp-day_today") as HTMLButtonElement;
            if (currentDay) {
                currentDay.focus();
            }
        }, 50);
    };

    return (
        <Dropdown as={ButtonGroup} autoClose="outside" onToggle={onToggle} className="w-100">
            <Dropdown.Toggle
                id={id}
                style={style}
                variant={variant}
                className={cn(
                    className,
                    "date-range-toggle d-flex flex-row gap-2 align-items-center justify-content-between flex-shrink-0"
                )}
                disabled={disabled}
                aria-label={label}
            >
                <span className={cn("text-nowrap text-start d-flex align-items-center gap-2", { small: isMobile })}>
                    <span style={{ minWidth: "7rem" }}>{value?.from ? formatJsonDate(dateToJson(value.from)) : "Start date"}</span>
                    <ArrowRight />
                    <span className="flex-shrink-0">{value?.to ? formatJsonDate(dateToJson(value.to)) : "End date"}</span>
                </span>
                <Icon icon={["fal", "calendar"]} />
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="bg-white"
                as={DateRangeMenu}
                vertical={vertical}
                value={value}
                onRangeSelect={onSelect}
            ></Dropdown.Menu>
        </Dropdown>
    );
};

const DateRangeMenu = React.forwardRef<
    HTMLDialogElement,
    {
        style: React.CSSProperties;
        className: string;
        "aria-labelledby": string;
        vertical?: boolean;
        value?: DateRange;
        onRangeSelect?: SelectRangeEventHandler;
    }
>(({ style, className, "aria-labelledby": labeledBy, vertical, value, onRangeSelect }, ref) => {
    // previous month date
    const previousMonthDate = new Date();
    previousMonthDate.setMonth(previousMonthDate.getMonth() - 1, 1);

    const primaryColor = useColor("primary");
    const isPrimaryColorLight = Color(primaryColor).isLight();
    const textColor = isPrimaryColorLight ? "var(--bs-dark)" : "var(--bs-light)";

    // lighten the primary color
    const rangeMiddleBg = Color(primaryColor).fade(0.9);
    const numberOfMonths = vertical ? 1 : 2;

    const css = `
        .rdp-day_range_middle {
            background-color: ${rangeMiddleBg};
            color: var(--bs-dark);
        }
        .rdp-day:not(.rdp-day_range_middle) {
            border-radius: var(--btn-border-radius);
        }
    `;

    return (
        <dialog ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
            <style>{css}</style>
            <DayPicker
                showOutsideDays
                defaultMonth={value?.from ?? previousMonthDate}
                selected={value}
                onSelect={onRangeSelect}
                mode="range"
                numberOfMonths={numberOfMonths}
                toDate={new Date()}
                className={cn("m-0", {
                    "rdp-vertical": vertical,
                })}
                classNames={{
                    months: "rdp-months gap-3 p-2",
                    month: "rdp-month m-0",
                }}
                style={
                    {
                        "--rdp-cell-size": "36px",
                        "--rdp-caption-font-size": "var(--bs-body-font-size)",
                        "--rdp-accent-color": "var(--bs-primary)",
                        "--rdp-background-color": "var(--bs-gray-200)",
                        "--rdp-selected-color": textColor,
                    } as React.CSSProperties
                }
            />
        </dialog>
    );
});

export const ArrowRight = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4637_33285)">
            <rect width="16" height="16" fill="white" fillOpacity="0.01" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 8.00011C4 7.8675 4.05268 7.74033 4.14645 7.64656C4.24021 7.55279 4.36739 7.50011 4.5 7.50011H10.293L8.146 5.35411C8.05211 5.26023 7.99937 5.13289 7.99937 5.00011C7.99937 4.86734 8.05211 4.74 8.146 4.64611C8.23989 4.55223 8.36722 4.49948 8.5 4.49948C8.63278 4.49948 8.76011 4.55223 8.854 4.64611L11.854 7.64611C11.9006 7.69256 11.9375 7.74773 11.9627 7.80848C11.9879 7.86922 12.0009 7.93435 12.0009 8.00011C12.0009 8.06588 11.9879 8.131 11.9627 8.19175C11.9375 8.25249 11.9006 8.30767 11.854 8.35411L8.854 11.3541C8.76011 11.448 8.63278 11.5007 8.5 11.5007C8.36722 11.5007 8.23989 11.448 8.146 11.3541C8.05211 11.2602 7.99937 11.1329 7.99937 11.0001C7.99937 10.8673 8.05211 10.74 8.146 10.6461L10.293 8.50011H4.5C4.36739 8.50011 4.24021 8.44743 4.14645 8.35367C4.05268 8.2599 4 8.13272 4 8.00011Z"
                fill="#CECEDA"
            />
        </g>
        <defs>
            <clipPath id="clip0_4637_33285">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
