import { Spinner } from "react-bootstrap";
import { useCustomerDashboardModel } from "./useCustomerDashboardModel";
import { usePageTopAndBottomRows } from "components/utils/page";
import { PageComponentsList } from "..";
import { ApplicationsList } from "./ApplicationsList";
import { ErrorSummary } from "components/ErrorSummary";
import { ApplicationListControls } from "./ApplicationListControls";
import { DashboardHeader } from "./DashboardHeader";
import { AppContext } from "components/App/AppContext";
import { useContext } from "react";
import classNames from "classnames";

import "./style.scss";

export function DashboardPageV2() {
    const { settings } = useContext(AppContext);
    const model = useCustomerDashboardModel();

    const { isLoadingDashboard, errorSummary } = model;
    const { topRows, bottomRows } = usePageTopAndBottomRows();

    const totalApplications = model?.applicationsGridConfig?.totalRecords || 0;
    if (isLoadingDashboard) {
        return (
            <div className="customer-home-v2 py-5 d-flex justify-content-center container-xxl" style={{ maxWidth: settings.maxWidth }}>
                <Spinner className="flex-shrink-0" animation="border" role="status">
                    <span className="visually-hidden">Loading dashboard applications</span>
                </Spinner>
            </div>
        );
    }

    return (
        <>
            <DashboardHeader model={model} />
            <div className="customer-home-v2 d-flex flex-column container-xxl" style={{ maxWidth: settings.maxWidth }}>
                <ErrorSummary className="mt-3" errorSummary={errorSummary} />
            </div>
            <div
                className={classNames("customer-home-v2 d-flex flex-column container-xxl px-md-4", {
                    "pb-5": totalApplications <= 10,
                    "pb-4": totalApplications > 10,
                })}
                style={{ maxWidth: settings.maxWidth }}
            >
                <PageComponentsList components={topRows} />
                <ApplicationListControls model={model} />
                <ApplicationsList model={model} />
                <PageComponentsList components={bottomRows} />
            </div>
        </>
    );
}
