import { httpGetAuthorized } from "components/utils/http";
import useSWR from "swr/immutable";

export const useStates = (): [states: State[], isLoading: boolean, error: any] => {
    const { data, error } = useSWR(process.env.REACT_APP_STATES_ENDPOINT, httpGetAuthorized);
    const isLoading = !error && !data;

    return [data, isLoading, error];
};

export interface State {
    id: number;
    abbreviation: string;
    state: string;
    country: string;
}
