import { isInIframe } from "components/utils/dom";
import { KeyboardEvent, useState } from "react";
import { Collapse, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalize, orderBy } from "lodash";
import useMediaQuery from "components/utils/useMediaQuery";
import { DataGridHead } from "components/DataGrid/DataGridHead";
import { ReactComponent as FilesPlaceholder } from "./../assets/files-placeholder.svg";
import "./../style.scss";
import { SubmitButton } from "components/Button/SubmitButton";
import { downloadFile, downloadFileFolder } from "components/utils";
import { download } from "components/utils/files";
import { ApplicationFile } from "types/SubmittedAppData";
import { getGroupedRows } from "components/DataGrid/utils";
import { formatDateAndTime } from "components/utils/date";
import { formatBytes } from "components/utils/format";
import classNames from "classnames";
import { EmptyStatePlaceholder } from "../EmptyStatePlaceholder";
import { Button } from "components/Button";

const FileNameSize = ({ file, isMobile }: { file: ApplicationFile; isMobile: boolean }) => {
    const getFileIcon = (name: string) => {
        const extension: string = name.split(".").pop() as string;
        if (["jpeg", "png", "jpg", "gif", "svg"].includes(extension)) {
            return "file-image";
        } else if (["xlsx", "docx", "pdf", "pptx", "xls", "doc", "ppt", "txt"].includes(extension)) {
            return "file-lines";
        } else {
            return "file";
        }
    };
    return (
        <div
            id={`${file.fileId}-name-size`}
            className={classNames("d-flex align-items-center gap-2 fs-7", {
                "lh-sm": isMobile,
            })}
        >
            <div className="py-1 px-2 border me-md-2 rounded-1">
                <FontAwesomeIcon fontSize="1.5rem" icon={["fal", getFileIcon(file.fileName)]} />
            </div>
            <div className="d-flex flex-column gap-1 text-break">
                <span>{file.fileName}</span>
                <span aria-hidden={true}>{formatBytes(Number(file.fileSize))}</span>
                <span className="visually-hidden">{formatBytes(Number(file.fileSize), 2, true)}</span>
            </div>
        </div>
    );
};

const DownloadFileButton = ({
    file,
    onDownloadFile,
    downloadingFile,
    isMobile,
}: {
    file: ApplicationFile;
    onDownloadFile: (id: string, name: string) => void;
    downloadingFile?: string;
    isMobile: boolean;
}) => (
    <SubmitButton
        className={classNames("download-file-button position-relative", {
            "px-0": isMobile,
        })}
        onClick={() => {
            onDownloadFile(file.fileId, file.fileName);
        }}
        variant="link"
        spinnerText="Downloading..."
        disabled={isInIframe()}
        isSubmitting={downloadingFile === file.fileId}
        aria-describedby={`${file.fileId}-name-size`}
        title="Download File"
    >
        {downloadingFile !== file.fileId && <FontAwesomeIcon icon={["fal", "file-arrow-down"]} size={"lg"} className="me-2" />}
        {!isMobile && "Download File"}
    </SubmitButton>
);

export function ApplicationFiles({ files, applicationNumber, setErrorSummary }: ApplicationFilesProps) {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const [downloadingFile, setDownloadingFile] = useState<string>();
    const [downloadingFolder, setDownloadingFolder] = useState<{ [key: string]: boolean }>({});
    const [collapsed, setCollapsed] = useState<{ [key: string]: boolean }>({});

    const FILE_DETAILS_ID = "file-details-id";

    const onDownloadFile = async (fileId: string, fileName: string) => {
        try {
            setDownloadingFile(fileId);
            const response = await downloadFile(fileId);
            download(response?.blob, fileName);
        } catch (error) {
            setErrorSummary(error);
        } finally {
            setDownloadingFile(undefined);
        }
    };

    const onDownloadFileFolder = async (appNumber: string, folder: string) => {
        if (isInIframe()) {
            return;
        }

        try {
            setDownloadingFolder({ ...downloadingFolder, [folder]: true });
            const response = await downloadFileFolder(appNumber, folder);
            download(response?.blob, `${folder}.zip`);
        } catch (error) {
            setErrorSummary(error);
        } finally {
            setDownloadingFolder({ ...downloadingFolder, [folder]: false });
        }
    };

    const collapseFolder = (folder: string) => {
        setCollapsed({ ...collapsed, [folder]: !collapsed[folder] });
    };

    const collapseFolderEnter = (e: KeyboardEvent<HTMLDivElement>, folder: string) => {
        e.key === "Enter" && collapseFolder(folder);
    };

    const rowGroups = getGroupedRows({
        rowGroups: [{ key: "folder" }],
        rows: files ? orderBy(files, (f) => new Date(f.uploadDate), "desc") : [],
    });

    return (
        <div className="application-files d-flex flex-column gap-3">
            <h2 className="m-0">Files</h2>
            {files.length > 0 ? (
                <div className="d-flex flex-column gap-4p5">
                    {Object.keys(rowGroups).map((folder, index) => (
                        <div key={index}>
                            <div className="position-relative d-flex folder-block border-bottom">
                                <div
                                    role="button"
                                    aria-label={`Folder ${folder}`}
                                    aria-controls={FILE_DETAILS_ID + `-${index}`}
                                    aria-expanded={!collapsed[folder]}
                                    tabIndex={0}
                                    onClick={() => collapseFolder(folder)}
                                    onKeyPress={(e) => collapseFolderEnter(e, folder)}
                                    className="d-flex w-100 py-3 px-3p5 align-items-center"
                                    data-metadata={JSON.stringify({ name: collapsed[folder] ? "Expand folder" : "Collapse folder" })}
                                >
                                    <div className="d-flex align-items-center gap-3 text-break" style={{ flex: 1 }}>
                                        {!isMobile && (
                                            <FontAwesomeIcon
                                                className="folder-block-icon text-dark"
                                                fontSize="1.5rem"
                                                icon={["fal", collapsed[folder] ? "folder" : "folder-open"]}
                                            />
                                        )}

                                        <h2 className="m-0 text-dark" id={`folder-name-${index}`}>
                                            {capitalize(folder.toLowerCase())}
                                        </h2>
                                    </div>
                                    <div className="d-flex align-items-center gap-3 gap-md-4 ms-auto" style={{ flex: 1 }}>
                                        {/* Placeholder for absolute position download all button which was positioned to avoid a button in button */}
                                        <Button aria-hidden="true" className="invisible" variant="outline-dark">
                                            Placeholder
                                        </Button>
                                        <FontAwesomeIcon
                                            className="ms-auto text-dark"
                                            icon={["fal", collapsed[folder] ? "chevron-down" : "chevron-up"]}
                                            size={"lg"}
                                        />
                                    </div>
                                </div>
                                <div className="position-absolute download-all-button d-flex gap-3 gap-md-4 my-3 top-0 bottom-0 align-items-center pe-none">
                                    <SubmitButton
                                        variant="outline-dark"
                                        className="ms-auto pe-auto"
                                        spinnerText="Downloading"
                                        isSubmitting={downloadingFolder[folder]}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onDownloadFileFolder(applicationNumber, folder);
                                        }}
                                        aria-label={isMobile ? "Download all" : undefined}
                                        aria-describedby={`folder-name-${index}`}
                                    >
                                        {!downloadingFolder[folder] && (
                                            <FontAwesomeIcon icon={["fal", "folder-arrow-down"]} size={"lg"} className="me-md-2" />
                                        )}
                                        {isMobile ? "" : "Download all"}
                                    </SubmitButton>
                                    {/* Placeholder for arrow icon for predictable spacing from right side while changing font sizes */}
                                    <FontAwesomeIcon className="invisible" aria-hidden="true" icon={["fal", "chevron-down"]} size={"lg"} />
                                </div>
                            </div>
                            <Collapse in={!collapsed[folder]}>
                                <div id={FILE_DETAILS_ID + `-${index}`}>
                                    {!isMobile ? (
                                        <div className="pt-2 pb-4 px-3p5 border-bottom">
                                            <Table className="bg-light">
                                                <caption className="visually-hidden">Files list</caption>
                                                <DataGridHead>
                                                    <tr className="fw-bold">
                                                        <th className="px-2 py-2 col-6">File</th>
                                                        <th className="px-2 py-2 col-6">Upload date</th>
                                                        <th className="px-2 py-2 col-4">
                                                            <div className="ms-3">Actions</div>
                                                        </th>
                                                    </tr>
                                                </DataGridHead>
                                                <tbody>
                                                    {rowGroups[folder].map((f: ApplicationFile, index: number) => (
                                                        <tr key={index}>
                                                            <td className="fs-7">
                                                                <FileNameSize file={f} isMobile={isMobile} />
                                                            </td>

                                                            <td className="fs-7">{formatDateAndTime(f.uploadDate)}</td>
                                                            <td>
                                                                <div className="d-flex justify-content-end text-nowrap">
                                                                    <DownloadFileButton
                                                                        file={f}
                                                                        onDownloadFile={onDownloadFile}
                                                                        downloadingFile={downloadingFile}
                                                                        isMobile={isMobile}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    ) : (
                                        <div className="mobile-files-block gap-3 d-flex flex-column p-3">
                                            {rowGroups[folder].map((f: ApplicationFile, index: number) => (
                                                <div key={index} className="flex-column w-100">
                                                    <div className="flex-column px-1 py-1 bg-white bg-opacity-10 row justify-content-center">
                                                        <div className="d-flex align-items-center border-bottom">
                                                            <p className="m-0 py-2 col-4 fw-bold">File</p>
                                                            <FileNameSize file={f} isMobile={isMobile} />
                                                        </div>
                                                        <div className="d-flex align-items-center border-bottom">
                                                            <p className="m-0 py-2 col-4 fw-bold">Upload date</p>
                                                            <span className="fs-7">{formatDateAndTime(f.uploadDate)}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center border-bottom">
                                                            <p className="m-0 py-2 col-4 fw-bold">Actions</p>
                                                            <div className="d-flex w-100 justify-content-end pe-4">
                                                                <DownloadFileButton
                                                                    file={f}
                                                                    onDownloadFile={onDownloadFile}
                                                                    downloadingFile={downloadingFile ?? ""}
                                                                    isMobile={isMobile}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </Collapse>
                        </div>
                    ))}
                </div>
            ) : (
                <EmptyStatePlaceholder
                    placeholderImage={<FilesPlaceholder />}
                    title="No files yet"
                    description="This is where you'll find files submitted for this application."
                    className="py-5"
                />
            )}
        </div>
    );
}

interface ApplicationFilesProps {
    files: ApplicationFile[];
    applicationNumber: string;
    setErrorSummary: (error: any) => void;
}
