import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import cn from "classnames";
import { Redirect, useLocation } from "react-router-dom";
import { AppContext } from "components/App/AppContext";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { updateCssVariables, useUserAuthenticated } from "components/utils";
import { isInIframe } from "components/utils/dom";
import { isEmpty, isNil } from "lodash";
import { PageLink } from "components/App/utils";
import { PageConfiguration } from "types/PageConfiguration";
import { LeadImage } from "components/LeadImage";
import { adjustColorContrast, getCssColor, useColor } from "components/utils/color";
import { getPageClassName } from "components/utils/page";
import useWidgetStyles from "components/utils/useWidgetStyles";
import { Components } from "components";
import { PortalWidgetConfiguration } from "types/PortalWidgetConfiguration";
import { ElementHighlighter } from "components/ElementHighlighter";

import "./style.scss";

export const PageContext = React.createContext<PageConfiguration | undefined>(undefined);

export function Page(props: PageProps) {
    const { header, footer, settings, utilityName, programName } = useContext(AppContext);

    const { title, isAnonymous, link, components = [], leadImage, backgroundWidth } = props;

    const isUserAuthenticated = useUserAuthenticated();
    const location = useLocation<{ referrer?: string }>();
    const content = settings?.enableIndexing ? (isAnonymous ? "all" : "none") : "none";

    const bodyBackgroundColor = useColor(settings?.backgroundOff ? "#ffffff" : settings?.backgroundColor, settings?.backgroundColorOpacity);
    const dangerForegroundColor = "#DC2131";

    const isContentCentered = backgroundWidth === "main-content";
    const showLeadImage = link === "/" && !isNil(leadImage) && (!isEmpty(leadImage?.file) || isInIframe());

    // All standard pages need default content padding if no padding is configured in portal config.
    const isPageWithDefaultSpacing = !props.custom;
    const isPaddingDefined = [props.padding, props.paddingBottom, props.paddingLeft, props.paddingRight, props.paddingTop].some(
        (v) => !isEmpty(v)
    );
    const defaultPaddingY = isPageWithDefaultSpacing && !isPaddingDefined ? "1.5rem" : undefined;
    const defaultPaddingX = isPageWithDefaultSpacing && !isPaddingDefined ? "1rem" : undefined;

    const customComponentIndex = components.findIndex((c) => c.type !== "Row");
    const pageComponents = customComponentIndex === -1 ? components : [components[customComponentIndex]];
    const referrer = location.pathname + location.search;

    const style = useWidgetStyles(
        [PageLink.ApplicationView, PageLink.CustomerHome, PageLink.Application].includes(link)
            ? {}
            : {
                  ...props,
                  overflow: "hidden",
                  paddingTop: props.paddingTop ?? defaultPaddingY,
                  paddingRight: props.paddingRight ?? defaultPaddingX,
                  paddingBottom: props.paddingBottom ?? defaultPaddingY,
                  paddingLeft: props.paddingLeft ?? defaultPaddingX,
                  maxWidth: isContentCentered ? settings?.maxWidth : undefined,
              }
    );

    useEffect(() => {
        const params = {
            utilityName: utilityName,
            programName: programName,
            pageName: title,
        } as { [key: string]: string };

        let siteTitleTemplate = isEmpty(settings.siteTitleTemplate)
            ? "{utilityName}: {programName} - {pageName}"
            : settings.siteTitleTemplate;

        Object.keys(params).forEach((paramKey) => (siteTitleTemplate = siteTitleTemplate?.replace(`{${paramKey}}`, params[paramKey])));

        if (siteTitleTemplate) {
            document.title = siteTitleTemplate;
        }
    }, [title, settings?.siteTitleTemplate, utilityName, programName]);

    useEffect(() => {
        const backgroundColor = style.background ?? bodyBackgroundColor ?? getCssColor("--bs-body-bg");
        const newColor = adjustColorContrast(dangerForegroundColor, backgroundColor);

        const cssVariablesToUpdate = {
            "--bs-danger-rgb": newColor.rgb().array(),
            "--bs-form-invalid-color": newColor.hex(),
            "--bs-form-invalid-border-color": newColor.hex(),
        };
        updateCssVariables(cssVariablesToUpdate);
    }, [style.background, bodyBackgroundColor]);

    /*
     * Use redirection only if using the portal outside of an iframe.
     * Do not redirect portal builder preview mode.
     */
    if (!isInIframe()) {
        // Redirect to login if tried to access protected page without login
        if (!isAnonymous && !isUserAuthenticated) {
            return <Redirect to={{ pathname: PageLink.SignIn, state: { referrer } }} />;
        }

        if (settings.programStartPage && location.pathname === "/" && settings.programStartPage !== "/") {
            return <Redirect to={settings.programStartPage} />;
        }

        if (location.pathname.startsWith(PageLink.CustomerAuthCode)) {
            return <Redirect to={{ pathname: PageLink.AccountCreate, state: { referrer } }} />;
        }
        if (location.pathname.startsWith(PageLink.customerEmailAuthCode)) {
            return <Redirect to={{ pathname: PageLink.ChangeEmail, state: { referrer } }} />;
        }

        // Redirect to home dashboard if already signed in and no referrer.
        if (isUserAuthenticated && location.pathname.startsWith(PageLink.SignIn) && isNil(location.state?.referrer)) {
            return <Redirect to={{ pathname: PageLink.CustomerHome }} />;
        }
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content={content} />
            </Helmet>
            {header && <Header {...header} />}
            <PageContext.Provider value={props}>
                <main
                    className={cn(
                        "page-content d-flex flex-column flex-fill outline-0",
                        { "mx-auto w-100": isContentCentered },
                        getPageClassName(props)
                    )}
                    style={style}
                    role="main"
                    id="main-content"
                    tabIndex={-1}
                >
                    {showLeadImage && <LeadImage leadImage={leadImage} mainBackgroundColor={bodyBackgroundColor} />}
                    <PageComponentsList components={pageComponents} />
                </main>
            </PageContext.Provider>
            {footer && <Footer {...footer} />}
            {isInIframe() && <ElementHighlighter />}
        </>
    );
}

interface PageProps extends PageConfiguration {}

export const PageComponentsList = ({ components }: { components: PortalWidgetConfiguration[] }) => {
    return (
        <>
            {components.map((component, index) => {
                const Component = Components[component.type];
                return Component ? <Component key={index} index={index} {...component.props} /> : null;
            })}
        </>
    );
};
