import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR, { SWRResponse } from "swr";

export const useApprovedMeasureGridId = (applicationNumber: string | undefined, industryMeasureNumber: string) => {
    const baseUrl =
        applicationNumber && industryMeasureNumber
            ? getUrl(process.env.REACT_APP_APPROVED_EQUIPMENT_GRID_ENDPOINT, { applicationNumber })
            : null;
    const query = new URLSearchParams();
    query.append("industryMeasureNumber", industryMeasureNumber);
    const url = baseUrl ? baseUrl + "?" + query.toString() : null;

    const { data, error } = useSWR(url, httpGetAuthorized) as SWRResponse<{
        gridId: string;
    }>;
    const isLoading = !error && !data && url !== null;

    return [data?.gridId, isLoading, error];
};
