import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./style.scss";

export const ExternalLinkIcon = ({ className, title }: ExternalLinkIconProps) => {
    return (
        <FontAwesomeIcon
            className={cn("external-link-icon ms-1", className)}
            icon={"arrow-up"}
            title={title ?? "The page will open in a new browser tab"}
        />
    );
};

interface ExternalLinkIconProps {
    className?: string;
    title?: string;
}
