import { isEmpty } from "lodash";

export const updateAlignClasses = (className, value, alignClasses = {}) => {
    let result = className;
    const allClasses = Object.values(alignClasses).flatMap((i) => i);
    if (isEmpty(value)) {
        result = result.filter((i) => !allClasses.includes(i));
    } else {
        result = result.filter((i) => !allClasses.includes(i)).concat(alignClasses[value]);
    }

    return result;
};

export const updateHeightClasses = (className, value) => {
    let result = className;
    const classPrefix = "h-";

    if (!["auto", "25", "50", "75", "100"].includes(value)) {
        result = result.filter((i) => !i.startsWith(classPrefix));
    } else {
        result = result.filter((i) => !i.startsWith(classPrefix)).concat(classPrefix + value);
    }

    return result;
};

export const updateWidthClasses = (className, value) => {
    let result = className;
    const classPrefix = "w-";

    if (!["auto", "25", "50", "75", "100"].includes(value)) {
        result = result.filter((i) => !i.startsWith(classPrefix));
    } else {
        result = result.filter((i) => !i.startsWith(classPrefix)).concat(classPrefix + value);
    }

    return result;
};

export const updateDisplayClasses = (className, showOnDesktop, showOnTablet, showOnMobile) => {
    let result = className;

    if (!showOnMobile) {
        result.push("d-none");
    }

    if (showOnTablet) {
        result.push("d-md-flex");
    } else {
        result.push("d-md-none");
    }

    if (showOnDesktop) {
        result.push("d-lg-flex");
    } else {
        result.push("d-lg-none");
    }

    return result;
};

export const updateFontSizeClasses = (className, value) => {
    let result = className;
    const classPrefix = "fs-";

    if (!["1", "2", "3", "4", "5", "6"].includes(value)) {
        result = result.filter((i) => !i.startsWith(classPrefix));
    } else {
        result = result.filter((i) => !i.startsWith(classPrefix)).concat(classPrefix + value);
    }

    return result;
};

export const updateFontWeightClasses = (className, value) => {
    let result = className;
    const classPrefix = "fw-";

    if (!["bold", "bolder", "normal", "light", "lighter"].includes(value)) {
        result = result.filter((i) => !i.startsWith(classPrefix));
    } else {
        result = result.filter((i) => !i.startsWith(classPrefix)).concat(classPrefix + value);
    }

    return result;
};
