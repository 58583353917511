import { useAppCorrespondence } from "components/Page/SubmittedApplicationV2/useAppCorrespondence";
import { CorrespondenceList } from "./CorrespondenceList";
import { Spinner } from "react-bootstrap";
import { DataGridPaging } from "components/DataGrid/DataGridPaging";
import { useState } from "react";
import { EmptyStatePlaceholder } from "../EmptyStatePlaceholder";
import { ReactComponent as CorrespondenceEmptyStatePlaceholder } from "./../assets/correspondence-placeholder.svg";

export const CorrespondenceSection = ({ applicationNumber }: { applicationNumber: string }) => {
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [correspondence = [], isLoadingCorrespondence, error, pagesCount] = useAppCorrespondence(applicationNumber, pageNumber, pageSize);

    const showEmptyState = (!isLoadingCorrespondence && correspondence?.length === 0) || error;

    if (isLoadingCorrespondence) {
        return (
            <Spinner as="span" animation="border" role="status" className="mx-auto my-4">
                <span className="visually-hidden">Loading correspondence...</span>
            </Spinner>
        );
    }

    return showEmptyState ? (
        <EmptyStatePlaceholder
            className="pb-5 app-correspondence-empty-state"
            placeholderImage={<CorrespondenceEmptyStatePlaceholder />}
            title="No correspondence yet"
            description="New correspondence, such as letters, emails, and texts, may appear here as you advance in your application."
        />
    ) : (
        <>
            <CorrespondenceList correspondence={correspondence} />
            <DataGridPaging
                pageNumber={pageNumber}
                pagesCount={pagesCount}
                pageSize={pageSize}
                onPageChange={setPageNumber}
                onPageSizeChange={setPageSize}
                isLoading={isLoadingCorrespondence}
            />
        </>
    );
};
