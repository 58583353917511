export const isInIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

/**
 * Is element in viewport.
 *
 */
export const isInViewport = (element: Element, offset: number = 0) => {
    var rect = element.getBoundingClientRect();

    return (
        rect.top >= 0 + offset &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - offset &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

// @ts-ignore
export const isIEBrowser = () => !!window.document.documentMode;

/**
 * IE11 compatible custom event dispatch.
 * https://stackoverflow.com/a/49071358
 */
export const dispatchEvent = (element: Element, eventType: string) => {
    var event;
    if (typeof Event === "function") {
        event = new Event(eventType, {
            cancelable: true,
            bubbles: true,
        });
    } else {
        event = document.createEvent("Event");
        event.initEvent(eventType, true, true);
    }

    element.dispatchEvent(event);
};

export const isOverflown = (element: HTMLElement) => {
    return element.scrollWidth > element.clientWidth;
};
