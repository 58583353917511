import { Container } from "components/Layout/Container";
import { Link } from "react-router-dom";

export const PrivacyPolicyPage = () => {
    return (
        <Container className="p-0">
            <h1 className="fs-5 mb-3">
                <u>
                    <b>VisionDSM Privacy Policy</b>
                </u>
            </h1>

            <h2 className="fs-5 mb-3">
                <b>Scope</b>
            </h2>
            <p>
                This Privacy Statement (“Privacy Statement”) applies to the VisionDSMTM system webpages, online services, and related
                content (collectively, the “Portal(s)") operated, or controlled by Applied Energy Group on behalf of your utility in
                connection with our services or programs.
            </p>
            <p>
                VisionDSM is a privacy-conscious service, and your trust is paramount to Applied Energy Group. This Privacy Statement
                discloses the information treatment practices, from what type of information VisionDSM gathers and track about Portal users
                to how VisionDSM collects, uses, processes, shares, or transfers such information. This Privacy Statement also describes the
                use of your access to, and how to update and correct your information. This Privacy Statement does not apply to any website
                that does not display or link to this Privacy Statement or that has its own privacy statement. We encourage you to read this
                Privacy Statement for this Portal and privacy statements for your utility that may be associated with this Portal.
            </p>
            <p>
                By accessing or continuing to use this Portal, you consent to the information collection, use, and disclosure practices
                described in this Privacy Statement. Unless otherwise expressly stated and to the extent permissible by law, if you reside
                outside the United States and continue to use these Portals, you understand and agree that your information may be processed
                in the United States where laws regarding processing of information may be less stringent than the laws in your country.
            </p>
            <p>
                This Privacy Statement may be supplemented or amended from time to time at our discretion. Accordingly, we reserve the right
                to change this policy at any time by posting the revised policy on this website. We encourage you to periodically review
                this Privacy Statement. By accessing or continuing to use our Portals after we post any such changes, you consent to accept
                this Privacy Statement as modified.
            </p>
            <p>
                If you have questions regarding this Privacy Statement, please <a href="mailto:info@appliedenergygroup.com">Contact Us</a>.
            </p>
            <h2 className="fs-5 mb-3">
                <b>Personal Information We Collect</b>
            </h2>
            <p>
                The VisionDSM Portal collects information that identifies you as a specific individual (e.g., program participants and
                Portal users) to provide an energy service or product to you on behalf of your utility and can be used to contact or
                identify you. This information may consist of, but is not limited to, your name, email address, home address, utility
                account numbers, or any other information required to provide our services and programs or to carry out a transaction you
                have requested (collectively “Personal Information”). We only will process that data if we consider it fair and lawful to do
                so or if we are otherwise required to process such information on behalf of the utilities we work for.
            </p>
            <h2 className="fs-5 mb-3">
                <b>Collection of Personal Information</b>
            </h2>
            <p>This Portal collects information about you in two ways:</p>
            <ul>
                <li>Information You Voluntarily Provide to Us</li>

                <li>Information Collected Automatically</li>
            </ul>
            <h3 className="fs-6 mb-3">
                <b>1. INFORMATION YOU VOLUNTARILY PROVIDE TO US</b>
            </h3>
            <p>
                You may choose to give us Personal Information directly in a variety of situations. For example, you may give us your
                Personal Information by:
            </p>
            <ul>
                <li>creating an account on the Portal.</li>

                <li>submitting an online or contact form through the Portal.</li>

                <li>starting and submitting an incentive or program participation application.</li>
            </ul>
            <h3 className="fs-6 mb-3">
                <b>2. INFORMATION COLLECTED AUTOMATICALLY</b>
            </h3>
            <p>
                We also may collect information using various technologies to enhance your experience on the Portal. For example, when you
                visit our Portals, we may automatically log certain information that your browser sends us, such as your IP address, browser
                type and language, access time, and referring website addresses (“Automated Non-Identifiable Data”). We may collect
                information about the pages you view within our Portals and other actions you take while visiting us. We may use such
                technologies to determine whether you've opened an e-mail or clicked on a link contained in an e-mail to the Portal. For
                details regarding the technologies we employ, see Cookies and Other Technologies below.
            </p>
            <h3 className="fs-6 mb-3">
                <b>Cookies</b>
            </h3>
            <p>
                The Portal has one cookie to record whether you have chosen to accept cookies. If you choose to accept cookies this decision
                is recorded in a cookie. No personal information is recorded with this decision. Your utility may require the use of
                additional cookies for their purpose please refer to your utility privacy policy on the use of these Cookies.
            </p>
            <h3 className="fs-6 mb-3">
                <b>Other Technologies</b>
            </h3>
            <p>
                As mentioned above, the Portal collects information from your visits to the Portal to help us gather statistics about usage
                and effectiveness, personalize your experience, and tailor the interactions with you. These technologies are internal to the
                Portal and are not using any third-party services. Please note that the Portal does not respond to “Do Not Track” signals.
            </p>
            <h2 className="fs-5 mb-3">
                <b>Use of Personal Information</b>
            </h2>
            <p>We may use your Personal Information to:</p>
            <ul>
                <li>communicate with you,</li>
                <li>process an application,</li>
                <li>contact you via surveys to conduct research,</li>
                <li>register you for a service or program,</li>
                <li>send you direct mail, email, SMS text message or telephone contact from us,</li>
                <li>comply with a state public utilities commission request or order,</li>
            </ul>
            <p>or</p>
            <ul>
                <li>
                    disclose your Personal Information as required by law and when we believe that disclosure is necessary to protect our
                    rights, or the rights of others, or to comply with a judicial proceeding, court order, or legal process served on our
                    Portals.
                </li>
            </ul>
            <p>
                The VisionDSM service, Applied Energy Group and its trusted business partners, agents, subcontractors, or third-party
                vendors only uses Personal Information for purposes consistent with this Privacy Statement. Please note that the utility may
                use the Personal Information it processes about you (including through the Portal) for its own purposes. Please review the
                utility’s privacy policy to learn more.
            </p>
            <h3 className="fs-6 mb-3">
                <b>Marketing</b>
            </h3>
            <p>
                The information you provide to us, as well as the information we have collected about you indirectly, may be used by the
                VisionDSM to market additional applicable programs and services to you on behalf of your utility. You may opt out of any
                marketing emails that you receive from us by opt-out in those emails or by contacting us using the information below.
            </p>
            <h2 className="fs-5 mb-3">
                <b>Disclosure of Personal Information</b>
            </h2>
            <h3 className="fs-6 mb-3">
                <b>Contracted Third-Party Vendors</b>
            </h3>
            <p>
                Services offered by the Portal may utilize a few third-party vendors to enhance your experience to provide special features,
                such as online payment processing, implementation services, and program materials. All such third-party vendors are
                prohibited from using your Personal Information except to provide these services to you, requiring them to maintain the
                confidentiality of your information. In addition, these third-party vendors are subject to contractual prohibitions on
                selling or sharing any Personal Information.
            </p>
            <h3 className="fs-6 mb-3">
                <b>Legal Disclosure</b>
            </h3>
            <p>
                Please be aware that in certain circumstances, your Personal Information may be subject to disclosure to government agencies
                pursuant to judicial proceeding, court order, or legal process. We also may share your information to protect the rights or
                property of Applied Energy Group, our business partners or alliances, or utility clients when we have reasonable grounds to
                believe that such rights or property have been or could be affected.
            </p>
            <p>
                Applied Energy Group will disclose Personal Information without notice, to law enforcement, government officials, or other
                investigative personnel if we determine it is required to do so by law. Applied Energy Group also may disclose such
                information based on its good faith belief that such action is necessary to:
            </p>
            <ul>
                <li>Conform to the edicts of the law or comply with legal process associated with VisionDSM Portals service,</li>

                <li>Protect and defend the rights or property of Applied Energy Group or</li>

                <li>
                    Act under exigent circumstances to protect the rights, property, or safety of users of our Portals, the public, or
                    Applied Energy Group.
                </li>
            </ul>
            <h2 className="fs-5 mb-3">
                <b>Data Security and Retention</b>
            </h2>
            <p>
                We implement commercially reasonable physical, administrative, and technical safeguards to help us protect the
                confidentiality, security, and integrity of your Personal Information and prevent the loss, misuse, unauthorized access,
                unauthorized interception, or information alteration.
            </p>
            <p>
                We retain your personal information based on legal requirements or the utility business needs. Generally, the VisionDSM
                system only retains personal information as defined by the utility for the duration of the relationship with the utility or
                as long as required by law.
            </p>
            <h2 className="fs-5 mb-3">
                <b>Your Rights</b>
            </h2>
            <p>
                If you are a resident of a jurisdiction with an applicable data privacy law and to the extent we determine the purposes and
                means of processing your Personal Information, you may have certain rights available to you in relation to your personal
                information. These rights may include:
            </p>
            <ul>
                <li>The right to be informed about our data collection practices;</li>

                <li>The right to access and correct your data;</li>

                <li>The right to erase or delete your data;</li>

                <li>The right to data portability;</li>

                <li>The right to opt out of the sale or sharing of your information;</li>

                <li>; </li>

                <li>; and</li>

                <li>The right to withdraw consent (to the extent applicable). </li>
            </ul>
            <p>
                To exercise any of the rights listed above, please contact your utility to exercise your rights whereby your request will be
                reflected within this Portal.
            </p>
            <p>
                If you are a resident of California, please review our <Link to="/aeg-privacy-policy-ccpa">California Privacy Notice</Link>{" "}
                for additional rights that may apply to you.
            </p>
            <p>
                To the extent that we process your personal information on behalf of a utility, you need to contact the utility directly to
                exercise any rights applicable to you.
            </p>
            <h2 className="fs-5 mb-3">
                <b>International Users</b>
            </h2>
            <p>
                The information that we collect through or in connection with our services is transferred to and processed in the United
                States for the purposes described above. We may subcontract the processing of your data to, or otherwise share your data
                with, affiliates or third parties in the United States or countries other than your country of residence. The data
                protection laws in these countries may be different from, and less stringent than, those in your country of residence. By
                using the Portal or by providing any Personal Information to us, you expressly consent to such transfer and processing.
            </p>
            <h2 className="fs-5 mb-3">
                <b>Children</b>
            </h2>
            <p>
                Content on the Platform is directed at individuals over the age of 18 and is not directed at children under the age of 13.
                We do not knowingly collect personally identifiable information from children under the age of 13.
            </p>
            <h2 className="fs-5 mb-3">
                <b>Privacy Questions and Access</b>
            </h2>
            <p>
                For any additional questions about the VisionDSM privacy policy you may contact Applied Energy Group by email,{" "}
                <a href="mailto:info@appliedenergygroup.com">Contact Us</a>.
            </p>
        </Container>
    );
};
