import { Dropdown, Form } from "react-bootstrap";
import cn from "classnames";
import { capitalize, isEmpty } from "lodash";
import { ContractorFilter, ContractorSearchParams, Filter } from "./types";
import { ContractorData } from "components/utils/contacts";

export const SingleFilterItem = ({
    property,
    propertyLabel,
    id,
    className,
    filter,
    onFilterChange,
    onEnter,
    disabled,
    placeholder = "",
    errors,
}: any) => {
    return (
        <div className={cn("single-filter-item py-1p5", className)}>
            <Form.Label className="m-0 flex-shrink-0 mb-1">{propertyLabel}</Form.Label>
            <Form.Control
                type="text"
                id={id}
                aria-label={propertyLabel}
                isInvalid={errors?.[property]}
                value={filter.find((f: Filter) => f.key === property)?.value ?? ""}
                onChange={(event) => onFilterChange(property, event.target.value)}
                onKeyDown={(event) => onEnter(property, event)}
                disabled={disabled}
                placeholder={placeholder}
                aria-invalid={!!errors?.[property]}
                {...(!!errors?.[property] ? { "aria-describedby": `filter-${property}-error` } : {})}
            />
            {errors?.[property] && (
                <>
                    <Form.Control.Feedback id={`filter-${property}-error`} type="invalid">
                        {errors[property]}
                    </Form.Control.Feedback>
                </>
            )}
        </div>
    );
};

export const SingleFilterMultiSelectItem = ({
    property,
    propertyLabel,
    filter,
    onSelectValue,
    options,
    isLoadingOptions,
    disabled,
}: {
    property: keyof ContractorData | "zipradius";
    propertyLabel: string;
    filter: Filter[];
    onSelectValue: (key: keyof ContractorData | "zipradius", value?: string, remove?: boolean) => void;
    options?: any;
    isLoadingOptions?: boolean;
    disabled?: boolean;
}) => {
    const filterCount = filter.filter((f) => f.key === property).length;

    return (
        <Dropdown className="single-filter-select-item py-1p5 w-100" autoClose={"outside"}>
            <Dropdown.Toggle
                id={`${property}-dropdown-autoclose-true`}
                className="w-100 justify-items-between bg-white text-body btn-as-dropdown btn-fixed-medium-size d-flex align-items-center"
                disabled={disabled || isLoadingOptions}
                aria-label={propertyLabel}
            >
                <span className="me-auto">
                    {capitalize(property)} {filterCount > 0 ? `(${filterCount})` : ""}
                </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="contractor-search-dropdown">
                {options?.map((o: { id: string; parameterValue: string }) => (
                    <Dropdown.Item key={o.id} as="div">
                        <Form.Check id={`status-${o.id}`}>
                            <Form.Check.Input
                                checked={!!filter.find((f) => f.key === property && f.value === o.parameterValue)}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        onSelectValue(property, o.parameterValue);
                                    } else {
                                        onSelectValue(property, o.parameterValue, true);
                                    }
                                }}
                            />
                            <Form.Check.Label className="w-100">{o.parameterValue}</Form.Check.Label>
                        </Form.Check>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export const SingleFilterSelectItem = ({
    property,
    propertyLabel,
    id,
    className,
    filter,
    onSelectValue,
    options,
    isLoadingOptions,
    disabled,
    placeholder = "",
}: any) => {
    return (
        <div className={cn("single-filter-select-item py-1p5 w-100", className)}>
            <Form.Select
                id={id}
                aria-label={propertyLabel}
                value={filter.find((f: Filter) => f.key === property)?.value ?? ""}
                onChange={(event) => onSelectValue(property, event.target.value)}
                disabled={isLoadingOptions || disabled}
            >
                <option className="d-none" value="" disabled selected>
                    {propertyLabel}
                </option>
                <option value=""></option>
                {options?.map((o: { id: string; parameterValue: string }) => (
                    <option key={o.id} value={o.parameterValue}>
                        {o.parameterValue}
                    </option>
                ))}
            </Form.Select>
        </div>
    );
};

export const getFilters = (
    contractorSearchParams: ContractorSearchParams | undefined,
    isLoadingParams: boolean,
    zipFilterActive: boolean
): ContractorFilter[] => [
    {
        property: "technologies",
        propertyLabel: "Technologies",
        id: "filter-technologies",
        options: contractorSearchParams?.technologies,
        isLoadingOptions: isLoadingParams,
    },
    {
        property: "services",
        propertyLabel: "Services",
        id: "filter-services",
        options: contractorSearchParams?.services,
        isLoadingOptions: isLoadingParams,
    },
    { property: "zip", propertyLabel: "Postal Code", id: "filter-zip" },
    ...(zipFilterActive
        ? [
              {
                  property: "zipradius" as keyof ContractorData,
                  propertyLabel: "Zip Radius",
                  id: "filter-zip-radius",
                  options: [
                      { id: 1, parameterValue: 5 },
                      { id: 2, parameterValue: 10 },
                      { id: 3, parameterValue: 20 },
                      { id: 4, parameterValue: 100 },
                  ],
              },
          ]
        : []),
    { property: "company", propertyLabel: "Company", id: "filter-company" },
    { property: "phone", propertyLabel: "Phone", id: "filter-phone" },
    { property: "email", propertyLabel: "Email", id: "filter-email" },
    { property: "fullAddress", propertyLabel: "Address", id: "filter-address" },
];

export const getSearchAttr = (currentFilter: Filter[], zipFilterActive: boolean) => {
    let value = currentFilter
        .filter((f) => {
            return !isEmpty(f.value) && !(!zipFilterActive && f.key === "zipradius");
        })
        .map((f) => `${f.key}=${f.value}`)
        .join("|");
    return value;
};

export const getZipFilterActive = (selectedFilters: Filter[]) => {
    return selectedFilters.some((sf) => sf.key === "zip");
};
