import { Button } from "components/Button";
import { Heading } from "components/Heading";
import { Container } from "components/Layout/Container";
import { AnalyticsEventType, useAnalyticsEvent } from "components/utils/analytics";

import "./PageNotFoundPage.scss";

export function PageNotFoundPage() {
    useAnalyticsEvent(AnalyticsEventType.PAGE_404, { path: document.location.pathname });

    return (
        <Container className="p-0 align-middle text-center page-not-found-page">
            <Heading className="error-code fw-bold">404</Heading>
            <Heading className="fs-3 p-2 fw-bold">Page not found</Heading>
            <p className="fs-6 p-2 text-wrap">The link you clicked may be broken or the page may have been removed or renamed.</p>
            <Button className="p-2" href="/" target="_self" variant="primary" text="Go to homepage" />
        </Container>
    );
}
