import { Heading } from "components/Heading";
import { Alert } from "react-bootstrap";
import { usePageTopAndBottomRows } from "components/utils/page";
import { PageComponentsList } from ".";
import { ApplicationPageContainer } from "./ApplicationPage/ApplicationPageContainer";
import { HeadingLevel } from "types/HeadingLevel";
import { isEmpty } from "lodash";

export function AnonymousThankYouPage() {
    const { topRows, bottomRows } = usePageTopAndBottomRows();
    const alertHeadingLevel = isEmpty(topRows) ? HeadingLevel.H1 : HeadingLevel.H4;

    return (
        <>
            <PageComponentsList components={topRows} />
            <ApplicationPageContainer>
                <Alert variant="success">
                    <Heading level={alertHeadingLevel} className="fs-4 alert-heading">
                        Thank You!
                    </Heading>
                    <p className="mb-0">Application submitted.</p>
                </Alert>
            </ApplicationPageContainer>
            <PageComponentsList components={bottomRows} />
        </>
    );
}
