import { toArray, findRelSchemaAndField } from "../utils";
import { validateFields } from "./validateAction";

function doRequire({ field, schema }) {
    if (!schema.required) {
        schema.required = [];
    }

    if (schema.required.indexOf(field) === -1) {
        schema.required.push(field);
    }
}

/**
 * Makes provided field required
 *
 * @param params
 * @param schema
 * @param uiSchema
 * @returns {{schema: *, uiSchema: *}}
 */
function requireField({ field }, schema) {
    toArray(field).forEach((field) => doRequire(findRelSchemaAndField(field, schema)));
}

requireField.validate = validateFields("require", function ({ field }) {
    return field;
});

export default requireField;
