import { DataGridConfig } from "components/DataGrid/types";
import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR, { SWRResponse } from "swr";
import { isInIframe } from "./dom";

export const useGridConfig = (dataGridId: string | undefined): [config: DataGridConfig | undefined, isLoading: boolean, error: any] => {
    const url =
        dataGridId && !isInIframe()
            ? getUrl(process.env.REACT_APP_DATA_GRID_CONSTRUCT_ENDPOINT, { dataGridId }) + "?returnType=JSON"
            : null;
    const { data, error } = useSWR(url, httpGetAuthorized) as SWRResponse<{
        grid: any;
    }>;

    const isLoading = !error && !data && url !== null;
    let config = undefined;

    if (data?.grid?.[0]) {
        config = data?.grid?.[0];
    }

    return [config, isLoading, error];
};
