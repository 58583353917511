import { useRef, useState, useEffect } from "react";
import { postCreatePdf, replaceHttpLinksToHttps } from "components/utils";
import { ErrorSummary } from "components/ErrorSummary";
import { Spinner } from "react-bootstrap";

export const HtmlContentAsPdf: React.FC<{
    fileContent: string;
    fileName: string;
    onClose: () => void;
}> = ({ fileContent, fileName, onClose }) => {
    const isGeneratingPdf = useRef(false);
    const dataUrl = useRef<string>();

    const [state, setState] = useState<{
        isLoading: boolean;
        fileData?: {
            blob: Blob;
        };
        error?: string;
    }>({
        fileData: undefined,
        isLoading: true,
        error: undefined,
    });

    useEffect(() => {
        const generatePdf = async () => {
            try {
                const fileData = await postCreatePdf({
                    fileName,
                    content: replaceHttpLinksToHttps(fileContent),
                });

                setState({
                    fileData,
                    isLoading: false,
                });

                dataUrl.current = URL.createObjectURL(fileData.blob);
            } catch (error: any) {
                setState({
                    isLoading: false,
                    error: error?.message,
                });
            }
        };

        if (!isGeneratingPdf.current) {
            isGeneratingPdf.current = true;
            generatePdf();
        }

        return () => {
            // Need a delay to avoid revoking the URL before the browser has a chance to load the PDF.
            setTimeout(() => {
                if (dataUrl.current) {
                    URL.revokeObjectURL(dataUrl.current);
                    dataUrl.current = undefined;
                    isGeneratingPdf.current = false;
                }
            }, 3000);
        };
    }, [fileContent, fileName]);

    if (state.isLoading) {
        return (
            <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                <Spinner as="span" animation="border" size="sm" role="status">
                    <span className="visually-hidden">Loading</span>
                </Spinner>
            </div>
        );
    }

    if (state.error) {
        return <ErrorSummary errorSummary={state.error} />;
    }

    return (
        <object data={dataUrl.current} width="100%" height="100%" type={state.fileData?.blob.type}>
            <p>Your browser does not support PDFs. You can download the PDF to view it.</p>
            <a href={dataUrl.current} download={fileName} onClick={onClose}>
                Download PDF
            </a>
        </object>
    );
};
