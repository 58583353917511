import { PageLink } from "components/App/utils";
import { Container } from "components/Layout/Container";
import { Link } from "react-router-dom";

export function SuccessPage(props: SuccessPageProps) {
    return (
        <Container className="p-0">
            <p>{props.text}</p>
            <Link to={props.returnUrl ?? PageLink.CustomerHome}>Return to Home Page</Link>
        </Container>
    );
}

interface SuccessPageProps {
    text?: string;
    returnUrl?: string;
}
