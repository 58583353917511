import React from "react";

import { WidgetProps } from "@rjsf/core";
import WidgetLabel from "./WidgetLabel";
import Form from "react-bootstrap/Form";

const CheckboxWidget = (props: WidgetProps) => {
    const {
        id,
        value,
        required,
        disabled,
        readonly,
        label,
        uiSchema,
        schema,
        autofocus,
        rawErrors = [],
        onChange,
        onBlur,
        onFocus,
    } = props;

    const isInvalid = rawErrors && rawErrors.length > 0;
    const _onChange = ({ target: { checked } }: React.FocusEvent<HTMLInputElement>) => onChange(checked);
    const _onBlur = ({ target: { checked } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, checked);
    const _onFocus = ({ target: { checked } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, checked);

    const desc = (
        <WidgetLabel
            id={id}
            isFieldset
            label={label}
            uiSchema={uiSchema}
            schema={schema}
            required={required}
            rawErrors={rawErrors}
        />
    );
    return (
        <Form.Check
            id={id}
            className={`checkbox ${disabled || readonly ? "disabled" : ""}`}
            label={desc}
            checked={typeof value === "undefined" ? false : value}
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            isInvalid={isInvalid}
            aria-describedby={isInvalid ? `${id}-error` : undefined}
            aria-invalid={isInvalid}
            onChange={_onChange}
            type="checkbox"
            onBlur={_onBlur}
            onFocus={_onFocus}
        />
    );
};

export default CheckboxWidget;
