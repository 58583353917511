import { utils } from "@rjsf/core";
import { GeneralProcedureWidget } from "./widgets/GeneralProcedureWidget";
import { ReadOnlyValueWidget } from "./widgets/ReadOnlyValueWidget";
import { ReadOnlyValueWithNoDataWidget } from "./widgets/ReadOnlyValueWithNoDataWidget";
import CheckboxesWidget from "./widgets/CheckboxesWidget";
import SelectWidget from "./widgets/SelectWidget";
import RadioWidget from "./widgets/RadioWidget";
import { ObjectFieldTemplate } from "./ObjectFieldTemplate";
import { FieldTemplate } from "./FieldTemplate";
import ErrorList from "./ErrorList";

import CheckboxWidget from "./widgets/CheckboxWidget";
import TextareaWidget from "./widgets/TextareaWidget";
import TextWidget from "./widgets/TextWidget";
import UpDownWidget from "./widgets/UpDownWidget";
import DateWidget from "./widgets/DateWidget";
import FileWidget, { DropzoneWidget } from "./widgets/FileWidget";
import PasswordWidget from "./widgets/PasswordWidget";
import EmailWidget from "./widgets/EmailWidget";
import DescriptionField from "./fields/DescriptionField";
import TitleField from "./fields/TitleField";
import ApplicationContactsWidget from "./widgets/ApplicationContactsWidget";
import { StatesWidget } from "./widgets/StatesWidget";
import { WorkflowWidget } from "./widgets/WorkflowWidget";
import { AdditionalContactsWidget } from "./widgets/AdditionalContactsWidget";
import { EquipmentBlockWidget } from "./widgets/EquipmentBlockWidget";
import { StatementWidget } from "./widgets/StatementWidget";
import ApplicationNameWidget from "./widgets/ApplicationNameWidget";
import { RebateTotalGridWidget } from "./widgets/RebateTotalGridWidget";
import { ContractorLookupListWidget } from "./widgets/ContractorLookupListWidget";
import ImageWidget from "./widgets/ImageWidget";
import SignatureWidget from "./widgets/SignatureWidget";
import { EncryptedTextWidget } from "./widgets/EncryptedTextWidget";

const { fields, widgets } = utils.getDefaultRegistry();

const customWidgets = {
    TextWidget,
    PasswordWidget,
    EmailWidget,
    TextareaWidget,
    CheckboxWidget,
    CheckboxesWidget,
    RadioWidget,
    SelectWidget,
    UpDownWidget,
    ViewOnlyWidget: ReadOnlyValueWidget,
    date: DateWidget,
    file: FileWidget,
    dropzone: DropzoneWidget,
    state: StatesWidget,
    readonlyvalue: ReadOnlyValueWidget,
    rawhtml: ReadOnlyValueWidget,
    statement: StatementWidget,
    nontextspacer: ReadOnlyValueWidget,
    defaultdate: DateWidget,
    spacer: ReadOnlyValueWidget,
    largecheckbox: CheckboxWidget,
    largecheckboxes: CheckboxesWidget,
    formwizardtarget: ReadOnlyValueWidget,

    additionalcontacts: AdditionalContactsWidget,
    applicationcontacts: ApplicationContactsWidget,
    equipmentblock: EquipmentBlockWidget,
    auditequipmentblock: EquipmentBlockWidget,

    applicationname: ApplicationNameWidget,

    auditresult: ReadOnlyValueWithNoDataWidget,
    auditresults: ReadOnlyValueWithNoDataWidget,
    auditworkorder: ReadOnlyValueWithNoDataWidget,
    contractorlookuplist: ContractorLookupListWidget,
    eqipmentrecomms: ReadOnlyValueWithNoDataWidget,
    rebatetotalgrid: RebateTotalGridWidget,
    workflow: WorkflowWidget,
    generalprocedure: GeneralProcedureWidget,
    image: ImageWidget,
    signature: SignatureWidget,
    encryptedtext: EncryptedTextWidget,
};

export const theme = {
    // ArrayFieldTemplate,
    ObjectFieldTemplate,
    FieldTemplate,
    fields: { ...fields, TitleField, DescriptionField },
    widgets: { ...widgets, ...customWidgets },
    ErrorList,
};
