import { Components } from "components";
import { AppContext } from "components/App/AppContext";
import { isEmpty } from "lodash";
import { useContext } from "react";
import { Container as BootstrapContainer } from "react-bootstrap";

export function Container(props) {
    const { id, className, hidden, components = [], children } = props;
    const { settings = {} } = useContext(AppContext);
    const { maxWidth } = settings;

    const containerStyle = !isEmpty(maxWidth) ? { maxWidth } : undefined;

    return (
        <BootstrapContainer id={id} fluid className={className} style={containerStyle} hidden={hidden}>
            {children}
            {components.map((component, index) => {
                const Component = Components[component.type];
                return Component ? <Component key={index} index={index} {...component.props} /> : null;
            })}
        </BootstrapContainer>
    );
}
