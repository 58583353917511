import { EquipmentEditSubmitItem } from "types/EquipmentEditSubmitItem";
import { EquipmentBlockContextValue } from "types/EquipmentBlockContextValue";
import { createContext } from "react";

export const EquipmentBlockContext = createContext<EquipmentBlockContextValue>({
    applicationNumber: undefined,
    allowEquipmentEntry: false,
    allowEquipmentCopy: false,
    onEquipmentAdd: () => {},
    onEquipmentAddSubmit: () => {},
    onEquipmentAddClose: () => {},
    onEquipmentEdit: () => {},
    onEquipmentEditSubmit: (equipmentNumber: string, equipmentItem: EquipmentEditSubmitItem, hasApprovedMeasure?: boolean) => {},
    onEquipmentEditClose: () => {},
    onEquipmentCopy: () => {},
    onEquipmentCopySubmit: (equipmentItem: EquipmentEditSubmitItem, hasApprovedMeasure?: boolean) => {},
    onEquipmentCopyClose: () => {},
});
