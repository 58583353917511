import useWidgetStyles from "components/utils/useWidgetStyles";
import { isNil } from "lodash";
import { Image as BootstrapImage } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { ImagePlaceholder } from "components/ImagePlaceholder";
import { WidgetStylesProps } from "types/WidgetStylesProps";
import { handleLinkClick, isExternalLink } from "components/utils/links";
import { useCallback } from "react";

import "./Image.scss";

export function Image(props: ImageProps) {
    const { src, altText, imageLink, openInNewTab, customSize, imageSize } = props;
    const history = useHistory();
    let style = useWidgetStyles(props);

    let customStyle;

    if (imageSize === "custom" && !isNil(customSize)) {
        customStyle = { maxWidth: parseFloat(customSize) + "%" };
    }

    style = {
        ...style,
        ...customStyle,
    };

    const onImageLinkClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            const target = isExternalLink(imageLink) ? (openInNewTab ? "_blank" : "_self") : "_self";
            handleLinkClick(event, imageLink, target, history);
        },
        [history, imageLink, openInNewTab]
    );

    if (!src) {
        return <ImagePlaceholder />;
    }

    if (imageLink) {
        return (
            <a href={imageLink} rel="noopener noreferrer" onClick={(event) => onImageLinkClick(event)}>
                <BootstrapImage className={cn("image-widget-picture", imageSize)} fluid style={style} src={src} alt={altText} />
            </a>
        );
    }

    return <BootstrapImage className={cn("image-widget-picture", imageSize)} fluid style={style} src={src} alt={altText} />;
}

export interface ImageProps extends WidgetStylesProps {
    src?: string;
    altText?: string;
    imageLink?: string;
    openInNewTab?: boolean;
    customSize?: string;
    imageSize?: string;
}
