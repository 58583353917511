import { isNil } from "lodash";
import { ApplicationFormEntryTask } from "./ApplicationFormEntryTask";
import { ApplicationFormFieldEntryTask } from "./ApplicationFormFieldEntryTask";
import { CheckboxTask } from "./CheckboxTask";
import { FileUploadTask } from "./FileUploadTask";
import { NewMeasureTask } from "./NewMeasureTask";
import { TaskProps, WorkflowTaskStepType } from "./types";

export const Task = (props: TaskProps) => {
    const { stepType } = props.task;
    let Component: React.ElementType | null;

    switch (stepType) {
        case WorkflowTaskStepType.DocumentUpload:
            Component = FileUploadTask;
            break;
        case WorkflowTaskStepType.ApplicationFormEntry:
            Component = ApplicationFormEntryTask;
            break;
        case WorkflowTaskStepType.ApplicationFormFieldEntry:
            Component = ApplicationFormFieldEntryTask;
            break;
        case WorkflowTaskStepType.CreateNewMeasure:
            Component = NewMeasureTask;
            break;
        case WorkflowTaskStepType.PerformTask:
            Component = CheckboxTask;
            break;
        default:
            Component = null;
            break;
    }

    if (isNil(Component)) {
        return null;
    }

    return <Component {...props} />;
};
