import { Icon } from "components/Icon";
import { useState } from "react";
import { Badge, Modal, Nav, Navbar } from "react-bootstrap";
import { Button } from "components/Button";
import { NotificationsMenuProps, NotificationsTab } from "./utils";
import { TaskList } from "./TaskList";
import { MessageList } from "./MessageList";
import cn from "classnames";

export const MobileView = ({ notificationCount, tasks, notes, navTabs, onNotificationClick }: NotificationsMenuProps) => {
    const [modalShow, setModalShow] = useState(false);
    const [activeTab, setActiveTab] = useState(NotificationsTab.Tasks);

    return (
        <>
            <Button
                variant="outline-secondary position-relative"
                aria-label="unread notifications"
                aria-haspopup="dialog"
                onClick={() => setModalShow(!modalShow)}
                data-metadata={JSON.stringify({ name: "Notifications" })}
            >
                <Icon icon={["fal", "bell"]} size={"lg"} />
                {notificationCount > 0 && (
                    <p className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger text-white ms-4 small">
                        {notificationCount}
                        <span className="visually-hidden">unread notifications</span>
                    </p>
                )}
            </Button>
            <Modal
                className="notifications-dropdown p-0"
                show={modalShow}
                fullscreen="true"
                onHide={() => setModalShow(false)}
                dialogClassName="m-0 border-none"
                centered
                onBackdropClick={() => setModalShow(false)}
                animation={false}
                aria-labelledby="notifications-menu-title"
            >
                <Modal.Header closeButton className="border-bottom">
                    <Modal.Title>
                        <h5 id="notifications-menu-title" className="m-0 fw-semi-bold">
                            Notifications
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="notifications-menu overflow-hidden">
                    <Navbar className="p-0">
                        <Nav variant="tabs" className="border-bottom-0" activeKey={activeTab} onSelect={(k: any) => setActiveTab(k)}>
                            {navTabs.map((t) => {
                                return (
                                    <Nav.Item key={t.key}>
                                        <Nav.Link
                                            eventKey={t.key}
                                            id={`header-nav-item-${t.key}`}
                                            aria-current={t.key === activeTab}
                                            className={cn("px-3 text-body", { "active-tab": activeTab === t.key })}
                                            data-metadata={JSON.stringify({ name: t.text })}
                                        >
                                            <div className="me-1 d-flex flex-row align-items-center gap-2 lh-lg">
                                                <Icon icon={t.icon}></Icon>
                                                <p className="m-0">{t.text}</p>
                                                {!!t.counter && <Badge className="rounded-5 bg-danger text-white">{t.counter}</Badge>}
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                );
                            })}
                        </Nav>
                    </Navbar>
                    <hr className="m-0 pb-3" />
                    {activeTab === NotificationsTab.Tasks && <TaskList tasks={tasks} onNotificationClick={onNotificationClick} />}
                    {activeTab === NotificationsTab.Inbox && <MessageList notes={notes} onNotificationClick={onNotificationClick} />}
                </Modal.Body>
            </Modal>
        </>
    );
};
