import { toArray } from "components/utils/array";
import { httpGetAuthorized } from "components/utils/http";
import { useMemo } from "react";
import useSWR, { KeyedMutator, SWRResponse } from "swr";
import { ApplicationStatus } from "./types";
import { isInIframe } from "components/utils/dom";

export const useCustomerPortalDetails = (
    programNumber: string | undefined
): [customerPortalDetails: CustomerPortalDetailsModel | undefined, isLoading: boolean, error: any, refresh: KeyedMutator<any>] => {
    let url = null;
    if (programNumber && !isInIframe()) {
        const query = new URLSearchParams();
        query.append("programNumber", programNumber);
        url = process.env.REACT_APP_CUSTOMER_DETAILS_ENDPOINT + "?" + query.toString();
    }

    const { data, error, mutate } = useSWR(url, httpGetAuthorized) as SWRResponse<CustomerPortalDetailsResponse>;
    const isLoading = !error && !data && url !== null;

    const totalRebate = data?.CustomerPortalDetail?.TotalRebate;
    const totalNotifications = data?.CustomerPortalDetail?.ApplicationCounts?.totalNotifications;
    const totalNotes = data?.CustomerPortalDetail?.ApplicationCounts?.TotalNotes;
    const totalTasks = data?.CustomerPortalDetail?.ApplicationCounts?.TotalTasks;
    const savedApplicationsCount = data?.CustomerPortalDetail?.ApplicationCounts?.SavedApplications;
    const completedApplicationsCount = data?.CustomerPortalDetail?.ApplicationCounts?.CompletedApplications;
    const applicationStatusList = data?.CustomerPortalDetail?.PortalStatusList;
    const favoriteApplicationsCount = data?.CustomerPortalDetail?.ApplicationCounts?.BookMarkedApplications;

    const result = useMemo(
        () => ({
            totalRebate: totalRebate ? Number(totalRebate) : 0,
            totalNotifications: totalNotifications ? Number(totalNotifications) : 0,
            totalNotes: totalNotes ? Number(totalNotes) : 0,
            totalTasks: totalTasks ? Number(totalTasks) : 0,
            savedApplicationsCount: savedApplicationsCount ? Number(savedApplicationsCount) : 0,
            completedApplicationsCount: completedApplicationsCount ? Number(completedApplicationsCount) : 0,
            favoriteApplicationsCount: favoriteApplicationsCount ? Number(favoriteApplicationsCount) : 0,
            applicationStatusList: toArray(applicationStatusList),
        }),
        [
            applicationStatusList,
            completedApplicationsCount,
            favoriteApplicationsCount,
            savedApplicationsCount,
            totalRebate,
            totalNotifications,
            totalNotes,
            totalTasks,
        ]
    );

    return [result, isLoading, error, mutate];
};

interface CustomerPortalDetailsModel {
    totalRebate: number;
    totalNotifications: number;
    totalNotes: number;
    totalTasks: number;
    savedApplicationsCount: number;
    completedApplicationsCount: number;
    favoriteApplicationsCount: number;
    applicationStatusList: ApplicationStatus[];
}

interface CustomerPortalDetailsResponse {
    CustomerPortalDetail: {
        TotalRebate: string;
        PortalStatusList: ApplicationStatus[];
        ApplicationCounts: {
            SavedApplications: string;
            CompletedApplications: string;
            BookMarkedApplications: string;
            TotalNotes: string;
            TotalTasks: string;
            totalNotifications: string;
        };
    };
}
