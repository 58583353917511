import React from "react";
import { WidgetProps } from "@rjsf/core";

const UpDownWidget = (props: WidgetProps) => {
    const { registry, schema } = props;
    const { TextWidget } = registry.widgets;

    const min = schema.exclusiveMinimum ? schema.exclusiveMinimum + 1 : schema.minimum;
    const max = schema.exclusiveMaximum ? schema.exclusiveMaximum - 1 : schema.maximum;

    return <TextWidget {...props} min={min} max={max} type="number" />;
};

export default UpDownWidget;
