import { PageContext } from "components/Page";
import { isEmpty } from "lodash";
import { useContext, useMemo } from "react";
import { PageConfiguration } from "types/PageConfiguration";

export const getPageClassName = (page: PageConfiguration) => {
    return (
        "page-" +
        (page.link ?? "")
            .split("/")
            .filter((i) => !isEmpty(i))
            .join("-")
    );
};

export const usePageTopAndBottomRows = () => {
    const page = useContext(PageContext);

    return useMemo(() => {
        const components = page?.components ?? [];
        const customComponentIndex = components.findIndex((c) => c.type !== "Row");
        const topRows = customComponentIndex > -1 && page?.topRowsOff === false ? components.slice(0, customComponentIndex) : [];
        const bottomRows = customComponentIndex > -1 && page?.bottomRowsOff === false ? components.slice(customComponentIndex + 1) : [];

        return {
            topRows,
            bottomRows,
        };
    }, [page]);
};
