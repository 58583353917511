import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useContext } from "react";
import { AppContext } from "components/App/AppContext";
import { useHistory } from "react-router-dom";
import { PageLink } from "components/App/utils";
import { NotificationsTab, useCustomerNotifications } from "./utils";
import { MobileView } from "./MobileView";
import { DesktopView } from "./DesktopView";
import useMediaQuery from "components/utils/useMediaQuery";

const NotificationsMenu = (props: { className?: string; notificationCount: number; totalNotes: number; totalTasks: number }) => {
    const { programNumber } = useContext(AppContext);
    const [tasks, notes] = useCustomerNotifications(programNumber);
    const history = useHistory();
    const isMobile = useMediaQuery("(max-width: 768px)");

    const onNotificationClick = (applicationNumber: string, tab: string) => {
        return history.push(`${PageLink.ApplicationView}?applicationNumber=${applicationNumber}&activeTab=${tab}`);
    };

    const navTabs: Array<{ text: string; counter?: number; key: NotificationsTab; icon: IconProp; component?: JSX.Element }> = [
        {
            text: "Tasks",
            counter: props.totalTasks,
            key: NotificationsTab.Tasks,
            icon: ["fal", "list-check"],
        },
        {
            text: "Inbox",
            counter: props.totalNotes,
            key: NotificationsTab.Inbox,
            icon: ["fal", "envelope"],
        },
    ];

    const menuProps = { ...props, tasks, notes, navTabs, onNotificationClick };

    return isMobile ? <MobileView {...menuProps} /> : <DesktopView {...menuProps} />;
};

export default NotificationsMenu;
