import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createContext, useCallback, useContext, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";

function ToastNotification() {
    const { text, show, type, onClose } = useContext(ToastContext);
    const autoHide = type !== "danger";

    const icon: IconName = type === "danger" ? "circle-exclamation" : "circle-check";

    return (
        <ToastContainer position="bottom-start" containerPosition="fixed" className="p-3">
            <Toast autohide={autoHide} delay={5000} show={show} bg={type} onClose={onClose} className={`text-bg-${type} p-1`}>
                <div className="d-flex align-items-center">
                    <Toast.Body className="d-flex align-items-center gap-2">
                        <FontAwesomeIcon icon={["fal", icon]} size="lg" />
                        <span>{text}</span>
                    </Toast.Body>
                    <button type="button" className="btn-close btn-close-white me-2 m-auto" aria-label="Close" onClick={onClose}></button>
                </div>
            </Toast>
        </ToastContainer>
    );
}

export const ToastContext = createContext<ToastCtx>(undefined!);

export const useToastModel = () => {
    const [showToast, setShowToast] = useState(false);
    const [toastText, setToastText] = useState("");
    const [toastType, setToastType] = useState<Variant>("success");

    const success = useCallback((text: string) => {
        setToastType("success");
        setToastText(text);
        setShowToast(true);
    }, []);

    const error = useCallback((text: string) => {
        setToastType("danger");
        setToastText(text);
        setShowToast(true);
    }, []);

    const onClose = useCallback(() => {
        setShowToast(false);
    }, []);

    return {
        text: toastText,
        show: showToast,
        type: toastType,
        error,
        success,
        onClose,
    };
};

export const useToast = () => {
    const { error, success } = useContext(ToastContext);

    return {
        error,
        success,
    };
};

type ToastCtx = {
    text?: string;
    show?: boolean;
    type?: Variant;
    onClose: () => void;
    success: (text: string) => void;
    error: (text: string) => void;
};

export default ToastNotification;
