import Form from "@rjsf/core";
import { HiddenSubmitButton, JsonForm } from "components/JsonForm";
import { useEquipmentValidationTypes } from "components/utils/useEquipmentValidationTypes";
import { useTrueFalseTypes } from "components/utils/useTrueFalseTypes";
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { EquipmentDetailsFromCatalog } from "./types";
import { getInitialValues, getSchema, getUiSchema } from "./utils";

export const Equipment = ({ formRef, item, isNewEquipment = false, readonly = false, liveValidate }: EquipmentProps) => {
    const [validationTypes = [], isLoadingValidationTypes] = useEquipmentValidationTypes();
    const [trueFalseTypes = [], isLoadingTrueFalseTypes] = useTrueFalseTypes();
    const [formData, setFormData] = useState<any>(null);
    const isInitialized = useRef<boolean>(false);

    const isLoading = isLoadingValidationTypes || isLoadingTrueFalseTypes;

    const schema = useMemo(
        () => (isLoading ? undefined : getSchema(item, validationTypes, trueFalseTypes)),
        [isLoading, item, validationTypes, trueFalseTypes]
    );

    const uiSchema = useMemo(() => (isLoading ? undefined : getUiSchema(item, validationTypes)), [isLoading, item, validationTypes]);

    useEffect(() => {
        if (!isLoading && item && !isInitialized.current) {
            isInitialized.current = true;
            setFormData((prevFormData: any) => getInitialValues(item, validationTypes, prevFormData, schema, isNewEquipment));
        }
    }, [isLoading, isNewEquipment, item, schema, validationTypes]);

    const formContext = useMemo(
        () => ({
            localizeDateValues: true,
        }),
        []
    );

    const onChange = useCallback(({ formData }: any) => {
        setFormData(formData);
    }, []);

    if (isLoading) {
        return (
            <Spinner className="align-self-center flex-shrink-0" animation="border" role="status">
                <span className="visually-hidden">Loading equipment attributes...</span>
            </Spinner>
        );
    }

    return (
        <JsonForm
            formRef={formRef}
            formContext={formContext}
            schema={schema}
            uiSchema={uiSchema}
            formData={formData}
            readOnly={readonly}
            onChange={onChange}
            liveValidate={liveValidate}
        >
            <HiddenSubmitButton />
        </JsonForm>
    );
};
interface EquipmentProps {
    formRef: MutableRefObject<Form<any> | undefined>;
    item: EquipmentDetailsFromCatalog;
    isNewEquipment?: boolean;
    readonly?: boolean;
    liveValidate?: boolean;
}
