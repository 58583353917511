import { ContractorsResponseItemData, ContractorsResponseItem } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { isInIframe } from "components/utils/dom";
import { getUrl, httpGetAuthorized } from "components/utils/http";
import { isEmpty, isNumber, isString, toNumber } from "lodash";
import useSWR from "swr";
import { mockedContractorsResponse, mockedProgramContractorSearchParamsResponse } from "./mockedData";
import { ContractorSearchParams } from "./types";

export const useProgramContractorSearchParams = (
    programNumber?: string
): [contractors: ContractorSearchParams | undefined, isLoading: boolean, error: any] => {
    const url =
        !isInIframe() && programNumber ? getUrl(process.env.REACT_APP_PROGRAM_CONTRACTORS_SEARCH_PARAMS_ENDPOINT, { programNumber }) : null;

    const { data, error } = useSWR(url, httpGetAuthorized);

    const isLoading = !!programNumber && !error && !data;

    if (isInIframe()) {
        return [mockedProgramContractorSearchParamsResponse, false, undefined];
    }

    return [data, isLoading, error];
};

export async function getProgramContractors({
    programNumber,
    pageNumber,
    pageSize,
    searchAttr,
    userCoordinates,
}: GetProgramContractorsProps) {
    if (isInIframe()) {
        return mockedContractorsResponse;
    }

    const baseUrl = getUrl(process.env.REACT_APP_PROGRAM_CONTRACTORS_ENDPOINTV2, { programNumber });
    const query = new URLSearchParams();
    query.append("recsPerPage", String(pageSize));
    query.append("pageNum", String(pageNumber));
    if (userCoordinates) {
        query.append("latitude", String(userCoordinates.latitude));
        query.append("longitude", String(userCoordinates.longitude));
        query.append("sort", "distance ASC");
    }
    if (!isEmpty(searchAttr)) {
        query.append("searchAttr", searchAttr);
    }

    const url = programNumber ? baseUrl + "?" + query.toString() : null;

    try {
        const data = await httpGetAuthorized(url || "");
        const contractors = data.items.map((c: ContractorsResponseItemData) => transformCoordinates(c));
        return { ...data, items: contractors };
    } catch (error) {
        throw error;
    }
}

const transformCoordinates = (contractor: ContractorsResponseItemData): ContractorsResponseItem => {
    const { lat, lng } = contractor;
    if (isString(lat) && isString(lng) && !isEmpty(lat) && !isEmpty(lng)) {
        return { ...contractor, lat: toNumber(lat), lng: toNumber(lng) };
    } else if (isNumber(contractor.lat) && isNumber(contractor.lng)) {
        return { ...contractor, lat: contractor.lat as number, lng: contractor.lng as number };
    } else {
        return { ...contractor, lat: null, lng: null };
    }
};

interface GetProgramContractorsProps {
    programNumber: string;
    pageNumber: number;
    pageSize: number;
    searchAttr: string;
    sort?: string;
    userCoordinates?: { latitude: number; longitude: number } | null;
}
