import { SubmittedAppData } from "types/SubmittedAppData";
import { MessagesList } from "./MessagesList";
import { MessageInput } from "./MessageInput";
import { EmptyStatePlaceholder } from "../EmptyStatePlaceholder";
import { ReactComponent as MessagesEmptyStatePlaceholder } from "../assets/messages-empty-state.svg";

export const MessagesSection = ({
    applicationNumber,
    appData,
    isMobile,
    onChanged,
}: {
    applicationNumber: string;
    appData: SubmittedAppData;
    isMobile?: boolean;
    onChanged?: () => void;
}) => {
    const messages = appData.applicationNotes ?? [];
    const showEmptyState = messages.length === 0;

    return (
        <>
            {showEmptyState ? (
                <div className="submitted-app-messages-list d-flex align-items-center h-100">
                    <EmptyStatePlaceholder
                        className="pb-5"
                        placeholderImage={<MessagesEmptyStatePlaceholder />}
                        title="No messages yet"
                        description="To start a conversation write a message on the field below"
                    />
                </div>
            ) : (
                <MessagesList messages={messages} isMobile={isMobile} />
            )}
            <MessageInput applicationNumber={applicationNumber} isMobile={isMobile} onChanged={onChanged} />
        </>
    );
};
