import { Icon } from "components/Icon";
import { useState } from "react";
import { Badge, ButtonGroup, Dropdown, Nav, Navbar, CloseButton } from "react-bootstrap";
import { Button } from "components/Button";
import { NotificationsMenuProps, NotificationsTab } from "./utils";
import { TaskList } from "./TaskList";
import { MessageList } from "./MessageList";
import cn from "classnames";

export const DesktopView = ({ className, notificationCount, tasks, notes, navTabs, onNotificationClick }: NotificationsMenuProps) => {
    const [activeTab, setActiveTab] = useState(NotificationsTab.Tasks);
    const [showMenu, setShowMenu] = useState(false);
    const toggleShow = () => setShowMenu((prev) => !prev);

    return (
        <Dropdown
            className={cn(className, "notifications-dropdown mt-2 mb-auto")}
            autoClose="outside"
            align="end"
            show={showMenu}
            onToggle={(isOpen) => setShowMenu(isOpen)}
        >
            <Dropdown.Toggle as={ButtonGroup} className="notifications-toggle position-relative mx-auto">
                <Button
                    variant="outline-secondary"
                    aria-label={notificationCount > 0 ? `${notificationCount} unread notifications` : "notifications"}
                    aria-haspopup="dialog"
                    data-metadata={JSON.stringify({ name: "Notifications" })}
                >
                    <Icon icon={["fal", "bell"]} size={"lg"} />
                </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu className="notifications-menu mt-2 dropdown-menu-lg-start p-0 bg-white">
                <div className="d-flex p-3 justify-content-between align-items-center">
                    <h5 className="m-0 fw-semi-bold">Notifications</h5>
                    <CloseButton type="button" className="btn-close" aria-label="Close Notifications" onClick={toggleShow}></CloseButton>
                </div>
                <Dropdown.Divider className="m-0" />
                <div className="p-3">
                    <Navbar className="p-0">
                        <Nav variant="tabs" className="border-bottom-0" activeKey={activeTab} onSelect={(k: any) => setActiveTab(k)}>
                            {navTabs.map((t) => {
                                return (
                                    <Nav.Item key={t.key}>
                                        <Nav.Link
                                            eventKey={t.key}
                                            id={`header-nav-item-${t.key}`}
                                            aria-current={t.key === activeTab}
                                            className={cn("px-3 text-body", { "active-tab": activeTab === t.key })}
                                            data-metadata={JSON.stringify({ name: t.text })}
                                        >
                                            <div className="me-1 d-flex flex-row align-items-center gap-2 lh-lg">
                                                <Icon icon={t.icon}></Icon>
                                                <p className="m-0">{t.text}</p>
                                                {!!t.counter && <Badge className="rounded-5 bg-danger text-white">{t.counter}</Badge>}
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                );
                            })}
                        </Nav>
                    </Navbar>
                    <Dropdown.Divider className="m-0 pb-3" />
                    {activeTab === NotificationsTab.Tasks && <TaskList tasks={tasks} onNotificationClick={onNotificationClick} />}
                    {activeTab === NotificationsTab.Inbox && <MessageList notes={notes} onNotificationClick={onNotificationClick} />}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};
