import cn from "classnames";
import { formatJsonDate, formatJsonDateTime } from "components/utils/date";
import { get, isEmpty, isNil, isString, omitBy } from "lodash";
import { DataGridCellValue, DataGridColumnConfig, DataGridConfig, DataGridFilter, DataGridRowConfig } from "./types";

export const getVisibleColumns = (config: DataGridConfig | undefined) => {
    return (config?.columns ?? []).filter(
        (c) => c.active === "true" && (isNil(c.hidecolumn) || c.hidecolumn === "false")
    ) as DataGridColumnConfig[];
};

export const getFilterableColumns = (config: DataGridConfig | undefined) => {
    return getVisibleColumns(config).filter((c) => c.filter === "true");
};

export const mapGridRowToObject = (gridColumnKeys: any, item: any) => {
    return Object.keys(gridColumnKeys).reduce((row, key) => {
        if (item[gridColumnKeys[key]] === undefined) {
            return row;
        }

        return {
            ...row,
            [key]: item[gridColumnKeys[key]],
        };
    }, {});
};

export const getGroupedRows = ({ rowGroups, rows }: { rowGroups: Array<{ key: string; defaultGroups?: any }>; rows: any }) => {
    let groupedRows = [];

    if (rowGroups.length) {
        groupedRows = rowGroups.map((item) => {
            let defaultGroups = (item.defaultGroups || []).reduce((result: any, next: any) => {
                return {
                    ...result,
                    [next]: [],
                };
            }, []);

            return rows.reduce((result: any, next: any) => {
                const groupName = next[item.key] || "";

                // Create group
                if (!result[groupName]) {
                    result[groupName] = [];
                }

                result[groupName].push(next);

                return result;
            }, defaultGroups);
        })[0];
    }
    return groupedRows;
};

export const getColumnFilterInputType = (column: DataGridColumnConfig) => {
    let result;

    switch (column.datatype) {
        case "date":
        case "datetime":
            result = "date";
            break;
        default:
            result = "text";
            break;
    }

    if (!isNil(column.selectValues)) {
        result = "select";
    }

    return result;
};

export const formatCellContent = (column: DataGridColumnConfig, value: DataGridCellValue, row: DataGridRowConfig) => {
    let result;

    if (column.renderer) {
        return column.renderer(column, value, row);
    }

    if (isNil(value)) {
        return "-";
    }

    switch (column.datatype) {
        case "date":
            result = formatJsonDate(String(value));
            break;

        case "datetime":
            result = formatJsonDateTime(String(value));
            break;
        case "html":
            result = <div dangerouslySetInnerHTML={{ __html: String(value) }} />;
            break;

        case "email":
            result = isString(value) && value.length > 0 && (
                <a className="email-link" href={`mailto:${value}`} title={value}>
                    {value}
                </a>
            );
            break;

        default:
            if (value === false) {
                result = "False";
            } else if (value === true) {
                result = "True";
            } else if (value.indexOf?.("\n") > -1) {
                result = breakTextIntoLines(value);
            } else {
                result = value;
            }

            break;
    }

    return result;
};

export const breakTextIntoLines = (value: string) => {
    if (isString(value)) {
        const split = value.split("\n");
        return split.map((str, index) => (
            <div className={cn("text-break", { "d-block": index > 0 })} key={index}>
                {str.trim()}
            </div>
        ));
    }
    return value;
};

export const formatFilterChipValue = (datatype: string | undefined, value: string | boolean | undefined) => {
    let result;

    if (isNil(value)) {
        return "";
    }

    switch (datatype) {
        case "date":
        case "datetime":
            result = formatJsonDate(String(value));
            break;

        default:
            if (value === false) {
                result = "False";
            } else if (value === true) {
                result = "True";
            } else if (value.indexOf?.("\n") > -1) {
                const split = value.split("\n");
                result = split.map((str, index) => (
                    <div className={index > 0 ? "d-block" : ""} key={index}>
                        {str.trim()}
                    </div>
                ));
            } else {
                result = value;
            }

            break;
    }

    return result;
};

export const getColumnSortState = (column: DataGridColumnConfig, sortBy: string, sortAsc: boolean) => {
    if (sortBy === column.key) {
        return sortAsc ? "ASC" : "DESC";
    }

    return undefined;
};

export const getPageSize = (config: DataGridConfig, defaultPageSize: number = 5) => {
    let pageSize = defaultPageSize;

    if (get(config, "gridactions[0].paging[0].enabled") === "true") {
        pageSize = parseInt(get(config, "gridactions[0].paging[0].pagesize", 0), 10);

        if (pageSize === 0) {
            pageSize = defaultPageSize;
        }
    } else {
        pageSize = 10000;
    }

    return pageSize;
};

export const getTableColumnCount = (columns: DataGridColumnConfig[], hasActionsColumn: boolean) => {
    return (
        columns.filter((c) => c.active === "true" && (isNil(c.hidecolumn) || c.hidecolumn === "false")).length + (hasActionsColumn ? 1 : 0)
    );
};

export const isFilterEmpty = (filter: DataGridFilter, notClearableFilterKeys: string[] | undefined) =>
    isEmpty(omitBy(filter, (value, key) => isEmpty(value) || (notClearableFilterKeys ?? []).includes(key)));

export const isFilterChanged = (filter: DataGridFilter, notClearableFilterKeys: string[] | undefined) =>
    !isEmpty(omitBy(filter, (_, key) => (notClearableFilterKeys ?? []).includes(key)));

export const getAriaSortAttribute = (column: DataGridColumnConfig) => {
    if (!isEmpty(column.sort)) {
        return column.sort === "ASC" ? "ascending" : "descending";
    }

    return undefined;
};
