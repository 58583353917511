export const formatPhoneNumber = (phoneNumber: string | undefined) => {
    const cleaned = phoneNumber?.replace(/\D/g, "");

    //format 10 digit US/Canada numbers to (123) 456-7890
    if (phoneNumber?.length === 10) {
        const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
    }

    //format 11 digit US/Canada numbers to +1 (123) 456-7890
    if (phoneNumber?.length === 11) {
        const match = cleaned?.match(/^1?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
        }
    }

    return phoneNumber;
};

// https://stackoverflow.com/a/18650828/7841823
export const formatBytes = (bytes: number, decimals = 2, accessible = false) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const accessibleSizes = ["Bytes", "Kilobytes", "Megabyts", "Gigabytes", "Terabytes", "Petabyte", "Exabyte", "Zettabyte", "Yottabyte"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${accessible ? accessibleSizes[i] : sizes[i]}`;
};
