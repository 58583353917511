import { Components } from "components";
import useWidgetStyles from "components/utils/useWidgetStyles";
import { FooterProps } from "types/FooterProps";

export function Footer(props: FooterProps) {
    const { components = [], isEnabled } = props;
    const style = useWidgetStyles(props);

    if (!isEnabled) {
        return null;
    }

    return (
        <footer className="page-footer" style={style}>
            {components.map((component, index) => {
                const Component = Components[component.type];
                return Component ? <Component key={index} index={index} {...component.props} /> : null;
            })}
        </footer>
    );
}
