import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR, { mutate } from "swr";
import { ContactInboundData } from "./types";

export const useApplicationContacts = (
    applicationNumber: string
): [contacts: ContactInboundData[] | undefined, isLoading: boolean, error: any] => {
    const url = applicationNumber ? getUrl(process.env.REACT_APP_APPLICATION_CONTACTS_ENDPOINT, { applicationNumber }) : null;
    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data;

    return [data, isLoading, error];
};

export const clearApplicationContactsCache = (applicationNumber: string) => {
    const url = applicationNumber ? getUrl(process.env.REACT_APP_APPLICATION_CONTACTS_ENDPOINT, { applicationNumber }) : null;
    mutate(url, undefined, false);
};
