import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR from "swr";
import { ApprovedEquipmentType } from "./types";
import { isInIframe } from "components/utils/dom";

export const useApprovedEquipmentCategories = (
    applicationNumber?: string,
    catalogNumber?: string
): [approvedEquipmentCategories: ApprovedEquipmentType[], isLoading: boolean, error: any] => {
    const baseUrl =
        !isInIframe() && applicationNumber ? getUrl(process.env.REACT_APP_APPROVED_EQUIPMENT_ENDPOINT, { applicationNumber }) : null;

    const query = new URLSearchParams();
    if (catalogNumber) {
        query.append("catalogNumber", catalogNumber);
    }

    const queryString = query.toString();
    const url = baseUrl ? baseUrl + (queryString.length > 0 ? "?" + queryString : "") : null;

    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data && url !== null;

    if (isInIframe()) {
        return [[], false, null];
    }

    return [data?.approvedMeasureCategoryList, isLoading, error];
};
