import { useCallback, useContext, useState, useEffect } from "react";
import { JsonForm } from "components/JsonForm";
import { userChangeEmail, verifyAuthCode } from "components/utils";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "components/App/AppContext";
import { Heading } from "components/Heading";
import { SuccessPage } from "./SuccessPage";
import { Container } from "components/Layout/Container";
import { PageLink } from "components/App/utils";

import { isEmpty } from "lodash";
import { getCustomerNumber } from "components/utils/user";

export function ChangeEmailPage(props) {
    const history = useHistory();

    const [validated, setValidated] = useState(false);
    const submitted = history?.location?.state?.submitted === true;
    const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
    const [extraErrors, setExtraErrors] = useState({});
    const { programNumber } = useContext(AppContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [customerId, setCustomerId] = useState("");
    const customerNumber = getCustomerNumber();
    const location = useLocation();
    const schema = {
        type: "object",
        required: ["email", "repeatedEmail", "password"],
        properties: {
            password: {
                type: "string",
                title: "Enter Password",
                format: "password",
            },
            email: {
                type: "string",
                title: "New Email",
                format: "email",
            },
            repeatedEmail: {
                type: "string",
                title: "Repeat New Email",
                format: "email",
            },
        },
    };

    const uiSchema = {
        password: {
            "ui:widget": "password",
            "ui:placeholder": "Enter Password",
        },
        email: {
            "ui:widget": "text",
            "ui:placeholder": "Enter Email Address",
        },
        repeatedEmail: {
            "ui:widget": "text",
            "ui:placeholder": "Re-Enter Email Address",
        },
    };

    const authenticationSchema = {
        type: "object",
        required: ["authCode"],
        properties: {
            authCode: {
                type: "string",
                title: "Authorization Code:",
            },
        },
    };

    useEffect(() => {
        if (!isEmpty(location?.state?.referrer)) {
            const pathParts = location.state.referrer.split("/");
            if (!isEmpty(pathParts[2])) {
                setValidated(true);
                setCustomerId(pathParts[2]);
            }
        }
    }, [location]);

    const authenticationUiSchema = {
        authCode: {
            "ui:placeholder": "Enter your authorization code",
        },
    };

    function validateEmail(formData, errors) {
        if (formData.email !== formData.repeatedEmail) {
            errors.repeatedEmail.addError("Email addresses don't match");
        }
        return errors;
    }

    const onSubmitAuthentication = useCallback(
        async (data) => {
            setIsSubmitting(true);
            const { formData } = data;
            verifyAuthCode({
                id: customerNumber ?? customerId,
                authCode: formData.authCode,
            })
                .then(() => {
                    let redirectToApp = location?.state?.referrer;

                    if (redirectToApp?.startsWith(PageLink.CustomerAuthCode)) {
                        // If the link in the create account email was opened, need to build the redirect url using the pagenumber query param
                        const urlParams = new URLSearchParams(`?${location?.state?.referrer?.split("?")[1]}`);
                        const pageNumber = urlParams.get("pagenumber");
                        redirectToApp = pageNumber ? `${PageLink.Application}?pageNumber=${pageNumber}` : null;
                    }
                    if (redirectToApp) {
                        setHasBeenSubmitted(true);
                        history.replace({
                            pathName: PageLink.ChangeEmail,
                            state: { submitted: true, validated: false, hasBeenSubmitted: true },
                        });
                    } else {
                        history.replace({
                            pathName: PageLink.ChangeEmail,
                            state: { submitted: true, validated: false, hasBeenSubmitted: true },
                        });
                    }
                })
                .catch(() =>
                    setExtraErrors({
                        authCode: {
                            __errors: ["The authorization code you entered is invalid"],
                        },
                    })
                )
                .finally(() => {
                    setIsSubmitting(false);
                });
        },
        [customerId, customerNumber, history, location?.state?.referrer]
    );

    const onSubmit = useCallback(
        (data) => {
            setIsSubmitting(true);
            const { formData } = data;
            userChangeEmail({
                email: formData.email,
                password: formData.password,
                programNumber,
            })
                .then(() => {
                    setValidated(true);
                    setExtraErrors({});
                })
                .catch((error) => {
                    if (error.responseMessage === "Error completing auth code request.  Password is incorrect.") {
                        setExtraErrors({
                            password: {
                                __errors: [error.responseMessage],
                            },
                        });
                    } else if (
                        error.responseMessage ===
                        "Error completing auth code request.  This email address is already used by another account."
                    ) {
                        setExtraErrors({
                            email: {
                                __errors: [error.responseMessage],
                            },
                        });
                    } else {
                        setExtraErrors({
                            email: {
                                __errors: [error.responseMessage],
                            },
                        });
                    }
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        },
        [programNumber]
    );

    if (validated) {
        return (
            <>
                {submitted || hasBeenSubmitted ? (
                    <SuccessPage text="E-mail changed successfully!" />
                ) : (
                    <Container className="p-0">
                        <Heading>Enter authorization code sent via new email</Heading>
                        <JsonForm
                            schema={authenticationSchema}
                            uiSchema={authenticationUiSchema}
                            onSubmit={onSubmitAuthentication}
                            extraErrors={extraErrors}
                            isSubmitting={isSubmitting}
                            formName="Enter authorization code"
                        />
                    </Container>
                )}
            </>
        );
    }

    return (
        <Container className="p-0">
            <Heading>Change email</Heading>
            <JsonForm
                schema={schema}
                uiSchema={uiSchema}
                onSubmit={onSubmit}
                validate={validateEmail}
                extraErrors={extraErrors}
                isSubmitting={isSubmitting}
                formName="Change email"
            />
        </Container>
    );
}
