import { WidgetProps } from "@rjsf/core";
import { ContactForm } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/ContactForm";
import { isFunction } from "lodash";
import CheckboxWidget from "../CheckboxWidget";
import { PrimaryContactFormProps } from "./types";
import { ContactType } from "components/utils/contacts";
import { RequireContact } from "../../../../../types/RequireContact";
import { getHiddenFieldsByContactType, getRequiredFieldsByContactType } from "components/utils/contacts";

export const PrimaryContactForm = ({
    formRef,
    formData,
    extraErrors,
    requirements,
    noTitle,
    isAppSubmitted,
    onSaveClick,
    onCopyContact,
    onUseCustomerContact,
    onChange,
    readonly,
}: PrimaryContactFormProps) => {
    const requiredFields = getRequiredFieldsByContactType(requirements.requiredFields, ContactType.Primary);
    const hiddenFields = getHiddenFieldsByContactType(requirements.hiddenFields, ContactType.Primary);
    const showCopyContact = isFunction(onCopyContact);

    const hideContact = requirements.requirePrimaryContact === RequireContact.Hidden;
    if (hideContact && !isAppSubmitted) {
        return null;
    }
    const copyContactUiSchema = showCopyContact
        ? {
              copyContact: {
                  "ui:widget": (props: WidgetProps) => <CheckboxWidget {...props} onChange={() => onCopyContact && onCopyContact()} />,
              },
          }
        : undefined;

    return (
        <ContactForm
            idPrefix="primary-contact"
            title={noTitle ? "" : requirements.primaryDescription}
            description={requirements.primaryDescription ? "" : "Who is the person we should contact regarding this application?"}
            formRef={formRef}
            formData={formData}
            requiredFields={requiredFields}
            hiddenFields={hiddenFields}
            extraErrors={extraErrors}
            copyContactUiSchema={copyContactUiSchema}
            onUseCustomerContact={onUseCustomerContact}
            onSaveClick={onSaveClick}
            readonly={readonly}
            onChange={onChange}
        />
    );
};
