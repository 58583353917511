import { EquipmentAdd } from "components/EquipmentBlock/EquipmentAdd";
import { EquipmentAddSubmitItem } from "types/EquipmentAddSubmitItem";
import { EquipmentBlockContextValue } from "types/EquipmentBlockContextValue";
import { refreshEquipmentList } from "components/EquipmentBlock/useEquipmentList";
import { createEquipment } from "components/EquipmentBlock/utils";
import { EquipmentBlockContext } from "components/utils/contexts";
import { useCallback, useMemo } from "react";
import { TaskProps } from "./types";
import { completeWorkflowTask } from "./utils";

export const NewMeasureTask = ({ applicationNumber, task, onComplete, onClose }: TaskProps) => {
    const onEquipmentAddSubmit = useCallback(
        async (equipmentItem: EquipmentAddSubmitItem) => {
            const taskNumber = task.wfTaskNumber;
            await createEquipment(applicationNumber, equipmentItem, taskNumber);
            await completeWorkflowTask(applicationNumber, task);
            refreshEquipmentList(applicationNumber);
            onComplete(task);
        },
        [applicationNumber, onComplete, task]
    );

    const contextValue: EquipmentBlockContextValue = useMemo(
        () => ({
            applicationNumber,
            allowEquipmentEntry: true,
            onEquipmentAddSubmit,
            onEquipmentAddClose: () => onClose(),
            onEquipmentAdded: () => completeWorkflowTask(applicationNumber, task).then(() => onComplete(task)),
        }),
        [applicationNumber, onClose, onComplete, onEquipmentAddSubmit, task]
    );

    return (
        <EquipmentBlockContext.Provider value={contextValue}>
            <EquipmentAdd />
        </EquipmentBlockContext.Provider>
    );
};
