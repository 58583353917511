import { cloneDeep, isEqual, isNil } from "lodash";
import execute from "./actions";
import { initRulesRunnerRules } from "./utils";

// Init validation rules
initRulesRunnerRules();

function doRunRules(engine, formData, schema, uiSchema, extraActions = {}) {
    let schemaCopy = cloneDeep(schema);
    let uiSchemaCopy = cloneDeep(uiSchema);
    let formDataCopy = cloneDeep(formData);

    let res = engine.run(formData).then((events) => {
        events.forEach((event) => execute(event, schemaCopy, uiSchemaCopy, formDataCopy, extraActions));
    });

    return res.then(() => {
        return {
            schema: schemaCopy,
            uiSchema: uiSchemaCopy,
            formData: formDataCopy,
        };
    });
}

function rulesRunner(schema, uiSchema, rules, engine, extraActions) {
    engine = typeof engine === "function" ? new engine([], schema) : engine;
    rules
        .sort(function (a, b) {
            if (a.order === undefined) {
                return b.order === undefined ? 0 : 1;
            }
            return b.order === undefined ? -1 : a.order - b.order;
        })
        .forEach((rule) => engine.addRule(rule));

    return (formData) => {
        if (isNil(formData)) {
            return Promise.resolve({ schema, uiSchema, formData });
        }

        return doRunRules(engine, formData, schema, uiSchema, extraActions).then((conf) => {
            if (isEqual(conf.formData, formData)) {
                return conf;
            } else {
                return doRunRules(engine, conf.formData, schema, uiSchema, extraActions);
            }
        });
    };
}

export default rulesRunner;
