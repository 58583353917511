import { DataGrid } from "components/DataGrid";
import { DataGridColumnConfig, DataGridRowConfig } from "components/DataGrid/types";
import { ApprovedEquipmentSearchResult } from "./useApprovedEquipmentSearch";
import { DataGridHead } from "components/DataGrid/DataGridHead";
import { DataGridHeader } from "components/DataGrid/DataGridHeader";
import { DataGridFilter } from "components/DataGrid/DataGridFilter";
import { DataGridBody } from "components/DataGrid/DataGridBody";
import { getColumnSortState, getVisibleColumns } from "components/DataGrid/utils";
import { Button } from "components/Button";
import { DataGridFilterChips } from "components/DataGrid/DataGridFilterChips";

export const ApprovedEquipmentGrid = ({
    items,
    gridConfig,
    onSelect,
    sortBy,
    sortAsc,
    onSortChange,
    onFilterChange,
    filter,
    isLoadingGrid,
    setInitialItemsFound,
}: ApprovedEquipmentGridProps) => {
    const columns = getVisibleColumns(gridConfig);
    const rows: DataGridRowConfig[] = (items ?? []).map((item) => ({
        data: item,
        actions: [
            {
                content: () => (
                    <Button variant="outline-dark" size="sm" onClick={() => onSelect(item)}>
                        Select
                    </Button>
                ),
            },
        ],
    }));

    if (!isLoadingGrid) {
        setInitialItemsFound(true);
    }

    const formattedColumns: DataGridColumnConfig[] = columns.map((col) => ({
        ...col,
        sort: getColumnSortState(col, sortBy, sortAsc),
        name: col.name.split("_").join(" "),
    }));

    return (
        <>
            <DataGridFilterChips
                className="d-lg-none m-3"
                gridConfig={gridConfig}
                filter={filter}
                formTitle="Filter Equipment"
                onFilterChange={onFilterChange}
            />
            <DataGrid className="approved-equipment-list data-grid-in-modal align-middle" withWrapper={false}>
                <DataGridHead className="position-sticky sticky-top">
                    <DataGridHeader columns={formattedColumns} onSortChange={onSortChange} stickyActionsColumn />
                    <DataGridFilter columns={columns} filter={filter} onFilterChange={onFilterChange} stickyActionsColumn />
                </DataGridHead>
                <DataGridBody
                    rows={rows}
                    columns={formattedColumns}
                    isLoadingData={isLoadingGrid}
                    nothingFoundMessage="No Results Found."
                    stickyActionsColumn
                />
            </DataGrid>
        </>
    );
};

interface ApprovedEquipmentGridProps {
    gridConfig: any;
    items: ApprovedEquipmentSearchResult[];
    onSelect: (item: ApprovedEquipmentSearchResult) => void;
    sortBy: string;
    sortAsc: boolean;
    onSortChange: (key: string) => void;
    onFilterChange: (filter: string) => void;
    filter?: string;
    isLoadingGrid: boolean;
    setInitialItemsFound: React.Dispatch<React.SetStateAction<boolean>>;
}
