export enum WorkflowFilterStepType {
    Files = "Files",
    Tasks = "Tasks",
    FormFields = "Formfields",
    Forms = "Forms",
    Equipment = "Equipment",
}

export enum WorkflowTaskStepType {
    DocumentUpload = "Document Upload",
    ApplicationFormEntry = "Application Form Entry Task",
    ApplicationFormFieldEntry = "Application Form Field Entry Task",
    ApplicationFormSectionEntry = "Application Form Section Entry Task",
    CreateNewMeasure = "Create New Measure",
    PerformTask = "Perform Task",
}
export interface WorkFlowTaskModel {
    wfTaskNumber: string;
    state: string;
    stepNumber: string;
    stepType: string;
    step: string;
    term: number;
    customStep: string;
    dateCreated: string;
    dateCompleted: string | null;
    dateRemoved: string | null;
    dateClosed: string | null;
    dateDue?: string;
    taskGroup: string;
    userOwner: string | null;
    userCompleted: string | null;
    hideFlag: boolean;
    allowAccess: boolean;
    isAutomatedTask: boolean;
    processMode: string | null;
    sectionNumber?: string | null;
    statusSortOrder: number;
    stepSortOrder: number;
    formPageNumber: string | null;
    fieldNumber: string | null;
    content?: string;
}

export interface TaskProps {
    applicationNumber: string;
    task: WorkFlowTaskModel;
    onComplete: (task: WorkFlowTaskModel) => void;
    onClose: () => void;
    isTaskCompleted?: boolean;
}
