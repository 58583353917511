export const COOKIE_COOKIES_PREFERENCES_SET = "cookies_preferences_set";
export const COOKIE_OPT_IN = "opt_in";

export function getCookie(name) {
    for (var t = name + "=", n = document.cookie.split(";"), i = 0, a = n.length; i < a; i++) {
        for (var o = n[i]; " " === o.charAt(0); ) o = o.substring(1, o.length);

        if (0 === o.indexOf(t)) return decodeURIComponent(o.substring(t.length));
    }

    return null;
}

export function getCookiesPolicyCookie() {
    return getCookie(COOKIE_OPT_IN) === "true";
}

export function setCookie(name, value, expires) {
    void 0 === expires && (expires = {});

    var cookie = name + "=" + value + "; path=/";

    if (expires.days) {
        var a = new Date();
        a.setTime(a.getTime() + 24 * expires.days * 60 * 60 * 1e3);
        cookie = cookie + "; expires=" + a.toGMTString();
    }

    "https:" === document.location.protocol && (cookie += "; Secure");

    cookie += "; SameSite=Lax";

    document.cookie = cookie;
}

export function deleteCookie(name, path, domain) {
    if (getCookie(name)) {
        document.cookie =
            name + "=" + (path ? ";path=" + path : "") + (domain ? ";domain=" + domain : "") + ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

export function setCookiesPolicyCookie(isUsageAllowed) {
    if (isUsageAllowed) {
        setCookie(COOKIE_OPT_IN, true, { days: 365 });
    } else {
        deleteCookie(COOKIE_OPT_IN);
    }
}

export function setCookiesPreferencesSetCookie() {
    setCookie(COOKIE_COOKIES_PREFERENCES_SET, "true", { days: 365 });
}
