import cn from "classnames";
import { Components } from "components";
import { AppContext } from "components/App/AppContext";
import { isInIframe } from "components/utils/dom";
import useWidgetClasses from "components/utils/useWidgetClasses";
import useWidgetStyles from "components/utils/useWidgetStyles";
import { omit, pick } from "lodash";
import { createContext, useContext } from "react";
import { Container as BootstrapContainer, Row as BootstrapRow } from "react-bootstrap";

const alignXClasses = {
    left: "justify-content-start",
    center: "justify-content-center",
    right: "justify-content-end",
};

const alignYClasses = {
    top: "align-content-start",
    center: ["align-content-center", "align-items-center"],
    bottom: "align-content-end",
};

const rowContainerProperties = [
    "backgroundColor",
    "backgroundColorOpacity",
    "backgroundOff",
    "firstGradientValue",
    "firstGradientColor",
    "secondGradientValue",
    "secondGradientColor",
    "gradientDirection",
    "colorType",
];
const contentContainerProperties = ["maxWidth"];

export const RowContext = createContext(undefined);

export function Row(props) {
    const { components = [], hidden, _highlight } = props;
    const { settings } = useContext(AppContext);
    const isHighlighted = _highlight && isInIframe();
    const rowContainerStyles = useWidgetStyles({
        ...pick(props, rowContainerProperties),
    });

    let rowContainerClassName = useWidgetClasses({
        ...pick(props, ["showOnDesktop", "showOnTablet", "showOnMobile"]),
        includeVisibilityClasses: !hidden,
    });

    const containerMaxWidth = props?.maxWidth || settings?.maxWidth;
    const contentContainerStyle = {
        maxWidth: containerMaxWidth === "100%" ? undefined : containerMaxWidth,
    };

    const rowStyle = useWidgetStyles(omit(props, [...rowContainerProperties, ...contentContainerProperties]));

    let className = useWidgetClasses({
        ...props,
        alignXClasses,
        alignYClasses,
    });

    return (
        <RowContext.Provider value={props}>
            <div
                className={cn("row-container", rowContainerClassName, { "position-relative": isHighlighted })}
                style={rowContainerStyles}
                hidden={hidden}
            >
                {isHighlighted && <div className="position-absolute top-0 bottom-0 w-100 h-100 bg-primary opacity-25" />}
                <BootstrapContainer fluid style={contentContainerStyle}>
                    <BootstrapRow className={cn("layout-row", className)} style={rowStyle}>
                        {components.map((component, index) => {
                            const Component = Components[component.type];
                            return Component ? <Component key={index} {...component.props} /> : null;
                        })}
                    </BootstrapRow>
                </BootstrapContainer>
            </div>
        </RowContext.Provider>
    );
}
