import { AppContext } from "components/App/AppContext";
import { HeaderNavigation } from "types/HeaderNavigation";
import { isUserAuthenticated } from "components/utils";
import { isNil, sortBy } from "lodash";
import { useContext } from "react";

export const useNavigationPages = () => {
    const { content } = useContext(AppContext);
    const pages = content?.pages ?? [];

    const isAuthenticated = isUserAuthenticated();

    return sortBy(
        pages.filter((p) => {
            if (isNil(p.headerNavigation) || p.headerNavigation === HeaderNavigation.NEVER) {
                return false;
            }

            if (p.headerNavigation === HeaderNavigation.ANONYMOUS && isAuthenticated) {
                return false;
            }

            if (p.headerNavigation === HeaderNavigation.AUTHENTICATED && !isAuthenticated) {
                return false;
            }

            return true;
        }),
        "order"
    );
};
