import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { useToast } from "components/Toast";
import { submitNote } from "components/utils";
import { getErrorMessage } from "components/utils/http";
import { isNullOrWhitespace } from "components/utils/validation";
import cn from "classnames";

export const MessageInput = ({
    applicationNumber,
    isMobile,
    onChanged,
}: {
    applicationNumber: string;
    isMobile?: boolean;
    onChanged?: Function;
}) => {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (isSubmitting) {
            return;
        }

        try {
            const form = e.currentTarget;
            const note = form.note.value;

            if (isNullOrWhitespace(note)) {
                setIsError(true);
                form.note.focus();
                return;
            }

            setIsSubmitting(true);

            const response = await submitNote({ note, noteType: "public", refType: 30 }, applicationNumber);

            form.reset();
            onChanged?.();

            toast.success(response.responseMessage);
        } catch (e) {
            toast.error(getErrorMessage(e));
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div
            className={cn("submitted-app-messages-input d-flex align-self-stretch border-top rounded-bottom", {
                "px-4 py-3": !isMobile,
                "p-2p5 py-3": isMobile,
            })}
        >
            <Form className="d-flex gap-2 w-100 align-self-stretch" noValidate onSubmit={onSubmit}>
                <Form.Control
                    size="lg"
                    type="text"
                    className="flex-grow-1 bg-white fw-light"
                    placeholder="Write your message..."
                    name="note"
                    aria-invalid={isError}
                    aria-label="Message input"
                    aria-describedby={isError ? "message-error" : undefined}
                    autoComplete="off"
                    isInvalid={isError}
                    required
                    onChange={() => setIsError(false)}
                />
                <Form.Control.Feedback type="invalid" id="message-error" className="visually-hidden" aria-live="polite">
                    Message cannot be empty
                </Form.Control.Feedback>
                {isMobile ? (
                    <Button type="submit" variant="primary" className="px-3 py-2" title="Send Message">
                        {isSubmitting ? (
                            <Spinner as="span" animation="border" size="sm" role="status" aria-atomic="true">
                                <span className="visually-hidden">Sending Message</span>
                            </Spinner>
                        ) : (
                            <Icon icon={["fal", "paper-plane"]} />
                        )}
                    </Button>
                ) : (
                    <Button
                        type="submit"
                        variant="primary"
                        className="d-flex align-items-center gap-2 flex-shrink-0 fw-light"
                        title="Send Message"
                        aria-atomic="true"
                        size="lg"
                    >
                        {isSubmitting ? <Spinner as="span" animation="border" size="sm" /> : <Icon icon={["fal", "paper-plane"]} />}
                        <span>{isSubmitting ? "Sending Message" : "Send Message"}</span>
                    </Button>
                )}
            </Form>
        </div>
    );
};
