import { AppContext } from "components/App/AppContext";
import { trim } from "lodash";
import { useContext } from "react";

export const useContractorLabel = () => {
    const { requirements } = useContext(AppContext);
    const contractorLabel = requirements.portalContractorLabel || "Contractor";

    return trim(contractorLabel);
};
