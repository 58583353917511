import { useEffect } from "react";
import { getCustomerNumber } from "./user";
import { EventProps } from "types/EventProps";

export const AnalyticsEventType = {
    BUTTON_CLICK: "Button: Click",
    FORM_OPEN: "Form: Open",
    FORM_SUBMIT: "Form: Submit",
    FORM_ERROR: "Form: Error",
    PAGE_404: "404",
    NOTIFICATION_CLOSE: "Notification: Close",
    APPLICATION_CREATED: "Application: Created",
    APPLICATION_SUBMITTED: "Application: Submitted",
    APPLICATION_SAVED: "Application: Saved",
    APPLICATION_ABANDONED: "Application: Abandoned",
    ACCOUNT_CREATED: "Account: Created",
    WORKFLOW_TASK_COMPLETED: "Workflow: Task Completed",
    CATALOG_NAVIGATE: "Catalog: Navigate",
};

export const sendAnalyticsEvent = (eventName: string, eventProps?: EventProps) => {
    const props: EventProps = {
        ...(eventProps || {}),
        hostname: window.location.hostname,
    };

    const customerNumber = getCustomerNumber();
    if (customerNumber) {
        props["customer"] = customerNumber;
    }

    // For debugging locally
    // if (window.location.hostname === "localhost") {
    //     console.log(`Sending analytics event "${eventName}"`, props);
    // }

    window.plausible?.(eventName, { props });
};

export const useAnalyticsEvent = (eventName: string, eventProps?: EventProps) => {
    useEffect(() => {
        sendAnalyticsEvent(eventName, eventProps);
    }, [eventName, eventProps]);
};

declare global {
    interface Window {
        plausible: (
            eventName: string,
            eventProps?: {
                props: EventProps;
                callback?: () => void;
            }
        ) => void;
    }
}
