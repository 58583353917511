import { Button } from "components/Button";
import { useContext, useRef, useState } from "react";
import { EquipmentListItem } from "types/EquipmentListItem";
import { useEquipmentDetails } from "./useEquipmentDetails";
import { Equipment } from "./Equipment";
import Form from "@rjsf/core";
import { ErrorSummary } from "components/ErrorSummary";
import { submitByRefPromise } from "components/JsonForm/utils";
import { Offcanvas, Spinner, Stack } from "react-bootstrap";
import { omit } from "lodash";
import { SubmitButton } from "components/Button/SubmitButton";
import { EquipmentBlockContext } from "components/utils/contexts";
import { createId } from "components/utils/string";
import { EquipmentAddSubmitItem } from "types/EquipmentAddSubmitItem";

export const EquipmentCopy = ({ item }: { item: EquipmentListItem }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorSummary, setErrorSummary] = useState<any>();
    const formRef = useRef<Form<any>>();
    const { applicationNumber, onEquipmentCopySubmit, onEquipmentCopyClose } = useContext(EquipmentBlockContext);
    const [equipment, isLoading] = useEquipmentDetails(applicationNumber, item.equipid);

    const onSubmit = async () => {
        try {
            // Validate equipment form
            const formData = await submitByRefPromise(formRef);
            const catalogNumber = equipment.catalogNumber;
            const hasApprovedMeasure = equipment.attributes.some((attr) => attr.hasApproveMeasure);
            if (catalogNumber && onEquipmentCopySubmit) {
                const equipmentItem: EquipmentAddSubmitItem = {
                    equipID: createId(),
                    catalogNumber,
                    quantity: formData.quantity,
                    attributes: Object.keys(omit(formData, "quantity")).map((key: string) => ({
                        attributename: key,
                        attributevalue: String(formData[key] ?? ""),
                    })),
                };

                setIsSubmitting(true);
                await onEquipmentCopySubmit(equipmentItem, hasApprovedMeasure);
            }
        } catch (error) {
            setErrorSummary(error);
            setIsSubmitting(false);
        }
    };

    return (
        <Offcanvas
            className="equipment-panel"
            show
            placement="end"
            backdrop="false"
            onHide={onEquipmentCopyClose}
            aria-labelledby="edit-equipment-title"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="edit-equipment-title">Copy Equipment</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column">
                {isLoading ? (
                    <Spinner className="align-self-center" animation="border" role="status">
                        <span className="visually-hidden">Loading equipment...</span>
                    </Spinner>
                ) : (
                    <>
                        <ErrorSummary errorSummary={errorSummary} />
                        {equipment && <Equipment formRef={formRef} item={equipment} />}
                        <Stack className="equipment-controls mt-auto justify-content-end" direction="horizontal">
                            {equipment && (
                                <SubmitButton
                                    onClick={onSubmit}
                                    isSubmitting={isSubmitting}
                                    spinnerText="Copying equipment..."
                                    className="me-3"
                                >
                                    Submit
                                </SubmitButton>
                            )}
                            <Button variant="secondary" onClick={onEquipmentCopyClose} disabled={isSubmitting}>
                                Cancel
                            </Button>
                        </Stack>
                    </>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
};
