import cn from "classnames";
import { AvatarColor } from "./types";
import "./style.scss";

export const Avatar = ({ color, text, badgeSize = "lg" }: AvatarProps) => {
    return (
        <div
            className={cn(`avatar avatar--${color} avatar--${badgeSize} d-flex flex-column flex-shrink-0 align-items-end rounded-circle`, {
                [`fs-8 fw-normal lh-1`]: badgeSize === "sm",
                [`fs-7 fw-normal lh-1`]: badgeSize === "md",
                [`fs-6 fw-medium lh-1`]: badgeSize === "lg",
            })}
            aria-hidden="true"
        >
            <div className="avatar-text d-flex flex-column flex-grow-1 align-self-stretch justify-content-center text-center text-uppercase">
                {text}
            </div>
        </div>
    );
};

interface AvatarProps {
    color: AvatarColor;
    text: string;
    badgeSize?: "sm" | "md" | "lg";
}
