import Form from "@rjsf/core";
import { Button } from "components/Button";
import { SubmitButton } from "components/Button/SubmitButton";
import { ErrorSummary } from "components/ErrorSummary";
import { ApplicationContactsWidgetRef } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { SUBMITTED_APP_FORM_WIDGETS_TO_REMOVE, getFormNameFromConfig, submitByRefPromise } from "components/JsonForm/utils";
import { ApplicationForm } from "components/Page/ApplicationPage/ApplicationForm";
import { getApplicationFormData, validateContactsWidget } from "components/Page/ApplicationPage/utils";
import { useApplicationFormPage } from "components/utils/useApplicationFormPage";
import { useEffect, useMemo, useRef, useState } from "react";
import { Offcanvas, Spinner, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TaskProps, WorkflowTaskStepType } from "components/WorkflowTasks/types";
import { completeWorkflowTask, submitApplicationItems } from "components/WorkflowTasks/utils";
import cn from "classnames";
import useMediaQuery from "components/utils/useMediaQuery";
import { RawHTML } from "components/RawHTML";

export const ApplicationFormEntryTask = ({ applicationNumber, task, onComplete, onClose, isTaskCompleted }: TaskProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorSummary, setErrorSummary] = useState<any>();
    const isMobile = useMediaQuery("(max-width: 768px)");

    const applicationFormRef = useRef<Form<any>>();
    const contactsWidgetRef = useRef<ApplicationContactsWidgetRef>();
    const additionalContactsWidgetRef = useRef<ApplicationContactsWidgetRef>();

    const [applicationForm, isLoadingApplicationForm, applicationFormError] = useApplicationFormPage(
        applicationNumber,
        task.formPageNumber
    );
    const formConfig = applicationForm?.formConfiguration;
    const visibleFieldIds = useMemo(() => {
        if (task.stepType === WorkflowTaskStepType.ApplicationFormSectionEntry) {
            const uiSchema = applicationForm?.formConfiguration?.uiSchema;
            const section = Object.keys(uiSchema ?? {})
                .filter((k) => uiSchema[k]["af:sectionNumber"] === task.sectionNumber)
                .map((k) => uiSchema[k])[0];
            const columns = Object.keys(section ?? {})
                .filter((k) => section?.[k]["af:columnNumber"])
                .map((k) => section?.[k]);
            return columns
                .map((column) =>
                    Object.keys(column ?? {})
                        .filter((k) => column[k]["af:fieldNumber"])
                        .map((k) => column[k]["af:fieldNumber"])
                )
                .flat();
        } else {
            return task.fieldNumber ? [task.fieldNumber] : undefined;
        }
    }, [applicationForm?.formConfiguration?.uiSchema, task.fieldNumber, task.sectionNumber, task.stepType]);
    const formData = useMemo(() => {
        let fields = applicationForm?.formDetails?.fields;

        if (visibleFieldIds && fields?.length > 0) {
            return fields.filter((f: any) => visibleFieldIds.includes(f.number));
        }

        return fields;
    }, [applicationForm?.formDetails?.fields, visibleFieldIds]);

    const formName = getFormNameFromConfig(formConfig);

    useEffect(() => {
        setErrorSummary(applicationFormError);
    }, [applicationFormError]);

    const onSubmit = async () => {
        try {
            if (!applicationFormRef.current || !task.formPageNumber || !formConfig) {
                return;
            }

            setIsSubmitting(true);

            // validate contacts widget
            await validateContactsWidget(contactsWidgetRef, formName);

            // validate additional contacts widget
            await validateContactsWidget(additionalContactsWidgetRef, formName);

            // Validate application form
            await submitByRefPromise(applicationFormRef);

            const { applicationItems } = getApplicationFormData(
                applicationFormRef,
                contactsWidgetRef,
                additionalContactsWidgetRef,
                formConfig,
                formData
            );

            await submitApplicationItems(applicationNumber, task.wfTaskNumber, task.formPageNumber, applicationItems);
            await completeWorkflowTask(applicationNumber, task);

            onComplete(task);
        } catch (error) {
            setErrorSummary(error);
            setIsSubmitting(false);
        }
    };

    return (
        <Offcanvas className={cn({ "offcanvas-75": !isMobile })} show placement="end" onHide={onClose} aria-labelledby="task-title">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fs-4" id="task-title">
                    {isTaskCompleted ? "Done - My completed task" : "My to-do task"}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column">
                {isLoadingApplicationForm ? (
                    <Spinner className="align-self-center flex-shrink-0" animation="border" role="status">
                        <span className="visually-hidden">Loading application form...</span>
                    </Spinner>
                ) : (
                    <>
                        {isTaskCompleted ? (
                            <div className="d-flex flex-column px-2">
                                <p className="d-flex m-0 py-2 fw-medium align-items-center">
                                    <FontAwesomeIcon
                                        aria-label="done"
                                        className="pe-2"
                                        icon={["fal", "circle-check"]}
                                        size="lg"
                                        color="green"
                                    />
                                    Submit form info
                                </p>
                                <div className="d-flex flex-column ps-4">
                                    <p className="m-0 text-break fw-medium ps-2">{task.step}</p>
                                    {task.content && <RawHTML className="m-0 py-2 text-break ps-2">{task.content}</RawHTML>}
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-start">
                                    <div className="d-flex flex-column pb-3">
                                        <p className="m-0 pt-2 pb-3 fw-medium">Submit form info</p>
                                        <p className="m-0 fw-medium">{task.step}</p>
                                        {task.content && <RawHTML className="m-0 pt-2">{task.content}</RawHTML>}
                                    </div>
                                </div>
                            </div>
                        )}
                        <ErrorSummary errorSummary={errorSummary} />
                        <ApplicationForm
                            formRef={applicationFormRef}
                            contactsWidgetRef={contactsWidgetRef}
                            additionalContactsWidgetRef={additionalContactsWidgetRef}
                            applicationNumber={applicationNumber}
                            configuration={formConfig}
                            formData={formData}
                            visibleFieldIds={visibleFieldIds}
                            readonly={isTaskCompleted}
                            isWorkflowTask
                            removeWidgets={SUBMITTED_APP_FORM_WIDGETS_TO_REMOVE}
                        />
                        {!isTaskCompleted && (
                            <Stack className="justify-content-start" direction="horizontal">
                                <SubmitButton onClick={onSubmit} isSubmitting={isSubmitting} spinnerText="Saving form..." className="me-3">
                                    Submit info
                                </SubmitButton>
                                <Button secondaryButtonType="filled" variant="secondary" onClick={onClose} disabled={isSubmitting}>
                                    Cancel
                                </Button>
                            </Stack>
                        )}
                    </>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
};
