import { Button } from "components/Button";
import { IconWithText } from "components/ContactCard/IconWithText";
import { SmallText } from "components/ContactCard/SmallText";
import { Icon } from "components/Icon";
import { ContractorsResponseItem } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import useMediaQuery from "components/utils/useMediaQuery";
import { openInfoWindow } from "./utils";
import { getExternalLink } from "components/utils/links";
import { MarkerProps } from "./types";
import { formatPhoneNumber } from "components/utils/format";
import { ExternalLink } from "components/ExternalLink";
import { isNil } from "lodash";

const EMPTY_VALUE = "-";

export const ContractorCard = ({
    c,
    showOnMapMobile,
    isLoading,
    contractorsWithMarkersRef,
    isInfoWindow,
    mapRef,
    openedInfoWindowRef,
    showClose,
    setSelectedContractor,
    setContractorOnSlideout,
}: {
    c: ContractorsResponseItem;
    showOnMapMobile?: (c: ContractorsResponseItem) => void;
    isLoading?: boolean;
    contractorsWithMarkersRef?: React.MutableRefObject<MarkerProps[]>;
    isInfoWindow?: boolean;
    mapRef?: React.MutableRefObject<google.maps.Map>;
    openedInfoWindowRef?: React.MutableRefObject<google.maps.InfoWindow | null>;
    showClose?: boolean;
    setSelectedContractor?: (v: ContractorsResponseItem | null) => void;
    setContractorOnSlideout?: (v: ContractorsResponseItem) => void;
}) => {
    const isMobile = useMediaQuery("(max-width: 768px)");

    if (isMobile) {
        return (
            <div className="d-flex flex-column w-100">
                <div className="fs-6 d-flex align-items-center justify-content-between gap-2 marker-popup mb-2 w-100">
                    <div className="d-flex gap-2 align-items-center w-100">
                        <div className="d-flex w-100 align-items-center">
                            <span className="text-body fw-bold text-wrap text-break m-0">{c.company}</span>
                            {showClose && (
                                <Button
                                    variant="ghost"
                                    onClick={() => setSelectedContractor?.(null)}
                                    className=" ms-auto btn-close"
                                    aria-label="Close"
                                ></Button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column gap-2 align-items-start">
                    {(c.firstName || c.lastName) && (
                        <>
                            <IconWithText icon={"user"} title="Contact">
                                <span className="py-2">{[c.firstName, c.lastName].join(" ")}</span>
                            </IconWithText>
                            <hr className="my-0 w-100"></hr>
                        </>
                    )}
                    <IconWithText icon={"location-dot"} title="Address">
                        {c.fullAddress ? <span className="py-2">{c.fullAddress}</span> : <span className="py-2">{EMPTY_VALUE}</span>}
                    </IconWithText>
                    {!showClose && c.url && (
                        <>
                            <hr className="my-0 w-100"></hr>
                            <IconWithText icon={"globe"} title="Website">
                                <ExternalLink className="text-break py-2" href={getExternalLink(c.url)} openInNewTab>
                                    {c.url}
                                </ExternalLink>
                            </IconWithText>
                        </>
                    )}
                    {c.email && (
                        <>
                            <hr className="my-0 w-100"></hr>
                            <IconWithText icon={"envelope"} title="Email">
                                <a className="text-break py-2" href={`mailto:${c.email}`}>
                                    {c.email}
                                </a>
                            </IconWithText>
                        </>
                    )}
                    {c.phone && (
                        <>
                            <hr className="my-0 w-100"></hr>
                            <IconWithText icon={"phone"} title="Phone">
                                <a className="py-2" href={`tel:${c.phone}`}>
                                    {formatPhoneNumber(c.phone)}
                                </a>
                            </IconWithText>
                        </>
                    )}
                    {!showClose && c.description && (
                        <>
                            <hr className="my-0 w-100"></hr>
                            <IconWithText icon={"memo"} title="Description">
                                <span className="py-2">{c.description}</span>
                            </IconWithText>
                        </>
                    )}
                </div>
                <div className="d-flex flex-column gap-2 justify-content-end align-self-stretch flex-grow-1 mt-2">
                    <hr className="my-0"></hr>
                    <div className="d-flex gap-2">
                        <Button
                            className="align-self-stretch d-flex justify-content-center align-items-center gap-2 mt-1 flex-grow-1"
                            variant="outline-secondary"
                            aria-haspopup="dialog"
                            disabled={isLoading}
                            onClick={() => setContractorOnSlideout?.(c)}
                        >
                            <span>More info</span>
                        </Button>
                        {showOnMapMobile && (
                            <Button
                                onClick={() => showOnMapMobile(c)}
                                className="align-self-stretch d-flex justify-content-center align-items-center gap-2 mt-1"
                                variant="outline-secondary"
                                aria-haspopup="dialog"
                                disabled={isLoading || isNil(c.lat) || isNil(c.lng)}
                            >
                                <span>View on map</span>
                                <Icon icon={["fal", "arrow-right"]} />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="gap-2 d-flex flex-column">
                <div className="fs-6 d-flex align-items-center justify-content-between gap-2 marker-popup">
                    <div className="d-flex gap-2 align-items-center">
                        <div className="d-flex flex-column">
                            <span className="text-body fw-bold text-wrap text-break m-0">{c.company}</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex  flex-column gap-2">
                    <hr className="my-0"></hr>
                    {(c.firstName || c.lastName) && (
                        <IconWithText icon={"user"} title="Contact">
                            <SmallText>{[c.firstName, c.lastName].join(" ")}</SmallText>
                        </IconWithText>
                    )}
                    <IconWithText icon={"location-dot"} title="Address">
                        <SmallText>{c.fullAddress ? c.fullAddress : EMPTY_VALUE}</SmallText>
                    </IconWithText>
                    {c.url && (
                        <IconWithText icon={"globe"} title="Website">
                            <SmallText>
                                <ExternalLink className="text-break" href={getExternalLink(c.url)} openInNewTab>
                                    {c.url ?? "-"}
                                </ExternalLink>
                            </SmallText>
                        </IconWithText>
                    )}
                    {c.email && (
                        <IconWithText icon={"envelope"} title="Email">
                            <SmallText>
                                <a className="text-break" href={`mailto:${c.email}`}>
                                    {c.email}
                                </a>
                            </SmallText>
                        </IconWithText>
                    )}
                    {c.phone && (
                        <IconWithText icon={"phone"} title="Phone">
                            <SmallText>
                                <a href={`tel:${c.phone}`}>{formatPhoneNumber(c.phone)}</a>
                            </SmallText>
                        </IconWithText>
                    )}
                    {c.description && (
                        <IconWithText icon={"memo"} title="Description">
                            <SmallText>{c.description}</SmallText>
                        </IconWithText>
                    )}
                </div>
                {isInfoWindow && (
                    <>
                        <hr className="my-0"></hr>
                        <div className="mt-2">
                            <div className="">Technologies Available:</div>{" "}
                            <div className="fs-7">{c.technologies?.split(",").join(", ").trim()}</div>
                        </div>
                        <div className="mt-2">
                            <div className="">Services Provided:</div>{" "}
                            <div className="fs-7">{c.services?.split(",").join(", ").trim()}</div>
                        </div>
                    </>
                )}
                {!isInfoWindow && (
                    <div className="d-flex flex-column gap-2 justify-content-end align-self-stretch flex-grow-1 mt-2">
                        <hr className="my-0"></hr>
                        <Button
                            onClick={() => {
                                openInfoWindow(
                                    contractorsWithMarkersRef?.current.find((m) => m.contractor?.contactNumber === c.contactNumber)
                                        ?.marker as google.maps.Marker,
                                    c,
                                    mapRef?.current,
                                    openedInfoWindowRef,
                                    contractorsWithMarkersRef?.current
                                );
                            }}
                            className="align-self-stretch d-flex justify-content-center align-items-center gap-2 mt-1"
                            variant="outline-secondary"
                            aria-haspopup="dialog"
                            disabled={isLoading || isNil(c.lat) || isNil(c.lng)}
                        >
                            <span>View on map</span>
                            <Icon icon={["fal", "arrow-right"]} />
                        </Button>
                    </div>
                )}
            </div>
        );
    }
};
