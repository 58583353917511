import classNames from "classnames";
import { Button } from "components/Button";
import cn from "classnames";
import { Container } from "components/Layout/Container";
import { useColor } from "components/utils/color";
import { AlignXClasses, AlignYClasses, LeadImageContentType, TextColor } from "types/LeadImage";
import { ImagePlaceholder } from "components/ImagePlaceholder";
import { isExternalLink } from "components/utils/links";

import "./style.scss";

export function LeadImage(props: LeadImageProps) {
    const { leadImage, mainBackgroundColor } = props;

    const overlayColor = useColor(leadImage.backgroundColor, leadImage.backgroundColorOpacity);

    const className = cn("lead-image-content d-flex flex-column py-3", AlignYClasses[leadImage.alignY], AlignXClasses[leadImage?.alignX]);

    const openButtonUrl = () => {
        const target = leadImage.target ? leadImage.target : "_self";
        window.open(leadImage.buttonURL, isExternalLink(leadImage.buttonURL) ? target : "_self");
    };

    return (
        // Extra div around grid layout to prevent it from stretching on Safari
        <div>
            <div
                className={classNames("lead-image-wrapper", {
                    "image-cover": leadImage?.imageSize === "cover",
                    "image-auto": leadImage?.imageSize === "auto",
                    "image-fit": leadImage?.imageSize === "fit",
                })}
            >
                {leadImage?.file ? (
                    <>
                        <img
                            className="lead-image"
                            style={{ objectPosition: `${leadImage?.alignBackgroundX} ${leadImage?.alignBackgroundY}` }}
                            src={leadImage?.file}
                            alt="Lead"
                        ></img>
                        {!leadImage.leadImageContentOff && (
                            <Container className="lead-image-content-container">
                                <div
                                    className={className}
                                    style={{
                                        color: leadImage.contentTextColor,
                                    }}
                                >
                                    <span className="content-title lh-1 lh-md-base">{leadImage.contentTitle}</span>
                                    {(leadImage.contentType === "titleText" || leadImage.contentType === "titleTextButton") && (
                                        <span className="content-text">{leadImage.contentText}</span>
                                    )}
                                    {(leadImage.contentType === "titleButton" || leadImage.contentType === "titleTextButton") &&
                                        leadImage.buttonText && (
                                            <div className="content-button">
                                                <Button variant="primary" onClick={openButtonUrl}>
                                                    {leadImage.buttonText}
                                                </Button>
                                            </div>
                                        )}
                                </div>
                            </Container>
                        )}
                        <div
                            className="lead-image-overlay d-flex"
                            style={{
                                backgroundColor: leadImage?.backgroundOff ? undefined : overlayColor,
                            }}
                        ></div>
                    </>
                ) : (
                    <ImagePlaceholder text="lead image" textStyle={{ backgroundColor: mainBackgroundColor }} />
                )}
            </div>
        </div>
    );
}

interface LeadImageProps {
    leadImage: {
        file: any;
        alignX: keyof typeof AlignXClasses;
        alignY: keyof typeof AlignYClasses;
        imageSize: string;
        contentTextColor: TextColor;
        contentType: LeadImageContentType;
        buttonUrl: string;
        buttonText: string;
        backgroundColor: string;
        backgroundColorOpacity: string;
        alignBackgroundX: string;
        alignBackgroundY: string;
        buttonURL: string;
        leadImageContentOff: boolean;
        contentTitle: string;
        contentText: string;
        backgroundOff: boolean;
        target: string;
    };
    mainBackgroundColor?: string;
}
