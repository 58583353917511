import classNames from "classnames";
import useWidgetClasses from "components/utils/useWidgetClasses";
import useWidgetStyles from "components/utils/useWidgetStyles";
import { WidgetClassesProps } from "types/WidgetClassesProps";
import { WidgetStylesProps } from "types/WidgetStylesProps";
import { useCustomLinkHandler } from "components/utils/links";

import "./style.scss";

export function LinkWidget(props: LinkWidgetProps) {
    const style = useWidgetStyles({
        ...props,
        overflow: "hidden",
        opacity: props.opacity ?? "1",
    });

    const className = useWidgetClasses({
        ...props,
        className: classNames(props.className, {
            "link-decoration-none": props.underline === false,
        }),
        alignXClasses: {
            left: "justify-content-start",
            center: "justify-content-center",
            right: "justify-content-end",
        },
        alignYClasses: {
            top: "align-items-start",
            center: "align-items-center",
            bottom: "align-items-end",
        },
    });

    const widgetRef = useCustomLinkHandler<HTMLSpanElement>();

    return (
        <span
            ref={widgetRef}
            className={classNames("link-widget text-break", className)}
            style={style}
            dangerouslySetInnerHTML={{ __html: props.link ?? "" }}
        />
    );
}

interface LinkWidgetProps extends WidgetStylesProps, WidgetClassesProps {
    /**
     * Html content with link
     */
    link?: string;
    /**
     * Underline links. Default value "true"
     */
    underline?: boolean;
}
