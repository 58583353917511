import { AvatarColors } from "components/Avatar/types";
import { ContactTypeCode } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { ContactType, ApplicationContact } from "components/utils/contacts";
import { isString } from "lodash";

export const EMPTY_VALUE = "—";

export const getAvatarColor = (contact?: ApplicationContact) => {
    if (!contact) {
        return AvatarColors.BLUE;
    }

    const contactType = getContactTypeByCode(Number(contact.contactType));

    switch (contactType) {
        case ContactType.Primary:
            return AvatarColors.PINK;
        case ContactType.Premise:
            return AvatarColors.PURPLE;
        case ContactType.Contractor:
            return AvatarColors.ORANGE;
        default: {
            return getContactColor(contact);
        }
    }
};

export const getAvatarText = (contact?: ApplicationContact) => {
    if (!contact) {
        return "";
    }

    if (contact.firstName && contact.lastName) {
        return contact.firstName[0] + contact.lastName[0];
    }

    if (contact.firstName) {
        return contact.firstName[0];
    }

    if (contact.lastName) {
        return contact.lastName[0];
    }

    if (contact.company) {
        return contact.company[0];
    }

    return "";
};

export const getContactName = (contact?: ApplicationContact | string) => {
    if (!contact) {
        return "";
    }

    if (isString(contact)) {
        return contact
            .split(" ")
            .map((n, index) => (index === 0 || index === contact.split(" ").length - 1 ? n[0] : ""))
            .join("");
    } else {
        if (contact.firstName && contact.lastName) {
            return `${contact.firstName} ${contact.lastName}`;
        }

        if (contact.firstName) {
            return contact.firstName;
        }

        if (contact.lastName) {
            return contact.lastName;
        }

        if (contact.company) {
            return contact.company;
        }
        return "";
    }
};

export const getContactTypeByCode = (code: number) => {
    let type;

    switch (code) {
        case ContactTypeCode.Primary:
            type = ContactType.Primary;
            break;
        case ContactTypeCode.Premise:
            type = ContactType.Premise;
            break;
        case ContactTypeCode.Contractor:
            type = ContactType.Contractor;
            break;
        default:
            type = ContactType.Other;
            break;
    }

    return type;
};

export const getContactColor = (contact: ApplicationContact | string) => {
    const colorArray = [
        AvatarColors.PINK,
        AvatarColors.PURPLE,
        AvatarColors.ORANGE,
        AvatarColors.TEAL,
        AvatarColors.BLUE,
        AvatarColors.CYAN,
    ];

    const name = getContactName(contact);
    const nameLength = name.length;
    let sum = 0;

    for (let i = 0; i < nameLength; i++) {
        sum += name.charCodeAt(i);
    }

    const average = sum / nameLength;
    const offset = Math.abs(average - name.charCodeAt(0));
    const colorIndex = Math.round(offset % colorArray.length);

    return colorArray[colorIndex];
};
