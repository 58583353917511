import { ContactForm } from "../ApplicationContactsWidget/ContactForm";
import { ContractorContactForm } from "../ApplicationContactsWidget/ContractorContactForm";
import { AdditionalContactType } from "../ApplicationContactsWidget/types";
import { AdditionalContactFormProps } from "./types";
import { useState } from "react";

export const AdditionalContactForm = ({
    formRef,
    index,
    formData,
    formRefObject,
    extraErrors,
    requirements,
    applicationNumber,
    fullContactRequirements,
    onChange,
    onClearForm,
    onUseCustomerContact,
}: AdditionalContactFormProps) => {
    const requiredFields = requirements.requiredFields?.split(",").map((f) => f.trim().toLowerCase()) ?? [];
    const [contactType, setContactType] = useState<AdditionalContactType>(AdditionalContactType.Stored);
    const showFields = requirements.showFieldNames?.split(",").map((f) => f.trim().toLowerCase()) ?? [];
    const hiddenFields = [
        "firstname",
        "lastname",
        "company",
        "premiseid",
        "meterid",
        "acct_number",
        "address",
        "address_cont",
        "city",
        "state",
        "zip",
        "phone",
        "cell",
        "fax",
        "email",
        "taxid",
    ].filter((f) => !showFields.includes(f));

    const changeContactType = (form: any) => {
        setContactType(form);
        onClearForm();
    };

    return (
        <>
            {contactType === AdditionalContactType.Contractor || formData?.origId ? (
                <ContractorContactForm
                    title={requirements.title}
                    formRef={formRef}
                    formData={formData}
                    extraErrors={extraErrors}
                    applicationNumber={applicationNumber}
                    requirements={fullContactRequirements}
                    isAdditionalContact
                    onChange={(form) => {
                        onChange(form);
                    }}
                    setContactType={changeContactType}
                />
            ) : (
                <ContactForm
                    idPrefix={`additional-contact-${index}`}
                    isAdditionalContact
                    title={requirements.title}
                    formRefObject={formRefObject}
                    formRef={formRef}
                    formData={formData}
                    showTypeSelect={true}
                    applicationNumber={applicationNumber}
                    requirements={fullContactRequirements}
                    requiredFields={requiredFields}
                    hiddenFields={hiddenFields}
                    extraErrors={extraErrors}
                    onChange={(form) => {
                        onChange(form);
                    }}
                    onClearForm={onClearForm}
                    onUseCustomerContact={onUseCustomerContact}
                    setContactType={changeContactType}
                />
            )}
        </>
    );
};
