import { dispatchEventUserAuthenticated, getSessionStorageItem, setSessionStorageItem } from "components/utils";
import { isString } from "lodash";

export const getUser = () => {
    const data = getSessionStorageItem("user");

    return isString(data) ? (JSON.parse(data) as User) : {};
};

export const saveUser = ({
    firstName,
    lastName,
    userName,
    email,
    serverTimezoneOffset,
    accessToken,
    refreshToken,
    customerNumber,
}: {
    firstName: string;
    lastName: string;
    userName?: string;
    email: string;
    serverTimezoneOffset?: number;
    accessToken?: string;
    refreshToken?: string;
    customerNumber?: string;
}) => {
    const item = JSON.stringify({
        firstName,
        lastName,
        userName,
        email,
        serverTimezoneOffset,
        accessToken,
        refreshToken,
    });

    if (customerNumber) {
        setSessionStorageItem("customerNumber", customerNumber);
    } else {
        if (accessToken) {
            const customerNumberFromToken = getCustomerNumberFromToken(accessToken);
            if (customerNumberFromToken) {
                setSessionStorageItem("customerNumber", customerNumberFromToken);
            }
        }
    }

    setSessionStorageItem("user", item);
    localStorage.setItem("user", item);

    const isAuthenticated = accessToken && accessToken?.length > 0 && refreshToken && refreshToken?.length > 0;

    dispatchEventUserAuthenticated(isAuthenticated);
};

export const getTokenData = (accessToken: string) => {
    const jwtData = accessToken.split(".")[1];
    const decodedData = window.atob(jwtData);
    return JSON.parse(decodedData) as TokenData;
};

export const getUserNameFromToken = (accessToken: string) => {
    const tokenData = getTokenData(accessToken);
    return tokenData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] || "";
};

export const getCustomerNumberFromToken = (accessToken: string) => {
    let customerNumber = "";

    if (accessToken) {
        const tokenData = getTokenData(accessToken);
        customerNumber = tokenData["sub"];
    }

    return customerNumber;
};

export const getCustomerNumber = () => {
    const data = getSessionStorageItem("customerNumber");
    return isString(data) ? data : null;
};

type TokenData = {
    aud: string;
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
    sub: string;
    ClientNumber: string;
    exp: number;
    ExpiresIn: string;
    iss: string;
    SessionId: string;
    UserStatus: string;
};

type User = {
    firstName?: string;
    lastName?: string;
    userName?: string;
    email?: string;
    accessToken?: string;
    refreshToken?: string;
};
