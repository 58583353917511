import React, { useCallback } from "react";
import { Image as BootstrapImage } from "react-bootstrap";
import { isNil } from "lodash";
import cn from "classnames";
import { isExternalLink, handleLinkClick, getExternalLink } from "components/utils/links";
import { useHistory } from "react-router";

const ImageWidget = (props: any) => {
    const history = useHistory();
    const { options } = props;
    const imageSrc = options?.imageSrc;
    const imageAltText = options?.imageAltText;
    const imageSize = options?.imageSize;
    const customSize = Number(options?.imageSize);
    const imageLink = options?.imageLink?.trim();
    const openInNewTab = options?.openInNewTab;

    const onImageLinkClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            const target = isExternalLink(imageLink) ? (openInNewTab ? "_blank" : "_self") : "_self";
            const link = isExternalLink(imageLink) ? getExternalLink(imageLink) : imageLink;
            handleLinkClick(event, link, target, history);
        },
        [history, imageLink, openInNewTab]
    );

    let customStyle;
    if (!isNaN(customSize)) {
        customStyle = { maxWidth: customSize + "%" };
    }

    if (isNil(imageSrc)) {
        return null;
    }

    if (imageLink) {
        return (
            <a href={imageLink} rel="noopener noreferrer" onClick={(event) => onImageLinkClick(event)}>
                <BootstrapImage
                    className={cn("image-widget-picture", imageSize)}
                    fluid
                    style={customStyle}
                    src={imageSrc}
                    alt={imageAltText}
                />
            </a>
        );
    }

    return <BootstrapImage className={cn("image-widget-picture", imageSize)} fluid style={customStyle} src={imageSrc} alt={imageAltText} />;
};
export default ImageWidget;
