export const mockedProgramContractorSearchParamsResponse = {
    technologies: [
        {
            id: 5,
            parameterType: "technologies",
            parameterValue: "Air source heat pump",
        },
        {
            id: 7,
            parameterType: "technologies",
            parameterValue: "Cavity wall",
        },
        {
            id: 8,
            parameterType: "technologies",
            parameterValue: "Double / Triple glazing",
        },
        {
            id: 9,
            parameterType: "technologies",
            parameterValue: "Draft proofing",
        },
    ],
    services: [
        {
            id: 1,
            parameterType: "services",
            parameterValue: "ENGINEERING/DESIGN SERVICES",
        },
        {
            id: 2,
            parameterType: "services",
            parameterValue: "EQUIPMENT SALES",
        },
        {
            id: 3,
            parameterType: "services",
            parameterValue: "INSTALLATION SERVICES",
        },
        {
            id: 4,
            parameterType: "services",
            parameterValue: "OTHER",
        },
    ],
};

export const mockedContractorsResponse = {
    totalRecords: 2,
    remainingRecords: 0,
    items: [
        {
            contactNumber: "???",
            firstName: "Robert",
            lastName: "Smith",
            company: "Acme, Inc",
            address: "47 Echo Ave",
            city: "Miller Place",
            state: "NY",
            zip: "11764",
            phone: "111111111",
            email: null,
            technologies:
                "External Solid Wall,Cavity wall,Hot water tank insulation,Loft,Flat roof,Draft proofing,Biomass boiler,Park home insulation,Air source heat pump",
            services: "INSTALLATION SERVICES,ENGINEERING/DESIGN SERVICES,OTHER",
            url: null,
            description: null,
            lat: 40.9452,
            lng: -72.9853,
            fullName: "Acme, Inc",
            fullAddress: "47 Echo Ave\r\nMiller Place, NY 11764",
            origId: "4735063",
            acct_number: null,
        },
        {
            contactNumber: "6CC3539625C7465091F5B0D11FDA0016",
            firstName: "John",
            lastName: "Smith",
            company: "Acme, Inc",
            address: "223 clay pitts rd",
            city: "East Northport",
            state: "NY",
            zip: "11731",
            phone: null,
            email: null,
            technologies:
                "External Solid Wall,Loft,Air source heat pump,Energy Efficient Replacement Doors,Heating controls,Hot water tank thermostats,Solar thermal,Room in Roof",
            services: "INSTALLATION SERVICES,EQUIPMENT SALES,ENGINEERING/DESIGN SERVICES,OTHER",
            url: null,
            description: null,
            lat: 40.8652,
            lng: -72.9141,
            fullName: "J smith Contractors",
            fullAddress: "223 clay pitts rd\r\nEast Northport, NY 11731",
            origId: "123456",
            acct_number: "123456",
        },
    ],
};
