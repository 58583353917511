import cn from "classnames";
import { Button } from "components/Button";
import { isEmpty } from "lodash";
import { Form, Stack } from "react-bootstrap";
import { DataGridFilterInput } from "./DataGridFilterInput";
import { DataGridColumnConfig } from "./types";
import { useFilter } from "./useFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DataGridFilter = ({
    columns,
    filter,
    notClearableFilterKeys,
    onFilterChange,
    stickyActionsColumn,
    canSelectRows,
    allRowsSelected,
    handleSelectAllRows,
}: DataGridFilterProps) => {
    const { isFilterEmpty, isFilterChanged, getFilterValue, onFilterValueChange, onFilterValueKeyDown, onFilterSubmit, onFilterClear } =
        useFilter(filter, onFilterChange, notClearableFilterKeys);

    return (
        <tr className="data-grid-filter d-none d-lg-table-row">
            {canSelectRows && (
                <td className="col-auto d-flex bg-white border-0 d-lg-table-cell py-3">
                    <Form.Check checked={allRowsSelected} onChange={() => handleSelectAllRows!()} />
                </td>
            )}
            {columns.map((column, index) => (
                <td
                    key={index}
                    className={cn(column.className, "bg-white border-0 fs-7", {
                        "col-sorted": !isEmpty(column.sort),
                    })}
                >
                    <DataGridFilterInput
                        column={column}
                        value={getFilterValue(column.key)}
                        onChange={(event, submitFilter) => onFilterValueChange(column.key, event.target.value, submitFilter)}
                        onKeyDown={(event) => onFilterValueKeyDown(column.key, event)}
                    />
                </td>
            ))}
            <td className={cn("bg-white border-0 fs-7", { "sticky-column-right": stickyActionsColumn })}>
                <Stack className="d-flex justify-content-center" style={{ minHeight: "2.5rem" }} direction="horizontal" gap={0}>
                    <Button
                        className="p-2"
                        variant="link"
                        title="Filter"
                        aria-label="Filter"
                        onClick={() => onFilterSubmit()}
                        hidden={!isFilterChanged}
                    >
                        <FontAwesomeIcon icon={"filter-list"} />
                    </Button>
                    <Button
                        className="p-2"
                        variant="link"
                        title="Clear filter"
                        aria-label="Clear filter"
                        onClick={() => onFilterClear()}
                        hidden={isFilterEmpty}
                    >
                        <FontAwesomeIcon icon={"ban"} />
                    </Button>
                </Stack>
            </td>
        </tr>
    );
};

interface DataGridFilterProps {
    columns: DataGridColumnConfig[];
    filter?: string;
    notClearableFilterKeys?: string[];
    onFilterChange: (searchAttr: string) => void;
    stickyActionsColumn?: boolean;
    canSelectRows?: boolean;
    allRowsSelected?: boolean;
    handleSelectAllRows?: () => void;
}
