import { EquipmentCatalog } from "./types";
import { ListGroup } from "react-bootstrap";

export const Catalogs = ({ items, onClick }: CatalogsProps) => {
    return (
        <ListGroup>
            {items.map((catalog, index) => (
                <ListGroup.Item key={index} action onClick={() => onClick(catalog)}>
                    {catalog.name}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
};
interface CatalogsProps {
    items: EquipmentCatalog[];
    onClick: (catalog: EquipmentCatalog) => void;
}
