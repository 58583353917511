import { CrossTreeSelector } from "axe-core";

export enum ValidationMessageImpact {
    Error = "error",
    Warning = "warning",
    Notice = "notice",
    Unknown = "unknown",
}

export enum ValidationStandard {
    Section508 = "Section508",
    WCAG2A = "WCAG2A",
    WCAG2AA = "WCAG2AA",
    WCAG2AAA = "WCAG2AAA",
}

export enum Validator {
    AxeCore = "axe-core",
    Htmlcs = "htmlcs",
}

export interface ValidationMessageNode {
    selector?: CrossTreeSelector;
    innerHtml: string;
}

export interface AccessibilityValidationMessage {
    id: string;
    message: string;
    description?: string;
    impact: ValidationMessageImpact;
    nodes: ValidationMessageNode[];
    validator?: Validator;
    originalMessage?: unknown;
    url: string;
}
