import { useContext, useEffect, useMemo, useRef } from "react";
import { ApplicationNote } from "types/SubmittedAppData";
import { RawHTML } from "components/RawHTML";
import { formatDateAndTime } from "components/utils/date";
import { Avatar } from "components/Avatar";
import { getUser } from "components/utils/user";
import cn from "classnames";
import { AppContext } from "components/App/AppContext";
import { TypographyLineHeight } from "types/TypographyLineHeight";
import { TypographyFontSize } from "types/TypographyFontSize";

export const MessagesList = ({ messages, isMobile }: { messages: ApplicationNote[]; isMobile?: boolean }) => {
    const listRef = useRef<HTMLDivElement>(null);
    const user = useMemo(() => getUser(), []);
    const currentUserName = [user.firstName, user.lastName].join(" ");

    const oldMessages = messages.filter((message) => !message.isUnread || (message.isUnread && message.userName === currentUserName));
    const newMessages = messages.filter((message) => message.isUnread && message.userName !== currentUserName);
    const hasUnreadMessages = newMessages.length > 0;

    // Automatically scroll to bottom when new messages are added
    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [listRef, messages]);

    return (
        <div
            ref={listRef}
            className={cn(
                "submitted-app-messages-list d-flex flex-column align-items-end align-self-stretch flex-grow-1 gap-3 overflow-y-auto border-top",
                {
                    "px-4 py-3": !isMobile,
                    "p-2p5 py-3": isMobile,
                }
            )}
        >
            {oldMessages.map((message, index) => (
                <MessagesListItem key={index} message={message} isCurrentUser={message.userName === currentUserName} />
            ))}
            {hasUnreadMessages && (
                <>
                    <div className="d-flex flex-row justify-content-center align-items-center gap-3 w-100">
                        <hr className="w-100" />
                        <span className="flex-shrink-0 fw-bold">Latest unread messages</span>
                        <hr className="w-100" />
                    </div>
                    {newMessages.map((message, index) => (
                        <MessagesListItem key={index} message={message} isCurrentUser={message.userName === currentUserName} />
                    ))}
                </>
            )}
        </div>
    );
};

const MessagesListItem = ({ message, isCurrentUser }: { message: ApplicationNote; isCurrentUser: boolean }) => {
    const avatarClassName = useAvatarClassName();

    if (isCurrentUser) {
        return <MessagesListItemForCurrentUser message={message} />;
    }

    return (
        <div className="submitted-app-messages-list-item d-flex flex-column gap-1 w align-self-start align-items-start pe-3">
            <div className="d-flex flex-wrap align-items-baseline column-gap-2 lh-base text-break">
                <span className="fw-bold">{message.userName}</span>
                <span className="small">{formatDateAndTime(message.noteDate)}</span>
            </div>
            <div className="d-flex align-items-start gap-2">
                <div className={avatarClassName}>
                    <Avatar text={getAvatarText(message.userName)} color={"purple"} badgeSize="md" />
                </div>
                <RawHTML className="submitted-app-message-content p-3 rounded bg-white text-break">{message.note}</RawHTML>
            </div>
        </div>
    );
};

const MessagesListItemForCurrentUser = ({ message }: { message: ApplicationNote }) => {
    return (
        <div className="submitted-app-messages-list-item d-flex flex-column gap-1 align-self-end align-items-end ps-3">
            <div className="d-flex align-items-baseline gap-2 justify-content-end">
                <span className="small">{formatDateAndTime(message.noteDate)}</span>
            </div>
            <div className="d-flex align-items-start gap-2">
                <RawHTML className="submitted-app-message-content p-3 rounded bg-dark-subtle text-break">{message.note}</RawHTML>
            </div>
        </div>
    );
};

// take fist character of every word in a string. If there is only one word then take first two characters. Max 2 chars.
const getAvatarText = (text: string) => {
    const words = text.split(" ");

    if (words.length === 1) {
        return text.slice(0, 2);
    }

    return words
        .slice(0, 2)
        .map((n) => n.charAt(0))
        .join("");
};

const useAvatarClassName = () => {
    const { settings } = useContext(AppContext);

    if (settings.fontSize === TypographyFontSize.Small) {
        switch (settings.lineHeight) {
            case TypographyLineHeight.Small:
                return "py-1";
            case TypographyLineHeight.Normal:
                return "py-1";
            case TypographyLineHeight.Large:
                return "py-2";
            default:
                return "py-1";
        }
    }

    if (settings.fontSize === TypographyFontSize.Normal) {
        switch (settings.lineHeight) {
            case TypographyLineHeight.Small:
                return "py-1";
            case TypographyLineHeight.Normal:
                return "py-2";
            case TypographyLineHeight.Large:
                return "py-2p5";
            default:
                return "py-1";
        }
    }

    if (settings.fontSize === TypographyFontSize.Large) {
        switch (settings.lineHeight) {
            case TypographyLineHeight.Small:
                return "py-2";
            case TypographyLineHeight.Normal:
                return "py-2p5";
            case TypographyLineHeight.Large:
                return "py-3";
            default:
                return "py-2";
        }
    }

    return "py-2";
};
