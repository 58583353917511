import classNames from "classnames";
import { Icon } from "components/Icon";
import useWidgetStyles from "components/utils/useWidgetStyles";
import { NavigationLink } from "types/NavigationLink";
import { useContext } from "react";
import { ColumnContext } from "components/Layout/Column";
import useWidgetClasses from "components/utils/useWidgetClasses";
import type { ColumnContextInterface } from "types/ColumnContextInterface";
import type { SocialLinkIcon } from "types/SocialLinkIcon";
import { IconName } from "@fortawesome/fontawesome-svg-core";

import "./style.scss";

const alignXClasses = {
    left: "align-items-start",
    center: "align-items-center",
    right: "align-items-end",
};

const alignYClasses = {
    top: "justify-content-start",
    center: "justify-content-center",
    bottom: "justify-content-end",
};

export function SocialLinks(props: SocialLinksProps) {
    const { layout } = props;

    const columnContext = useContext<ColumnContextInterface>(ColumnContext)!;

    const links = getLinks(props);

    const listClassName = useWidgetClasses({
        alignX: columnContext.alignX,
        alignY: columnContext.alignY,
        alignXClasses,
        alignYClasses,
    });

    const linkStyle = useWidgetStyles({
        textColor: props.textColor,
    });

    if (!props.version || props.version < 2) {
        return <SocialLinksV1 {...props} />;
    }

    return (
        <ul className={classNames(`social-links-widget list-unstyled d-flex flex-${layout} flex-wrap m-0 gap-2`, listClassName)}>
            {links.map((link, index) => (
                <li key={index}>
                    <a
                        href={link.url}
                        target="_blank"
                        rel="noreferrer"
                        className={classNames("d-flex m-1 align-items-center", {
                            "text-decoration-none": link.underline === false,
                        })}
                        style={linkStyle}
                    >
                        {link.icon && <Icon title={link.text || link.altText} icon={link.icon} iconVersion={2} />}
                        {link.text && (
                            <span
                                className={classNames({
                                    "ms-2": link.icon,
                                })}
                            >
                                {link.text}
                            </span>
                        )}
                    </a>
                </li>
            ))}
        </ul>
    );
}

export function SocialLinksV1(props: SocialLinksProps) {
    const { layout } = props;

    const links = getLinks(props);

    const linkStyle = useWidgetStyles({
        textColor: props.textColor,
    });

    return (
        <ul className={`social-links-widget-v1 list-unstyled d-flex flex-${layout} flex-wrap m-0`}>
            {links.map((link, index) => (
                <li key={index}>
                    <a
                        href={link.url}
                        target="_blank"
                        rel="noreferrer"
                        className={classNames("social-links-widget-item d-flex m-1 align-items-center", {
                            "text-decoration-none": link.underline === false,
                        })}
                        style={linkStyle}
                    >
                        {link.icon && <Icon className="social-media-icon" title={link.text || link.altText} icon={link.icon} />}
                        {link.text && <span className="social-media-text">{link.text}</span>}
                    </a>
                </li>
            ))}
        </ul>
    );
}

const getLinks = (props: SocialLinksProps) => {
    let links: NavigationLink[] = [];
    const linkIndexes: LinkIndex[] = [1, 2, 3, 4, 5];

    const getLink = (index: LinkIndex): NavigationLink => {
        return {
            text: props[`link${index}Text`],
            altText: props[`link${index}AltText`],
            icon: props[`link${index}Icon`],
            url: props[`link${index}Url`],
            underline: props.underline,
        };
    };

    linkIndexes.forEach((i) => {
        if (props[`link${i}Url`]) {
            const link = getLink(i);

            links.push(link);
        }
    });

    return links;
};

type LinkIndex = 1 | 2 | 3 | 4 | 5;

interface SocialLinksProps {
    /**
     * Widget version.
     */
    version?: number;
    /**
     * Horizontal or vertical layout.
     */
    layout: "row" | "column";

    /**
     * Link text color. Uses global link color if not provided.
     */
    textColor?: string;

    /**
     * Link underline. Default value "true"
     */
    underline?: boolean;

    /**
     * Link text.
     */
    link1Text?: string;

    /**
     * Link alt text. Text of AltText should be provided.
     */
    link1AltText?: string;

    /**
     * Link icon.
     */
    link1Icon?: SocialLinkIcon | IconName;

    /**
     * Link url
     */
    link1Url: string;

    link2Text?: string;
    link2AltText?: string;
    link2Icon?: SocialLinkIcon;
    link2Url: string;

    link3Text?: string;
    link3AltText?: string;
    link3Icon?: SocialLinkIcon;
    link3Url: string;

    link4Text?: string;
    link4AltText?: string;
    link4Icon?: SocialLinkIcon;
    link4Url: string;

    link5Text?: string;
    link5AltText?: string;
    link5Icon?: SocialLinkIcon;
    link5Url: string;
}
