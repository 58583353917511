import classNames from "classnames";
import { Components } from "components";
import useWidgetStyles from "components/utils/useWidgetStyles";
import { HeaderProps } from "types/HeaderProps";

export function Header(props: HeaderProps) {
    const { components = [], isEnabled } = props;
    const style = useWidgetStyles(props);

    if (!isEnabled) {
        return null;
    }

    return (
        <header className={classNames("page-header")} style={style}>
            {components.map((component, index) => {
                const Component = Components[component.type];
                return Component ? <Component key={index} index={index} {...component.props} /> : null;
            })}
        </header>
    );
}
