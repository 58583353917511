import {
    getDateValueForInput,
    getDateWithoutTime,
    jsonDateToDate,
    formatJsonDate,
    dateToJson,
    dateStringToJsonDate,
    validateDate,
} from "components/utils/date";
import { isInViewport } from "components/utils/dom";
import { REQUIRED_FIELD_ERROR_TEXT, isNullOrWhitespace } from "components/utils/validation";
import { get, isDate, isEmpty, isNil } from "lodash";

export const validateCheckboxField = (fieldSchema: any, schemaId: string, value: boolean | undefined, errors: any, parentSchema: any) => {
    const fieldId = schemaId.split(".").pop();
    const isRequired = (parentSchema?.required ?? []).includes(fieldId);

    if (value === false && isRequired) {
        const fieldErrorSchema = get(errors, schemaId);
        fieldErrorSchema.addError(REQUIRED_FIELD_ERROR_TEXT);
    }
};

export const validateCheckboxesField = (fieldSchema: any, schemaId: string, value: [] | undefined, errors: any, parentSchema: any) => {
    const fieldId = schemaId.split(".").pop();
    const isRequired = (parentSchema?.required ?? []).includes(fieldId);

    if (value?.length === 0 && isRequired) {
        const fieldErrorSchema = get(errors, schemaId);
        fieldErrorSchema.addError(REQUIRED_FIELD_ERROR_TEXT);
    }
};

export const validateDateField = (fieldSchema: any, schemaId: string, value: string, errors: any, rootFieldId: string = "root") => {
    const isFormatDate = fieldSchema.format !== "date-time";

    const fieldDateValue = getDateValueForInput(value, fieldSchema.format);
    const fieldErrorSchema = get(errors, schemaId);

    if (value) {
        if (fieldSchema.format === "date" || isNil(fieldSchema.format)) {
            const date = dateStringToJsonDate(value);
            if (!validateDate(date)) {
                fieldErrorSchema.addError("Invalid date");
            }
        }

        if (fieldSchema.format === "date-time") {
            const date = dateStringToJsonDate(value.substring(0, 10));
            if (!validateDate(date)) {
                fieldErrorSchema.addError("Invalid date");
            }
        }
    } else {
        const fieldId = rootFieldId + "_" + schemaId.split(".").join("_");
        const input = document.getElementById(fieldId) as HTMLInputElement;
        if (input && (input.validity?.badInput || input.validity?.valueMissing)) {
            fieldErrorSchema.addError("Invalid date");
        }
    }

    const todayDate = isFormatDate ? getDateWithoutTime(new Date()) : new Date();

    const formatMinimum =
        fieldSchema.formatMinimum && (fieldSchema.formatMinimum === "today" ? todayDate : jsonDateToDate(fieldSchema.formatMinimum));

    const formatMaximum =
        fieldSchema.formatMaximum && (fieldSchema.formatMaximum === "today" ? todayDate : jsonDateToDate(fieldSchema.formatMaximum));

    if (isDate(formatMaximum) && fieldSchema.formatMaximum !== "today") {
        formatMaximum.setHours(23, 59);
    }

    if (isDate(fieldDateValue)) {
        if (formatMinimum && formatMinimum > fieldDateValue) {
            const fieldErrorSchema = get(errors, schemaId);
            const jsonDate = dateToJson(formatMinimum) ?? "";
            fieldErrorSchema.addError("Minimum allowed date is " + formatJsonDate(jsonDate));
        }

        if (formatMaximum && formatMaximum < fieldDateValue) {
            const fieldErrorSchema = get(errors, schemaId);
            const jsonDate = dateToJson(formatMaximum) ?? "";
            fieldErrorSchema.addError("Maximum allowed date is " + formatJsonDate(jsonDate));
        }
    }
};

export const validateWorkflowField = (fieldSchema: any, schemaId: string, value: string, errors: any) => {
    if (value === "false") {
        const fieldErrorSchema = get(errors, schemaId);
        fieldErrorSchema.addError("All tasks should be completed");
    }
};

export const validateEquipmentBlock = (fieldSchema: any, schemaId: string, value: string, errors: any) => {
    if (value === "false") {
        const fieldErrorSchema = get(errors, schemaId);
        fieldErrorSchema.addError("There should be at least one selected equipment");
    }
};

//force form to validate min and max length for input type number/integer, ticket:V50-8091
export const validateInputTypeNumber = (fieldSchema: any, schemaId: string, value: number | null | undefined, errors: any) => {
    if (!isNil(value)) {
        const fieldErrorSchema = get(errors, schemaId);
        if (fieldSchema.hasOwnProperty("minLength") && value.toString().length < fieldSchema["minLength"]) {
            fieldErrorSchema.addError(
                `should NOT be shorter than ${fieldSchema["minLength"]} ${fieldSchema["minLength"] > 1 ? "characters" : "character."}`
            );
        } else if (fieldSchema.hasOwnProperty("maxLength") && value.toString().length > fieldSchema["maxLength"]) {
            fieldErrorSchema.addError(
                `should NOT be longer than ${fieldSchema["maxLength"]} ${fieldSchema["maxLength"] > 1 ? "characters." : "character."}`
            );
        }
    }
};

export const validatePattern = (fieldSchema: any, schemaId: string, value: string, errors: any) => {
    const fieldErrorSchema = get(errors, schemaId);

    if (value && fieldSchema.pattern) {
        const pattern = new RegExp(fieldSchema.pattern);

        if (Array.isArray(value)) {
            if (!pattern.test(value.join(","))) {
                fieldErrorSchema.addError(`should match pattern "${fieldSchema.pattern}"`);
            }
        } else if (!pattern.test(value)) {
            fieldErrorSchema.addError(`should match pattern "${fieldSchema.pattern}"`);
        }
    }
};

export const validateSignatureField = (fieldSchema: any, schemaId: string, value: string, errors: any, parentSchema: any) => {
    const fieldId = schemaId.split(".").pop();
    const isRequired = (parentSchema?.required ?? []).includes(fieldId);

    if (isRequired) {
        const fieldErrorSchema = get(errors, schemaId);
        const [signature, checked] = (value || "").split("|");

        const requiredMessage = "is a required field";
        let signatureError = "";
        let confirmationError = "";

        if (isNullOrWhitespace(signature)) {
            signatureError = requiredMessage;
        }

        if (checked !== "true") {
            confirmationError = requiredMessage;
        }

        if (!isEmpty(signatureError) || !isEmpty(confirmationError)) {
            fieldErrorSchema.addError(
                JSON.stringify({
                    signature: signatureError,
                    confirmation: confirmationError,
                })
            );
        }
    }
};

export const scrollToFirstError = () => {
    const errorEl = document.querySelector<HTMLDivElement>(".field-error");

    if (errorEl) {
        // Scroll into view
        if (!isInViewport(errorEl)) {
            errorEl.scrollIntoView();
        }

        // Focus
        const firstErrorInput = errorEl.querySelector<HTMLElement>("input, textarea, select, button");
        if (firstErrorInput) {
            firstErrorInput.focus();
        }
    }
};
