import classNames from "classnames";
import useWidgetClasses from "components/utils/useWidgetClasses";
import useWidgetStyles from "components/utils/useWidgetStyles";

import "./List.scss";

export function List(props) {
    const style = useWidgetStyles({
        ...props,
        overflow: "hidden",
    });

    const className = useWidgetClasses({
        ...props,
        alignXClasses: {
            left: "justify-content-start",
            center: "justify-content-center",
            right: "justify-content-end",
        },
        alignYClasses: {
            top: "align-items-start",
            center: "align-items-center",
            bottom: "align-items-end",
        },
    });

    return <div className={classNames("list-widget", className)} style={style} dangerouslySetInnerHTML={{ __html: props.list }} />;
}
