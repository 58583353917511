import { AppContext } from "components/App/AppContext";
import { ErrorSummary } from "components/ErrorSummary";
import { PageLink } from "components/App/utils";
import { Heading } from "components/Heading";
import { JsonForm } from "components/JsonForm";
import { Container } from "components/Layout/Container";
import { verifyAuthCode, createAccount, userNewAuthCode, usePasswordRequirements } from "components/utils";
import { isEmpty } from "lodash";
import { useState, useContext, useEffect, useCallback } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { usePageTopAndBottomRows } from "components/utils/page";
import { PageComponentsList } from ".";
import { getCustomerNumber, saveUser } from "components/utils/user";
import { AnalyticsEventType, sendAnalyticsEvent } from "components/utils/analytics";
import { Button } from "../Button";
import { Alert } from "react-bootstrap";

export function CreateAccountPage() {
    const history = useHistory();
    const [validated, setValidated] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { programNumber } = useContext(AppContext);
    const {
        requirements: { requireAccountNumber },
    } = useContext(AppContext);

    const location = useLocation<{ referrer: string }>();
    const customerNumber = getCustomerNumber();
    const [customerId, setCustomerId] = useState<string>();
    const [errorSummary, setErrorSummary] = useState<any>();
    const { topRows, bottomRows } = usePageTopAndBottomRows();
    const [showNewAuthButton, setShowNewAuthButton] = useState<boolean>(false);
    const [disableNewAuthButton, setDisableNewAuthButton] = useState<boolean>(false);
    const [showGotNewAuthCodeMessage, setShowGotNewAuthCodeMessage] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<string | null>(null);
    const [passwordRequirements, isLoading] = usePasswordRequirements();

    useEffect(() => {
        if (!isEmpty(location?.state?.referrer)) {
            const pathParts = location.state.referrer.split("/");
            if (!isEmpty(pathParts[2])) {
                setValidated(true);
                setCustomerId(pathParts[2]);
            }
        }
        if (location?.state?.referrer?.includes("customerAuthCode")) {
            const urlParams = new URLSearchParams(`?${location?.state?.referrer?.split("?")[1]}`);
            setPageNumber(urlParams.get("pagenumber"));
            setShowNewAuthButton(true);
        }
    }, [location]);
    const thirtyMins = 1800000;

    useEffect(() => {
        const interval = setInterval(() => {
            setShowNewAuthButton(true);
        }, thirtyMins);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setDisableNewAuthButton(false);
        }, thirtyMins);

        return () => clearInterval(interval);
    }, [disableNewAuthButton]);

    const accountSchema = {
        type: "object",
        required: ["firstName", "lastName", "email", "repeatEmail", "password", "repeatPassword"],
        properties: {
            firstName: {
                type: "string",
                title: "First Name:",
            },
            lastName: {
                type: "string",
                title: "Last Name:",
            },
            email: {
                type: "string",
                title: "Email Address:",
                format: "email",
            },
            repeatEmail: {
                type: "string",
                title: "Re-Enter Email Address",
                format: "email",
            },
            accountNumber: {
                type: "string",
                title: "Utility Account Number",
            },
            password: {
                type: "string",
                title: "Password:",
            },
            repeatPassword: {
                type: "string",
                title: "Re-Enter password:",
            },
        },
    };

    const accountUiSchema = {
        firstName: {
            "ui:placeholder": "Enter First Name",
        },
        lastName: {
            "ui:placeholder": "Enter Last Name",
        },
        email: {
            "ui:placeholder": "Enter Email Address",
        },
        repeatEmail: {
            "ui:placeholder": "Re-Enter Email Address",
        },
        accountNumber: {
            "ui:placeholder": "Enter Account Number",
            "ui:widget": requireAccountNumber ? "text" : "hidden",
        },
        password: {
            "ui:widget": "password",
            "ui:help": !isLoading && passwordRequirements,
        },
        repeatPassword: {
            "ui:widget": "password",
        },
    };

    const authenticationSchema = {
        type: "object",
        required: ["authCode"],
        properties: {
            authCode: {
                type: "string",
                title: "Authorization Code:",
            },
        },
    };

    const authenticationUiSchema = {
        authCode: {
            "ui:placeholder": "Enter your authorization code",
        },
    };

    function validation(formData: any, errors: any) {
        if (formData.password !== formData.repeatPassword) {
            errors.repeatPassword.addError("Passwords don't match");
        }
        if (formData.email !== formData.repeatEmail) {
            errors.repeatEmail.addError("Email addresses don't match");
        }
        return errors;
    }

    const onSubmitAccount = useCallback(
        async ({ formData }: any) => {
            const urlParams = new URLSearchParams(`?${location?.state?.referrer?.split("?")[1]}`);
            const pageNumber = urlParams.get("pageNumber");
            setPageNumber(pageNumber);
            setErrorSummary(undefined);
            setIsSubmitting(true);

            createAccount({
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                password: formData.password,
                accountNumber: formData.accountNumber,
                programNumber: programNumber,
                pageNumber,
            })
                .then(() => {
                    saveUser({
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        email: formData.email,
                    });
                    setValidated(true);
                })
                .catch((error) => {
                    setUserEmail(formData.email);
                    setErrorSummary(error);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        },
        [location?.state?.referrer, programNumber]
    );

    const onSubmitAuthCode = useCallback(
        async ({ formData }: { formData: any }) => {
            setErrorSummary(undefined);
            setIsSubmitting(true);

            verifyAuthCode({
                id: customerNumber ?? customerId,
                authCode: formData.authCode,
            })
                .then(() => {
                    let redirectToApp: string | null = location?.state?.referrer;

                    if (redirectToApp?.startsWith(PageLink.CustomerAuthCode)) {
                        // If the link in the create account email was opened, need to build the redirect url using the pagenumber query param
                        const urlParams = new URLSearchParams(`?${location?.state?.referrer?.split("?")[1]}`);
                        const pageNumber = urlParams.get("pagenumber");
                        redirectToApp = pageNumber ? `${PageLink.Application}?pageNumber=${pageNumber}` : null;
                    }

                    sendAnalyticsEvent(AnalyticsEventType.ACCOUNT_CREATED, { isRedirectToApp: Boolean(redirectToApp) });

                    if (redirectToApp) {
                        history.push(redirectToApp);
                    } else {
                        history.push(PageLink.CustomerHome);
                    }
                })
                .catch((error) => {
                    setErrorSummary(error);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        },
        [customerId, customerNumber, history, location?.state?.referrer]
    );

    const newAuthCode = useCallback(() => {
        setDisableNewAuthButton(true);
        userNewAuthCode({
            programNumber,
            pageNumber,
        })
            .then(() => {
                setValidated(true);
                setShowGotNewAuthCodeMessage(true);
            })
            .catch((error) => {
                setErrorSummary(error);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }, [programNumber, pageNumber]);

    if (validated) {
        return (
            <Container className="p-0">
                <Heading>Enter authorization code sent via email</Heading>
                <ErrorSummary errorSummary={errorSummary} />
                <div className="d-flex justify-content-start">
                    <Alert
                        dismissible
                        onClose={() => setShowGotNewAuthCodeMessage(false)}
                        show={showGotNewAuthCodeMessage}
                        variant="success"
                    >
                        <p className="mb-0">A new authorization code has been sent to your email.</p>
                    </Alert>
                </div>
                <JsonForm
                    schema={authenticationSchema}
                    uiSchema={authenticationUiSchema}
                    onSubmit={onSubmitAuthCode}
                    isSubmitting={isSubmitting}
                />

                {showNewAuthButton && (
                    <Button onClick={newAuthCode} className="mt-4" disabled={disableNewAuthButton}>
                        Get New Authorization Code
                    </Button>
                )}
            </Container>
        );
    }

    const resetPasswordMessage = (
        <div>
            An account with this email already exists. Do you need to{" "}
            <Link to={`${PageLink.ForgotPassword}?email=${userEmail}`}>reset your password</Link>?
        </div>
    );

    return (
        <>
            <Container className="p-0">
                <Heading>Create Account</Heading>
            </Container>
            <PageComponentsList components={topRows} />
            <Container className="p-0">
                <ErrorSummary
                    errorSummary={errorSummary?.toString() === "Error: Customer already exists" ? resetPasswordMessage : errorSummary}
                />
                <JsonForm
                    schema={accountSchema}
                    uiSchema={accountUiSchema}
                    onSubmit={onSubmitAccount}
                    validate={validation}
                    isSubmitting={isSubmitting}
                    formName="Create Account"
                />
            </Container>
            <PageComponentsList components={bottomRows} />
        </>
    );
}
