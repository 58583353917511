import { useMemo } from "react";
import classNames from "classnames";
import { useCustomLinkHandler } from "components/utils/links";
import useWidgetClasses from "components/utils/useWidgetClasses";
import useWidgetStyles from "components/utils/useWidgetStyles";

import "./TextWidget.scss";

export function TextWidget(props) {
    const style = useWidgetStyles({
        ...props,
        overflow: "hidden",
        opacity: props.opacity ?? 1,
    });

    const className = useWidgetClasses({
        ...props,
        alignXClasses: {
            left: "justify-content-start",
            center: "justify-content-center",
            right: "justify-content-end",
        },
        alignYClasses: {
            top: "align-items-start",
            center: "align-items-center",
            bottom: "align-items-end",
        },
    });

    const widgetRef = useCustomLinkHandler();

    const processedText = useMemo(() => {
        let newText = props.text ?? "";

        // Wrap tables with the Bootstrap .table-responsive div
        newText = newText.replace(/<table/gi, '<div class="table-responsive"><table');
        newText = newText.replace(/<\/table>/gi, "</table></div>");

        // Process fast tags
        newText = newText.replace(/%%YEAR%%/g, new Date().getFullYear());

        return newText;
    }, [props.text]);

    return (
        <div
            ref={widgetRef}
            className={classNames("text-widget text-break", className)}
            style={style}
            dangerouslySetInnerHTML={{ __html: processedText }}
        />
    );
}
