import { Spinner } from "react-bootstrap";
import { Button } from ".";
import { ButtonProps } from "types/ButtonProps";

export const SubmitButton = (props: SubmitButtonProps) => {
    const { children, disabled } = props;
    const { buttonWidth, spinnerText, isSubmitting, ...commonProps } = props;
    return (
        <Button {...commonProps} disabled={isSubmitting || disabled}>
            {isSubmitting && (
                <Spinner as="span" className="me-2" animation="border" size="sm" role="status">
                    <span className="visually-hidden">{spinnerText}</span>
                </Spinner>
            )}
            {children}
        </Button>
    );
};

interface SubmitButtonProps extends ButtonProps {
    isSubmitting?: boolean;
    spinnerText: string;
}
