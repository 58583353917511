import { AppContext } from "components/App/AppContext";
import { PowerBiReportEmbed } from "components/PowerBiReport/PowerBiReportEmbed";
import { usePowerBiPublicReport } from "components/PowerBiPublicReport/usePowerBiPublicReport";
import { useContext } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { PowerBiReportEntityType } from "types/PowerBiReportEntityType";
import { WidgetStylesProps } from "types/WidgetStylesProps";

export const PowerBiPublicReport = (props: PowerBiPublicReportProps) => {
    const { reportId } = props;
    const { programNumber } = useContext(AppContext);

    // Use placeholder program number in preview mode. Actual portal will provide the program number.
    const [report, isLoading, error] = usePowerBiPublicReport(
        reportId,
        programNumber ?? "_PROGRAM_NUMBER_",
        PowerBiReportEntityType.Program
    );

    if (!reportId) {
        return null;
    }

    if (!report && isLoading) {
        return (
            <Spinner animation="border" className="m-auto" role="status">
                <span className="visually-hidden">Loading report</span>
            </Spinner>
        );
    }

    if (!report?.token || !report?.url || error) {
        return (
            <Alert className="m-auto" variant="danger">
                Failed to load the report.
            </Alert>
        );
    }

    return (
        <PowerBiReportEmbed
            className="w-100 h-100"
            embedId={reportId}
            embedUrl={report.url}
            accessToken={report.token}
            navContentPaneEnabled={false}
        />
    );
};

export interface PowerBiPublicReportProps extends WidgetStylesProps {
    title?: string;
    reportId: string;
}
