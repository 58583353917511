import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

interface EquipmentLabelProps {
    equipment: {
        name: string;
        category: string;
        equipid?: string;
        catalogNumber?: string;
        imgFileLocation?: string;
    };
    className?: string;
}

export const EquipmentLabel = ({ equipment, className }: EquipmentLabelProps) => {
    const isValidIconName = (name?: string): name is IconName => true;

    function getCategoryIcon(): IconProp {
        const iconProps = equipment?.imgFileLocation?.split(" ");
        if (iconProps) {
            const iconName = iconProps[iconProps?.length - 1]?.replace("fa-", "");
            return ["fal", isValidIconName(iconName) ? iconName : "cube"];
        }
        return ["fal", "cube"];
    }

    return (
        <div className={cn("equipment-label d-flex align-items-center gap-2", className)}>
            <FontAwesomeIcon className="category-icon p-1 rounded-1 flex-shrink-0" icon={getCategoryIcon()} size="lg" />
            <div className="d-flex flex-column">
                <span className="m-0 fw-bold text-break" id={`${equipment.equipid ?? equipment.catalogNumber}-name`}>
                    {equipment.name}
                </span>
                <div className="m-0 text-break fw-normal">
                    <span className="fw-bold">Product category: </span>
                    {equipment.category}
                </div>
            </div>
        </div>
    );
};
