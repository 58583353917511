import { ContactInboundData } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { httpGetAuthorized } from "components/utils/http";
import useSWR, { KeyedMutator } from "swr";
import { getBrowserTimezoneOffset } from "./date";
import { toArray } from "./array";
import { omit } from "lodash";
import { ApplicationContact } from "./contacts";

export const useCustomerContacts = (
    page: number,
    pageSize: number
): [contacts: ContactInboundData[] | undefined, isLoading: boolean, error: any, totalRecords: number, mutate: KeyedMutator<any>] => {
    const baseUrl = pageSize > 0 ? process.env.REACT_APP_CUSTOMER_CONTACTS_ENDPOINT : null;

    let url = null;
    if (baseUrl) {
        const query = new URLSearchParams();
        query.append("pageNum", String(page));
        query.append("recsPerPage", String(pageSize));
        query.append("timeOffset", String(getBrowserTimezoneOffset()));

        url = baseUrl + "?" + query.toString();
    }

    const { data, error, mutate } = useSWR(url, httpGetAuthorized);
    const isLoading = Boolean(!error && !data && url !== null);

    let contacts: ContactInboundData[] | undefined = undefined;
    let totalRecords = 0;

    if (data?.grid) {
        const rows = toArray(data?.grid.rows);
        if (rows?.length > 0) {
            totalRecords = Number(rows[0]["totRecords"]);
            contacts = rows
                .map((i: any) => omit(i, ["MoreRecords", "totRecords"]))
                .map((i: any) => ({
                    contactType: i["c_0583dc7f-2d25-43b4-a2fa-eb51b7f6bccf"],
                    contactNumber: i["c_280b0954-4e11-4eb9-b739-9496c1275aae"],
                    contactTitle: i["c_9bae6a22-2da8-4d9c-a1d4-4f2ef7dae842"],
                    firstName: i["c_864bdda4-1bf6-4689-ba07-35a33ce67e1a"],
                    lastName: i["c_f61773e2-39de-44a2-ae20-201a7a4e5c82"],
                    company: i["c_de6110ba-15c9-4c31-a180-1c4a789ff2b0"],
                    address: i["c_38c90d47-1195-4652-8c39-0ba44fcc5b91"],
                    address_cont: i["c_27a26f5a-3474-4a46-a75c-0d9ce13adda0"],
                    city: i["c_2fdfee48-f62b-4766-b542-62ee2f2e2a44"],
                    state: i["c_01ab19aa-e2f8-4816-96f8-9b34913f2326"],
                    zip: i["c_1d03498f-d65e-433d-99ae-715ace3ddb9d"],
                    phone: i["c_3920a36a-1a50-4803-b762-802dcde94b32"],
                    cell: i["c_34c606c7-d902-4a70-8911-1e4a4bf17980"],
                    fax: i["c_5502d1bb-ce73-49a2-9cd1-d1539f85c2e6"],
                    email: i["c_39fe44d4-7d18-4b6b-8bea-2dfafa3b1c27"],
                    accountNumber: i["c_3a0eadbd-22f9-477d-b88e-a4cc4fe49d22"],
                    taxId: i["c_3fea0ca3-cc8d-40d5-9cf0-e7962b384c91"],
                    premiseId: i["c_ac18513f-b1ad-421a-b4ab-3ac33e4afa0d"],
                    meterid: i["c_c16e7484-2c57-40fa-a911-f3f33db44b66"],
                }));
        }
    }

    const pagesCount = pageSize <= 0 ? 0 : Math.ceil(totalRecords / pageSize);

    return [contacts, isLoading, error, pagesCount, mutate];
};

export const useCustomerContactsV2 = (
    page: number,
    pageSize: number
): [
    contacts: ApplicationContact[] | undefined,
    isLoading: boolean,
    error: any,
    totalRecords: number,
    pagesCount: number,
    mutate: KeyedMutator<any>
] => {
    const baseUrl = pageSize > 0 ? process.env.REACT_APP_CUSTOMER_CONTACTS_ENDPOINT : null;

    let url = null;
    if (baseUrl) {
        const query = new URLSearchParams();
        query.append("pageNum", String(page));
        query.append("recsPerPage", String(pageSize));
        query.append("timeOffset", String(getBrowserTimezoneOffset()));

        url = baseUrl + "?" + query.toString();
    }

    const { data, error, mutate } = useSWR(url, httpGetAuthorized);
    const isLoading = Boolean(!error && !data && url !== null);

    let contacts: ApplicationContact[] | undefined = undefined;
    let totalRecords = 0;

    if (data?.grid) {
        const rows = toArray(data?.grid.rows);
        if (rows?.length > 0) {
            totalRecords = Number(rows[0]["totRecords"]);
            contacts = rows
                .map((i: any) => omit(i, ["MoreRecords", "totRecords"]))
                .map((i: any) => ({
                    contactType: i["c_0583dc7f-2d25-43b4-a2fa-eb51b7f6bccf"],
                    contactNumber: i["c_280b0954-4e11-4eb9-b739-9496c1275aae"],
                    contactTitle: i["c_9bae6a22-2da8-4d9c-a1d4-4f2ef7dae842"],
                    firstName: i["c_864bdda4-1bf6-4689-ba07-35a33ce67e1a"],
                    lastName: i["c_f61773e2-39de-44a2-ae20-201a7a4e5c82"],
                    company: i["c_de6110ba-15c9-4c31-a180-1c4a789ff2b0"],
                    address: i["c_38c90d47-1195-4652-8c39-0ba44fcc5b91"],
                    addressCont: i["c_27a26f5a-3474-4a46-a75c-0d9ce13adda0"],
                    city: i["c_2fdfee48-f62b-4766-b542-62ee2f2e2a44"],
                    state: i["c_01ab19aa-e2f8-4816-96f8-9b34913f2326"],
                    zip: i["c_1d03498f-d65e-433d-99ae-715ace3ddb9d"],
                    phone: i["c_3920a36a-1a50-4803-b762-802dcde94b32"],
                    cell: i["c_34c606c7-d902-4a70-8911-1e4a4bf17980"],
                    fax: i["c_5502d1bb-ce73-49a2-9cd1-d1539f85c2e6"],
                    email: i["c_39fe44d4-7d18-4b6b-8bea-2dfafa3b1c27"],
                    accountNumber: i["c_3a0eadbd-22f9-477d-b88e-a4cc4fe49d22"],
                    taxId: i["c_3fea0ca3-cc8d-40d5-9cf0-e7962b384c91"],
                    premiseId: i["c_ac18513f-b1ad-421a-b4ab-3ac33e4afa0d"],
                    meterId: i["c_c16e7484-2c57-40fa-a911-f3f33db44b66"],
                }));
        }
    }

    const pagesCount = pageSize <= 0 ? 0 : Math.ceil(totalRecords / pageSize);

    return [contacts, isLoading, error, totalRecords, pagesCount, mutate];
};
