import { toArray } from "components/utils/array";
import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWRImmutable from "swr/immutable";
import { ApprovedEquipmentDetailsModel } from "./types";

export const useApprovedEquipmentDetails = (
    industryMeasureNumber: string | undefined,
    rowId: number | undefined
): [approvedEquipmentDetails: ApprovedEquipmentDetailsModel | undefined, isLoading: boolean, error: any] => {
    const url =
        industryMeasureNumber && rowId
            ? getUrl(process.env.REACT_APP_APPROVED_EQUIPMENT_DETAILS_ENDPOINT, { industryMeasureNumber, rowId })
            : null;

    const { data, error, isValidating } = useSWRImmutable(url, httpGetAuthorized, {
        revalidateOnMount: true,
    });
    const isLoading = isValidating || (!error && !data && url !== null);

    const equipmentDetails = data?.approvedMeasure
        ? {
              industryMeasureNumber,
              rowId,
              attributes: toArray(data?.approvedMeasure.attributeItem),
          }
        : data;

    // Case with valid empty response
    if (equipmentDetails?.xmloutput === null) {
        return [
            undefined,
            isLoading,
            {
                message: "Approved equipment not found",
            },
        ];
    }

    return [equipmentDetails, isLoading, error];
};
