import { Link } from "react-router-dom";
import useWidgetStyles, { useMarginStyles, usePaddingStyles } from "components/utils/useWidgetStyles";
import classNames from "classnames";
import { NavigationLink } from "types/NavigationLink";
import { WidgetStylesProps } from "types/WidgetStylesProps";
import { getExternalLink, isInternalLink } from "components/utils/links";
import { ExternalLink } from "components/ExternalLink";
import { Icon } from "components/Icon";
import { ExternalLinkIcon } from "components/Icon/ExternalLinkIcon";
import { SocialLinkIcon } from "types/SocialLinkIcon";
import { IconName } from "@fortawesome/fontawesome-svg-core";

export function NavigationWidget(props: NavigationWidgetProps) {
    const { layout } = props;
    const links = getLinks(props);

    const listStyle = useWidgetStyles({
        maxHeight: props.maxHeight,
    });

    const linkStyle = useWidgetStyles({
        textColor: props.textColor,
        ...usePaddingStyles(props),
        ...useMarginStyles(props),
    });

    return (
        <nav className={"navigation-widget"} aria-label={props.ariaLabel}>
            <ul className={`navbar-nav navigation-widget-list flex-${layout} flex-wrap ps-0 mb-0`} style={listStyle}>
                {links.map((link, index) => (
                    <li key={index} className={"p-1"}>
                        {isInternalLink(link.url) ? (
                            <Link
                                className={classNames("d-inline-block", {
                                    "text-decoration-none": link.underline === false,
                                })}
                                to={link.url}
                                target={link.target}
                                style={linkStyle}
                            >
                                {link.icon && (
                                    <Icon className="me-2" title={link.text || link.altText} icon={link.icon} iconVersion={props.version} />
                                )}
                                {link.text}
                                {link.target === "_blank" && <ExternalLinkIcon />}
                            </Link>
                        ) : (
                            <ExternalLink
                                className={classNames("d-inline-block", {
                                    "text-decoration-none": link.underline === false,
                                })}
                                href={getExternalLink(link.url)}
                                openInNewTab={link.target === "_blank"}
                                style={linkStyle}
                            >
                                {link.icon && (
                                    <Icon className="me-2" title={link.text || link.altText} icon={link.icon} iconVersion={props.version} />
                                )}
                                {link.text}
                            </ExternalLink>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
}

const getLinks = (props: NavigationWidgetProps) => {
    let links: NavigationLink[] = [];
    const linkIndexes: LinkIndex[] = [1, 2, 3, 4, 5, 6, 7, 8];

    const getLink = (index: LinkIndex): NavigationLink => {
        return {
            text: props[`link${index}Text`],
            altText: props[`link${index}AltText`],
            icon: props[`link${index}Icon`] as SocialLinkIcon | IconName,
            url: props[`link${index}Url`],
            target: props[`link${index}Target`],
            underline: props.underline,
        };
    };

    linkIndexes.forEach((i) => {
        if (props[`link${i}Url`]) {
            const link = getLink(i);

            links.push(link);
        }
    });

    return links;
};

type LinkIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

interface NavigationWidgetProps extends WidgetStylesProps {
    /**
     * Widget version
     */
    version?: number;
    /**
     * Navigation link widget description. Needed for accessibility.
     */
    ariaLabel: string;

    /**
     * Horizontal or vertical layout.
     */
    layout: "row" | "column";

    /**
     * Link text color. Uses global link color if not provided.
     */
    textColor?: string;

    /**
     * Link underline. Default value "true"
     */
    underline?: boolean;

    /**
     * Link text
     */
    link1Text: string;

    /**
     * Link alt text. Text or AltText should be provided.
     */
    link1AltText?: string;

    /**
     * Link icon.
     */
    link1Icon?: SocialLinkIcon | IconName;

    /**
     * Link url
     */
    link1Url: string;

    /**
     * Link target.
     */
    link1Target?: React.HTMLAttributeAnchorTarget;

    link2Text?: string;
    link2AltText?: string;
    link2Icon?: SocialLinkIcon | IconName;
    link2Url: string;
    link2Target?: React.HTMLAttributeAnchorTarget;

    link3Text?: string;
    link3AltText?: string;
    link3Icon?: string;
    link3Url: string;
    link3Target?: React.HTMLAttributeAnchorTarget;

    link4Text?: string;
    link4AltText?: string;
    link4Icon?: SocialLinkIcon | IconName;
    link4Url: string;
    link4Target?: React.HTMLAttributeAnchorTarget;

    link5Text?: string;
    link5AltText?: string;
    link5Icon?: SocialLinkIcon | IconName;
    link5Url: string;
    link5Target?: React.HTMLAttributeAnchorTarget;

    link6Text?: string;
    link6AltText?: string;
    link6Icon?: SocialLinkIcon | IconName;
    link6Url: string;
    link6Target?: React.HTMLAttributeAnchorTarget;

    link7Text?: string;
    link7AltText?: string;
    link7Icon?: SocialLinkIcon | IconName;
    link7Url: string;
    link7Target?: React.HTMLAttributeAnchorTarget;

    link8Text?: string;
    link8AltText?: string;
    link8Icon?: SocialLinkIcon | IconName;
    link8Url: string;
    link8Target?: React.HTMLAttributeAnchorTarget;
}
