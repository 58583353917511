export enum LeadImageContentType {
    Title = "title",
    TitleButton = "titleButton",
    TitleText = "titleText",
    TitleTextButton = "titleTextButton",
}

export enum TextColor {
    Black = "black",
    White = "white",
}

export enum AlignYClasses {
    top = "justify-content-start",
    center = "justify-content-center",
    bottom = "justify-content-end",
}

export enum AlignXClasses {
    left = "align-items-start text-start",
    center = "align-items-center text-center",
    right = "align-items-end text-end",
}
