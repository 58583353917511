import { toArray } from "components/utils/array";
import { getUrl, httpGetAuthorized } from "components/utils/http";
import { isEmpty, omit } from "lodash";
import { useState, useEffect, useMemo } from "react";
import useSWR from "swr";
import { ApprovedEquipmentSearchFilter } from "./types";

export const useApprovedEquipmentSearch = (
    applicationNumber: string | undefined,
    searchFilter: ApprovedEquipmentSearchFilter,
    pageNumber: number,
    pageSize: number,
    searchAttr: string = "",
    sortBy: string = "",
    sortAsc: boolean = true
): [searchResults: ApprovedEquipmentSearchResponse | undefined, isLoading: boolean, error: any] => {
    const [response, setResponse] = useState<any>();
    const baseUrl = applicationNumber ? getUrl(process.env.REACT_APP_APPROVED_EQUIPMENT_SEARCH_ENDPOINT, { applicationNumber }) : null;

    let url = null;
    if (baseUrl) {
        const query = new URLSearchParams();
        query.append("pageNum", String(pageNumber));
        query.append("recsPerPage", String(pageSize));

        // Search form entries
        Object.entries(searchFilter).forEach(([key, value]) => {
            if (value) {
                query.append(key, value);
            }
        });

        // Grid filters
        query.append("searchAttr", searchAttr);

        if (!isEmpty(sortBy)) {
            query.append("sortBy", sortBy);
            query.append("sortAsc", sortAsc ? "1" : "0");
        }

        url = baseUrl + "?" + query.toString();
    }

    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data && url !== null;

    // Update data only when not loading anymore. Prevents grid flicker.
    useEffect(() => {
        if (!isLoading) {
            setResponse(data);
        }
    }, [data, isLoading]);

    const result = useMemo(() => {
        let result: ApprovedEquipmentSearchResponse | undefined = undefined;

        if (response) {
            const gridOutput = toArray(response)[0]?.gridOutput;
            if (gridOutput) {
                const gridData = JSON.parse(gridOutput);
                if (gridData?.length > 0) {
                    const items = gridData.map((i: any) => omit(i, ["morerecords", "totrecords"]));
                    const totalRecords = Number(gridData[0]["totrecords"]);
                    const remainingRecords = Number(gridData[0]["morerecords"]);

                    result = {
                        items,
                        totalRecords,
                        remainingRecords,
                    };
                }
            }
        }

        return result;
    }, [response]);

    return [result, isLoading, error];
};

export interface ApprovedEquipmentSearchResponse {
    totalRecords: number;
    remainingRecords: number;
    items: ApprovedEquipmentSearchResult[];
}

export interface ApprovedEquipmentSearchResult {
    [key: string]: string;
}
