import { Container } from "components/Layout/Container";
import { Link } from "react-router-dom";

export const PrivacyPolicyCCPAPage = () => {
    return (
        <Container className="p-0">
            <h1 className="fs-6 mb-3">
                <b>CALIFORNIA PRIVACY NOTICE</b>
            </h1>
            <p>Date Last Updated: May 31, 2023</p>
            <p>
                If you are a California resident, the California Consumer Privacy Act and the California Privacy Rights Act (collectively,
                the “CCPA”) provide you with certain rights regarding your personal information, including the right to know about personal
                information collected, disclosed, or sold; the right to request that we delete personal information that we have collected
                from you; the right to update or correct your information; the right to opt-out of the sale or sharing of your personal
                information; the right to limit our use of your sensitive personal information; and the right not to be discriminated
                against for exercising your rights. These rights are subject to certain exceptions. You may also visit our{" "}
                <Link to="/aeg-privacy-policy">Privacy Policy</Link> to learn more about how we protect your information.
            </p>
            <p>
                Any capitalized terms in this California Privacy Notice that are not otherwise defined have the same meaning as they do in
                the CCPA or in our Privacy Policy. This California Privacy Notice also serves as our “Notice at Collection” under California
                law.
            </p>
            <p>
                Please note we generally process personal information in our role as a “service provider” to the utilities that you have a
                direct relationship with. You may need to contact the utility directly to learn more about the processing of your personal
                information.
            </p>

            <h2 className="fs-6 mb-3 fw-normal lh-base">
                <u>Collection of Personal Information </u>
            </h2>
            <p>
                If you visit or interact with the Portals, we may collect information that identifies, relates to, describes, is reasonably
                capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or
                household (“personal information”). We may have collected the following categories of personal information about you over
                the past 12 months:
            </p>
            <ul>
                <li>Identifiers, such as your name, email address, postal address, utility account number, and IP address.</li>
                <li>
                    Categories of personal information described in subdivision (e) of California Civil Code Section 1798.80, such as your
                    name, address, and phone number.
                </li>
                <li>Commercial information, such as your transaction history.</li>
                <li>Geolocation information.</li>
                <li>
                    Internet or other electronic network activity information, such as your cookies, web logs, pixels, and IP addresses.
                </li>
            </ul>

            <p>We collect the personal information listed above from the following sources:</p>
            <ul>
                <li>From you directly, when you provide it to us or when we collect it </li>

                <li>Automatically from your browser or device or from our Portals.</li>
            </ul>

            <h2 className="fs-6 mb-3 fw-normal lh-base">
                <u>Disclosure of Personal Information</u>
            </h2>
            <p>
                In the last 12 months, we may have disclosed for our business purposes the following categories of personal information
                subject to the CCPA:
            </p>
            <ul>
                <li> Identifiers, such as your name, email address, postal address, utility account number, and IP address.</li>
                <li>
                    Categories of personal information described in subdivision (e) of California Civil Code Section 1798.80, such as your
                    name, address, and phone number.
                </li>
                <li> Commercial information, such as your transaction history</li>
                <li> Geolocation information</li>
                <li>
                    Internet or other electronic network activity information, such as your cookies, web logs, pixels, and IP addresses.
                </li>
            </ul>

            <p>We may have disclosed or shared any of the information listed above with: </p>
            <ul>
                <li> Our service providers, contractors, processors, and vendors.</li>
                <li> Other third parties for a legal purpose (such as in response to a court order or to defend against a legal claim).</li>
                <li>
                    Other third parties when necessary to investigate, prevent, or take action regarding illegal activities, suspected
                    fraud, violations of our terms and conditions, or situations involving threats to our property or the property or
                    physical safety of any person or third party.
                </li>
                <li>
                    Other third parties in the possible event of a merger, acquisition, purchase, sale, or disposal of all or part of our
                    business or assets.
                </li>
            </ul>

            <h2 className="fs-6 mb-3 fw-normal lh-base">
                <u>Purposes for Collection and Disclosure of Personal Information</u>
            </h2>
            <p>We collect, disclose, sell, or share your personal information to the entities listed above for the following purposes: </p>
            <ul>
                <li> Communicate with you,</li>
                <li> Process an application,</li>
                <li> Contact you via surveys to conduct research,</li>
                <li> Register you for a service or program,</li>
                <li> Send you direct mail, email, SMS text message or telephone contact from us,</li>
                <li> Comply with a state public utilities commission request or order, or</li>
                <li>
                    Disclose your Personal Information as required by law and when we believe that disclosure is necessary to protect our
                    rights, or the rights of others, or to comply with a judicial proceeding, court order, or legal process served on our
                    Portals.
                </li>
            </ul>

            <h2 className="fs-6 mb-3 fw-normal lh-base">
                <u>Your Rights </u>
            </h2>
            <p>
                If we maintain personal information about you that is subject to the CCPA, you may exercise certain rights in connection
                with this data if you are a California resident. Please note that, to the extent that we process personal information in our
                role as a service provider to the utility you are connected with, you may need to contact the utility directly to exercise
                your rights.
            </p>

            <h3 className="fs-6 mb-3 fw-normal lh-base">
                <i>Right to Know</i>
            </h3>
            <p>
                California residents have the right to request that we disclose certain information about our collection and use of your
                personal information over the past twelve months (“Right to Know”). Once we receive and confirm your verifiable consumer
                request, we will disclose to you:
            </p>
            <ul>
                <li> The categories of personal information we collected about you. </li>
                <li> The categories of sources for the personal information we collected about you.</li>
                <li> The categories of personal information that we have disclosed for a business or commercial purpose.</li>
                <li> Our business or commercial purposes for collecting, selling, or sharing personal information.</li>
                <li> The categories of third parties with whom we share or disclose that personal information.</li>
                <li> The specific pieces of personal information we collected about you (i.e., a data portability request).</li>
            </ul>

            <h3 className="fs-6 mb-3 fw-normal lh-base">
                <i>Right to Delete</i>
            </h3>
            <p>
                California residents have the right to request that we delete the personal information that we collected from you and
                retain, subject to certain exceptions (“Right to Delete”). Once we receive and verify your request, we will delete (and
                direct our service providers/contractors to delete) your personal information from our records, unless an exception applies.
            </p>
            <h3 className="fs-6 mb-3 fw-normal lh-base">
                <i>Right to Correct</i>
            </h3>
            <p>
                California residents have the right to correct or amend the personal information we have on file (“Right to Correct”). You
                may correct or amend by logging into your account or by contacting us using the information below.
            </p>
            <h3 className="fs-6 mb-3 fw-normal lh-base">
                <i>Right to Opt-Out of Sale or Sharing</i>
            </h3>
            <p>
                The CCPA provides California residents with the right to opt-out of the “sale” or “sharing” of their personal information
                (“Right to Opt Out”). We do not sell or share personal information, including the personal information of children under the
                age of 16.
            </p>
            <h3 className="fs-6 mb-3 fw-normal lh-base">
                <i>Right to Limit the Use of Your Sensitive Personal Information</i>
            </h3>
            <p>
                The CCPA provides California residents with the right to limit the use of their “sensitive personal information” for the
                purposes outlined in 11 CCR § 7027(m) of the CCPA regulations. We do not use your sensitive personal information for any
                purposes that fall outside of 11 CCR § 7027(m).
            </p>

            <h3 className="fs-6 mb-3 fw-normal lh-base">
                <i>Right to Nondiscrimination</i>
            </h3>
            <p>
                The CCPA provides California residents with the right not to receive discriminatory treatment for the exercise of their
                privacy rights conferred by the CCPA. We will not discriminate against you for exercising any of your CCPA rights.
            </p>
            <h2 className="fs-6 mb-3 fw-normal lh-base">
                <u>Exercising Your Rights</u>
            </h2>
            <p>
                If you are a California resident and wish to exercise any of the rights listed above, you may submit a request to us by{" "}
                <a href="mailto:info@appliedenergygroup.com">info@appliedenergygroup.com</a>.
            </p>
            <p>
                When you submit a request, we will confirm your identity by asking you to provide information associated with your account.
                We will verify your identity by matching the information you provide with what we have on file for you. We may ask you for
                additional information to verify your identity or to comply with your request.
            </p>
            <h3 className="fs-6 mb-3 fw-normal lh-base">
                <i>Authorized Agent</i>
            </h3>
            <p>
                You may authorize an agent to make a request on your behalf. To designate an agent, please provide a written and signed
                document by both you and the agent that authorizes the agent to act on your behalf. You may also use a power of attorney. We
                will still require you to provide information to allow us to reasonably verify that you are the person about whom we
                collected personal information.
            </p>
            <h2 className="fs-6 mb-3 fw-normal lh-base">
                <u>Data Retention</u>
            </h2>
            <p>
                We retain personal information about you necessary to fulfill the purpose for which that information was collected or as
                required or permitted by law. We do not retain personal information longer than is necessary for us to achieve the purposes
                for which we collected it. When we destroy your personal information, we do so in a way that prevents that information from
                being restored or reconstructed.
            </p>
            <h2 className="fs-6 mb-3 fw-normal lh-base">
                <u>Shine the Light Law</u>
            </h2>
            <p>
                In addition to the CCPA, California's "Shine the Light" law, Civil Code section 1798.83, requires certain businesses to
                respond to requests from California customers asking about the businesses' practices related to disclosing personal
                information to third parties for the third parties' direct marketing purposes. We do not disclose your personal information
                to third parties for these purposes.
            </p>
            <h2 className="fs-6 mb-3 fw-normal lh-base">
                <u>Contact for More Information </u>
            </h2>
            <p>
                If you wish to contact us regarding this California Privacy Notice, you may do so by email at{" "}
                <a href="mailto:info@appliedenergygroup.com">info@appliedenergygroup.com</a>.
            </p>
        </Container>
    );
};
