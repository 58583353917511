import { FieldProps } from "@rjsf/core";
import { Heading } from "components/Heading";

export interface TitleFieldProps extends Partial<FieldProps> {
    title: string;
}

const TitleField = ({ title, headingLevel }: Partial<FieldProps>) => (
    <div className="my-1">
        <Heading level={headingLevel}>{title}</Heading>
        <hr className="text-body" style={{ height: "1px" }} />
    </div>
);

export default TitleField;
