import axe, { Result, AxeResults } from "axe-core";
import { Message } from "html_codesniffer";
import { isNil } from "lodash";
import {
    AccessibilityValidationMessage,
    ValidationStandard,
    ValidationMessageImpact,
    ValidationMessageNode,
    Validator,
} from "types/validation";

export const REQUIRED_FIELD_ERROR_TEXT = "is a required field";

export const REQUIRED_LAST_AND_FIRST_OR_CONTACT = "First Name and Last Name or Company Name is Required";

export function isNumeric(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

/**
 * Check if string is null or contains only whitespace chars
 *
 */
export function isNullOrWhitespace(value: any): boolean {
    return String(value ?? "").trim().length === 0;
}

/**
 * Run accessibility validation on currently open page.
 *
 * @param {boolean} loggingEnabled Print log messages to console if enabled.
 * @returns {Promise<AccessibilityValidationMessage[]>} promise with list of validation messages.
 */
export const validateActivePage = async (loggingEnabled: boolean): Promise<AccessibilityValidationMessage[]> => {
    if (loggingEnabled) {
        console.log("Validating portal:", document.location.toString());
    }

    let results: AccessibilityValidationMessage[] = [];

    try {
        const axeResults = await axeValidatorRun(loggingEnabled);
        const htmlcsResultsWCAG2AA = await htmlcsValidatorRun(ValidationStandard.WCAG2AA, loggingEnabled);

        results = results.concat(axeResults, htmlcsResultsWCAG2AA);
    } catch (error) {
        results.push({
            id: "accessibility.exception",
            message: `Portal validator failed to run`,
            impact: ValidationMessageImpact.Error,
            description: String(error),
            nodes: [],
            url: window.location.toString(),
        });
    }

    if (loggingEnabled) {
        console.log("Validation done. Results:", results);
    }

    return results;
};

/**
 * Run axe validator.
 *
 * @param {boolean} loggingEnabled Print log messages to console if enabled.
 * @returns {Promise<AccessibilityValidationMessage[]>} promise with list of validation messages.
 */
const axeValidatorRun = async (loggingEnabled: boolean): Promise<AccessibilityValidationMessage[]> => {
    const axe = await import("axe-core");
    const results = await axe.run({
        // Exclude Googlemaps container from validation.
        exclude: [[".map-container"]],
    });

    if (loggingEnabled) {
        console.log("axe results:", results);
    }

    return formatAxeResults(results);
};

/**
 * Run htmlcs validator against provided accessibility standard.
 *
 * @param standard - The name of the standard to use.
 * @param {boolean} loggingEnabled Print log messages to console if enabled.
 * @returns promise with list of validation messages.
 */
const htmlcsValidatorRun = async (standard: ValidationStandard, loggingEnabled: boolean): Promise<AccessibilityValidationMessage[]> => {
    const cssGenerator = await import("css-selector-generator");

    if (!window.HTMLCS) {
        const HTMLCS = await import("html_codesniffer/build/HTMLCS");
        Object.assign(window, HTMLCS.default);
    }

    return new Promise((resolve) => {
        // Add attribute to google maps container to exclude it from validation.
        const googleMapsContainer = document.querySelector(".map-container");
        if (googleMapsContainer) {
            googleMapsContainer.setAttribute("aria-hidden", "true");
        }

        // Function to clear google maps container attribute.
        const clearGoogleMapsContainer = () => {
            if (googleMapsContainer) {
                googleMapsContainer.removeAttribute("aria-hidden");
            }
        };

        window.HTMLCS.process(
            standard,
            document.documentElement,
            () => {
                const results = window.HTMLCS.getMessages();

                if (loggingEnabled) {
                    console.log(`htmlcs results ${standard}`, results);
                }

                clearGoogleMapsContainer();
                resolve(formatHtmlcsResults(results, cssGenerator));
            },
            () => {
                clearGoogleMapsContainer();
                resolve([
                    {
                        id: "",
                        message: `HTMLCS validator failed to process the standard ${standard}`,
                        impact: ValidationMessageImpact.Error,
                        description: "",
                        nodes: [],
                        validator: Validator.Htmlcs,
                        url: window.location.toString(),
                    },
                ]);
            },
            "en"
        );
    });
};

/**
 * Convert axe validation message to AccessibilityValidationMessage.
 * @param messages list of axe validation messages.
 * @returns list of formatted messages.
 */
const formatAxeResults = (axeResults: AxeResults): AccessibilityValidationMessage[] => {
    const messages = [
        axeResults.violations,
        // Change impact of incomplete results to not show false errors.
        axeResults.incomplete.map<Result>((r) => ({
            ...r,
            impact: ["serious", "critical"].includes(String(r.impact)) ? "moderate" : r.impact,
        })),
    ].flatMap((a) => a);

    const formatMessage = (message: axe.Result): AccessibilityValidationMessage => {
        return {
            id: message.id,
            message: message.help,
            impact: getAxeImpact(message),
            description: message.description,
            nodes: getAxeNodes(message),
            validator: Validator.AxeCore,
            originalMessage: message,
            url: window.location.toString(),
        };
    };

    return (
        messages
            // Filter out warnings about iframe content not tested
            .filter((m) => m.id !== "frame-tested")
            .map((m) => formatMessage(m))
    );
};

/**
 * Convert htmlcs validation message to AccessibilityValidationMessage.
 * @param messages list of htmlcs validation messages.
 * @param cssGenerator css generator library.
 * @returns list of formatted messages.
 */
const formatHtmlcsResults = (
    messages: Message[],
    cssGenerator: typeof import("css-selector-generator")
): AccessibilityValidationMessage[] => {
    return messages
        .map((message: Message) => ({
            id: message.code,
            message: message.msg,
            impact: getHtmlcsImpact(message),
            description: "",
            nodes: getHtmlcsNodes(message, cssGenerator),
            validator: Validator.Htmlcs,
            originalMessage: {
                ...message,
                element: cssGenerator.getCssSelector(message?.element),
            },
            url: window.location.toString(),
        }))
        .filter((m) => {
            const nodeSelectors = m.nodes.map((n) => n.selector);

            // Filter out hidden recaptcha iframe error.
            if (
                m.id === "WCAG2AA.Principle2.Guideline2_4.2_4_1.H64.1" &&
                nodeSelectors.every((p) => p?.toString().startsWith("[style='display\\:\\ none\\;']"))
            ) {
                return false;
            }

            // Filter out recaptcha badge errors.
            if (nodeSelectors.every((p) => p?.toString().startsWith("#g-recaptcha"))) {
                return false;
            }

            // Filter out toast container fixed position warning
            if (
                m.id === "WCAG2AA.Principle1.Guideline1_4.1_4_10.C32,C31,C33,C38,SCR34,G206" &&
                nodeSelectors.every((p) => p?.toString().startsWith(".toast-container"))
            ) {
                return false;
            }

            // Filter out errors on elements with class "visually-hidden"
            if (nodeSelectors.every((p) => p?.includes(".visually-hidden")) && m.impact === ValidationMessageImpact.Warning) {
                return false;
            }

            // Remove warnings about not selected value in select element
            if (m.id === "WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.Select.Value" && m.impact === ValidationMessageImpact.Warning) {
                return false;
            }

            // Remove warnings about grouping select options
            if (m.id === "WCAG2AA.Principle1.Guideline1_3.1_3_1.H85.2" && m.impact === ValidationMessageImpact.Warning) {
                return false;
            }

            // Remove caption transparency warning
            if (
                m.id === "WCAG2AA.Principle1.Guideline1_4.1_4_3.G18.Alpha" &&
                nodeSelectors.every((p) => p?.includes("caption")) &&
                m.impact === ValidationMessageImpact.Warning
            ) {
                return false;
            }

            return true;
        });
};

const getHtmlcsImpact = (message: Message) => {
    let impact = ValidationMessageImpact.Unknown;
    switch (message.type) {
        case 1:
            impact = ValidationMessageImpact.Error;
            break;
        case 2:
            impact = ValidationMessageImpact.Warning;
            break;
        case 3:
            impact = ValidationMessageImpact.Notice;
            break;
        default:
            break;
    }

    return impact;
};

const getAxeImpact = (message: axe.Result) => {
    let impact = ValidationMessageImpact.Unknown;
    switch (message.impact) {
        case "critical":
        case "serious":
            impact = ValidationMessageImpact.Error;
            break;
        case "moderate":
            impact = ValidationMessageImpact.Warning;
            break;
        case "minor":
            impact = ValidationMessageImpact.Notice;
            break;
        default:
            break;
    }

    return impact;
};

const getAxeNodes = (message: axe.Result): ValidationMessageNode[] => {
    return message.nodes
        .filter((nodeResult) => !isNil(nodeResult))
        .map((nodeResult) => {
            return {
                selector: nodeResult.target[0],
                innerHtml: nodeResult.html,
            };
        });
};

const getHtmlcsNodes = (message: Message, cssGenerator: typeof import("css-selector-generator")): ValidationMessageNode[] => {
    return [
        {
            selector: cssGenerator.getCssSelector(message?.element),
            innerHtml: message.element?.innerHTML,
        },
    ];
};
/**
 * Checks whether a string matches an email pattern.
 */

export const isEmail = (value: string) => {
    const emailEx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailRegEx = new RegExp(emailEx);

    return emailRegEx.test(value);
};

export const isPhoneNumber = (value: string) => {
    const phoneNumberEx = /^([+]1\s?)?(\d{3}|\(\d{3}\))[\s-]?\d{3}[\s-]?\d{4}$/;
    const phoneNumberRegEx = new RegExp(phoneNumberEx);

    return phoneNumberRegEx.test(value);
};

/**
 * Checks whether a string matches a US or CA postal code pattern.
 */
export const isPostalCode = (value: string) => {
    // Do not validate if value is empty.
    if (isNullOrWhitespace(value)) {
        return true;
    }

    const usZipCodePattern = /^\d{5}(?:[-]\d{4})?$/;
    const canadaPostalCodePattern = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;

    const trimmedValue = value.trim();
    const isUSZipCode = usZipCodePattern.test(trimmedValue);
    const isCanadaPostalCode = canadaPostalCodePattern.test(trimmedValue);

    return isUSZipCode || isCanadaPostalCode;
};
