import cn from "classnames";

interface PlaceholderProps {
    placeholderImage: JSX.Element;
    title: string;
    description?: string;
    className?: string;
}

export const EmptyStatePlaceholder = ({ placeholderImage, title, description, className }: PlaceholderProps) => {
    return (
        <div className={cn(className, "app-empty-state d-flex flex-column align-items-center m-auto justify-content-center")}>
            {placeholderImage}
            <div className="d-flex flex-column text-center">
                <h3 className="text-body fw-semi-bold m-0 pb-1">{title}</h3>
                {!!description && <p className="fw-light m-0 lead">{description}</p>}
            </div>
        </div>
    );
};
