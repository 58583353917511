import { EquipmentCategory } from "./types";
import { ListGroup } from "react-bootstrap";

export const Categories = ({ items, onClick }: CategoriesProps) => {
    return (
        <ListGroup>
            {items.map((category, index) => (
                <ListGroup.Item key={index} action onClick={() => onClick(category)}>
                    {category.category}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
};
interface CategoriesProps {
    items: EquipmentCategory[];
    onClick: (category: EquipmentCategory) => void;
}
