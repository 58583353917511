import { AppContext } from "components/App/AppContext";
import { PageLink } from "components/App/utils";
import { Heading } from "components/Heading";
import { JsonForm } from "components/JsonForm";
import { Container } from "components/Layout/Container";
import { customerLogin, customerTwoFactor } from "components/utils";
import { usePageTopAndBottomRows } from "components/utils/page";
import { isEmpty } from "lodash";
import { useState, useContext } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { PageComponentsList } from ".";

import "./SignInPage.scss";

export function SignInPage() {
    const history = useHistory();
    const { programNumber } = useContext(AppContext);
    const [extraErrors, setExtraErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [requireTwoFactor, setRequireTwoFactor] = useState(false);
    const location = useLocation<{ referrer: string }>();
    const [initialLogin, setInitialLogin] = useState(true);
    const { topRows, bottomRows } = usePageTopAndBottomRows();

    const onInitialSubmit = (data: any) => {
        const { formData } = data;
        setExtraErrors({});
        setIsSubmitting(true);

        async function isTwoFactorRequired(userName: string) {
            customerTwoFactor(userName, programNumber)
                .then((response) => {
                    if (response.twoFactorRequired) {
                        setRequireTwoFactor(true);
                    }
                    setInitialLogin(false);
                })
                .catch(() => {
                    setExtraErrors({
                        userName: {
                            __errors: ["The username you entered cannot be found."],
                        },
                    });
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }

        isTwoFactorRequired(formData?.userName);
    };

    const onFinalSubmit = (data: any) => {
        const { formData } = data;
        setExtraErrors({});
        setIsSubmitting(true);

        customerLogin(formData)
            .then(() => {
                if (!isEmpty(location?.state?.referrer)) {
                    history.push(location.state.referrer);
                } else {
                    history.push(PageLink.CustomerHome);
                }
            })
            .catch(() => {
                setIsSubmitting(false);

                const message = requireTwoFactor
                    ? "The password or authorization code you entered is not correct."
                    : "The username/password combination you entered cannot be found";

                setExtraErrors({
                    password: {
                        __errors: [message],
                    },
                    authCode: {
                        __errors: [message],
                    },
                });
            });
    };

    return (
        <>
            <Container className="p-0 d-flex flex-column">
                <Heading className="align-self-center">Sign in</Heading>
            </Container>
            <PageComponentsList components={topRows} />
            <Container className="sign-in-page p-0">
                <div className="standard-form mx-auto d-flex flex-column">
                    {initialLogin ? (
                        <JsonForm
                            formName="Sign in Email"
                            schema={userNameSchema}
                            uiSchema={userNameUiSchema}
                            onSubmit={onInitialSubmit}
                            extraErrors={extraErrors}
                            isSubmitting={isSubmitting}
                            submitButtonText="Continue"
                        />
                    ) : (
                        <JsonForm
                            formName="Sign in Password"
                            schema={requireTwoFactor ? twoFactorSchema : passwordSchema}
                            uiSchema={passwordUiSchema}
                            onSubmit={onFinalSubmit}
                            extraErrors={extraErrors}
                            isSubmitting={isSubmitting}
                        />
                    )}
                    <div className="sign-in-page--help-links d-flex justify-content-center mt-3">
                        <Link to={PageLink.ForgotPassword}>Forgot Password</Link>
                        <span className="separator mx-2">|</span>
                        <Link to={{ pathname: PageLink.AccountCreate, state: location?.state }}>Create Account</Link>
                    </div>
                </div>
            </Container>
            <PageComponentsList components={bottomRows} />
        </>
    );
}

const userNameSchema = {
    type: "object",
    required: ["userName"],
    properties: {
        userName: {
            type: "string",
            title: "Email Address:",
            format: "email",
        },
    },
};

const userNameUiSchema = {
    userName: {
        "ui:autofocus": true,
    },
};

const passwordSchema = {
    type: "object",
    required: ["password"],
    properties: {
        password: {
            type: "string",
            title: "Password:",
        },
    },
};

const twoFactorSchema = {
    type: "object",
    required: ["password", "authCode"],
    properties: {
        password: {
            type: "string",
            title: "Password:",
        },
        authCode: {
            type: "string",
            title: "Authorization Code:",
        },
    },
};

const passwordUiSchema = {
    password: {
        "ui:widget": "password",
        "ui:autofocus": true,
    },
};
