import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR from "swr";
import { EquipmentCatalog } from "./types";

export const useEquipmentCatalogs = (
    applicationNumber?: string,
    categoryNumber?: string
): [catalogs: EquipmentCatalog[], isLoading: boolean, error: any] => {
    const url =
        applicationNumber && categoryNumber
            ? getUrl(process.env.REACT_APP_EQUIPMENT_CATALOGS_ENDPOINT, { applicationNumber, categoryNumber })
            : null;

    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data && url !== null;

    return [data?.equipmentCatalogList, isLoading, error];
};
