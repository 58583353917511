import { useCallback, useState } from "react";
import { JsonForm } from "components/JsonForm";
import { ErrorSummary } from "components/ErrorSummary";
import { usePasswordRequirements, userChangePassword } from "components/utils";
import { useHistory } from "react-router-dom";
import { SuccessPage } from "components/Page/SuccessPage";
import { Container } from "components/Layout/Container";
import { Heading } from "components/Heading";
import { PageLink } from "components/App/utils";

export function ChangePasswordPage(props) {
    const history = useHistory();
    const submitted = history?.location?.state?.submitted === true;
    const [errorSummary, setErrorSummary] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [passwordRequirements, isLoading] = usePasswordRequirements();

    const schema = {
        type: "object",
        required: ["oldPassword", "newPassword", "repeatedPassword"],
        properties: {
            oldPassword: {
                type: "string",
                title: "Current Password",
            },
            newPassword: {
                type: "string",
                title: "New Password",
            },
            repeatedPassword: {
                type: "string",
                title: "Confirm New Password",
            },
        },
    };

    const uiSchema = {
        oldPassword: {
            "ui:widget": "password",
        },
        newPassword: {
            "ui:widget": "password",
            "ui:help": !isLoading && passwordRequirements,
        },
        repeatedPassword: {
            "ui:widget": "password",
        },
    };

    function validatePassword(formData, errors) {
        if (formData.newPassword !== formData.repeatedPassword) {
            errors.repeatedPassword.addError("Passwords don't match");
        }
        return errors;
    }

    const onSubmit = useCallback(
        async ({ formData }) => {
            try {
                setIsSubmitting(true);
                setErrorSummary(undefined);
                await userChangePassword({
                    oldPassword: formData.oldPassword,
                    newPassword: formData.newPassword,
                });
                history.replace({ pathName: PageLink.ChangePassword, state: { submitted: true } });
            } catch (error) {
                setErrorSummary(error);
            } finally {
                setIsSubmitting(false);
            }
        },
        [history]
    );

    return submitted ? (
        <SuccessPage text="Password successfully changed" />
    ) : (
        <Container className="p-0">
            <Heading>Change Password</Heading>
            <ErrorSummary errorSummary={errorSummary} />
            <JsonForm
                schema={schema}
                uiSchema={uiSchema}
                onSubmit={onSubmit}
                validate={validatePassword}
                isSubmitting={isSubmitting}
                formName="Change Password"
            />
        </Container>
    );
}
