import { formatJsonDateTime } from "components/utils/date";
import { WorkFlowTaskModel } from "components/WorkflowTasks/types";
import { Card } from "react-bootstrap";
import { isTaskCompleted } from "./utils";

export const WorkflowTask = ({ task, onClick }: WorkflowTaskProps) => {
    const isCompleted = isTaskCompleted(task);

    return (
        <Card
            className="shadow-sm"
            role={isCompleted ? undefined : "button"}
            onClick={isCompleted ? undefined : () => onClick(task)}
            onKeyDown={isCompleted ? undefined : (e) => e.key === "Enter" && onClick(task)}
            tabIndex={0}
        >
            <Card.Body>
                <Card.Title>{task.stepType}</Card.Title>
                <Card.Text>{task.step}</Card.Text>
            </Card.Body>
            {isCompleted && (
                <Card.Footer className="bg-success text-white">
                    Completed{task.dateCompleted && `: ${formatJsonDateTime(task.dateCompleted)}`}
                </Card.Footer>
            )}
        </Card>
    );
};

interface WorkflowTaskProps {
    task: WorkFlowTaskModel;
    onClick: (task: WorkFlowTaskModel) => void;
}
