import { Form } from "react-bootstrap";
import { useLocation } from "react-router";
import { SubmittedAppData } from "types/SubmittedAppData";

export const FormsHeader = ({ appData }: { appData: SubmittedAppData }) => {
    const forms = appData.applicationForms ?? [];
    const showSideNav = forms.length > 1;

    const location = useLocation();
    const currentUrl = location.pathname + location.search;

    const onLinkClick = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const target = e.target as HTMLSelectElement;
        const pageNumber = target.value;

        if (pageNumber) {
            // Find the target element
            const element = document.getElementById(pageNumber);
            if (element) {
                // Update the page URL without triggering a page reload
                window.history.pushState({}, "", `${currentUrl}#${pageNumber}`);

                // Scroll to the target element
                element.scrollIntoView();

                // Make the form section focusable so that next focusable element is within the form
                element.setAttribute("tabindex", "-1");
                element.focus();

                // Remove the tabindex
                setTimeout(() => {
                    element.removeAttribute("tabindex");
                }, 1000);
            }
        }
    };

    return (
        <div className="d-flex flex-column gap-3 mb-5">
            <div className="w-100 d-flex flex-column gap-2">
                <h2 className="m-0">Forms</h2>
                <p className="fs-7 m-0">In this page you can find information provided as part of the application.</p>
            </div>
            {showSideNav && (
                <Form.Select className="d-block d-md-none" aria-label="Content" onChange={onLinkClick} value="">
                    <option value="">Contents</option>
                    {forms.map((form) => (
                        <option key={form.pageNumber} value={form.pageNumber}>
                            {form.pageName}
                        </option>
                    ))}
                </Form.Select>
            )}
        </div>
    );
};
