import { Form } from "react-bootstrap";

export const DateInput = (props: any) => {
    // Workaround to make the date picker "reset" button to work on iOS.
    const onRefSet = (ref: any) => {
        if (ref) {
            ref.defaultValue = "";
            ref.value = props.value;
        }
    };

    const onChange = (event: { target: HTMLInputElement }) => {
        props?.onChange(event);

        // Workaround to make the date picker "reset" button to work on iOS.
        const target = event.target;
        setTimeout(() => {
            target.defaultValue = "";
        });
    };

    // @ts-ignore
    return <Form.Control ref={onRefSet} {...props} onChange={onChange} />;
};
