import cn from "classnames";
import { isInViewport } from "components/utils/dom";
import { isNil, isObject, isString } from "lodash";
import { useEffect, useMemo, useRef, isValidElement } from "react";
import { Alert } from "react-bootstrap";

export const ErrorSummary = (props) => {
    const { className, errorSummary, errorHeading } = props;

    const errorRef = useRef();

    const message = useMemo(() => {
        if (isString(errorSummary) || isValidElement(errorSummary)) {
            return errorSummary;
        } else if (isObject(errorSummary)) {
            // Take network error or backend error as a fallback.
            return errorSummary.message ?? errorSummary.responseMessage ?? errorSummary.title;
        }

        return null;
    }, [errorSummary]);

    useEffect(() => {
        const element = errorRef?.current;
        if (element) {
            if (!isInViewport(element)) {
                element.scrollIntoView();
            }

            element.focus();
        }
    }, [message]);

    if (isNil(message)) {
        return null;
    }

    return (
        <Alert variant="danger" className={cn("outline-0", className)} ref={errorRef} tabIndex={-1}>
            <Alert.Heading as="h4">{errorHeading ?? errorSummary.title ?? "There is a problem"}</Alert.Heading>
            <p>{message}</p>
        </Alert>
    );
};
