import { Container, Offcanvas, Stack } from "react-bootstrap";
import { ContractorData } from "components/utils/contacts";
import { useApplicationContractors } from "./useApplicationContractors";
import { Button } from "components/Button";
import { isEmpty, omit, trim } from "lodash";
import { useState } from "react";
import { DataGrid } from "components/DataGrid";
import { DataGridFilter } from "components/DataGrid/DataGridFilter";
import { DataGridPaging } from "components/DataGrid/DataGridPaging";
import { DataGridHead } from "components/DataGrid/DataGridHead";
import { DataGridHeader } from "components/DataGrid/DataGridHeader";
import { DataGridColumnConfig, DataGridConfig } from "components/DataGrid/types";
import { DataGridBody } from "components/DataGrid/DataGridBody";
import { getColumnSortState } from "components/DataGrid/utils";
import { DataGridFilterChips } from "components/DataGrid/DataGridFilterChips";
import { SubmitButton } from "components/Button/SubmitButton";
import { ContractorsResponse } from "./types";
import { useContractorLabel } from "./useContractorLabel";

export const ContractorSearch = ({ applicationNumber, hide, onSelect, isContractorRequired }: ContractorSearchProps) => {
    const [showList, setShowList] = useState(false);

    const contractorLabel = useContractorLabel();

    const pageSize = 20;
    const [searchAttr, setSearchAttr] = useState<string>("");
    const [sort, setSort] = useState<string>("");
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [contractorData, isLoading] = useApplicationContractors(
        showList ? applicationNumber : undefined,
        pageNumber,
        pageSize,
        searchAttr,
        sort
    );

    const isLoadingList = showList && isEmpty(contractorData) && isLoading;

    const onSortChange = (key: string) => {
        if (!isEmpty(sort)) {
            const parts = sort.split(" ");
            if (parts[0] === key) {
                if (parts[1] === "ASC") {
                    setSort(`${key} DESC`);
                } else {
                    setSort(`${key} ASC`);
                }
                return;
            }
        }

        setSort(`${key} ASC`);
    };

    const onFilterChange = (newValue: string) => {
        if (newValue !== searchAttr) {
            setSearchAttr(newValue);
            setPageNumber(1);
        }
    };

    const onSelectContractor = (contractor: ContractorData) => {
        setShowList(false);
        onSelect(contractor);
    };

    const onCancel = () => {
        setShowList(false);
        setSearchAttr("");
        setSort("");
        setPageNumber(1);
    };

    if (hide) {
        return null;
    }

    if (showList && !isLoadingList) {
        return (
            <ContractorList
                contractorData={contractorData}
                pageSize={pageSize}
                pageNumber={pageNumber}
                sort={sort}
                searchAttr={searchAttr}
                contractorLabel={contractorLabel}
                onSelectContractor={onSelectContractor}
                onSortChange={onSortChange}
                onFilterChange={onFilterChange}
                onPageChange={setPageNumber}
                onCancel={onCancel}
            />
        );
    }

    return (
        <div className="d-inline-flex flex-column">
            <SubmitButton onClick={() => setShowList(true)} isSubmitting={isLoadingList} spinnerText="Loading contractor list...">
                Search for a {contractorLabel}
            </SubmitButton>
            {isContractorRequired && <span>* Required</span>}
        </div>
    );
};

const ContractorList = ({
    contractorData,
    pageSize,
    pageNumber,
    sort,
    searchAttr,
    contractorLabel,
    onSelectContractor,
    onSortChange,
    onFilterChange,
    onPageChange,
    onCancel,
}: {
    contractorData?: ContractorsResponse;
    pageSize: number;
    pageNumber: number;
    sort: string;
    searchAttr: string;
    contractorLabel: string;
    onSelectContractor: (contractor: ContractorData) => void;
    onSortChange: (key: string) => void;
    onFilterChange: (newValue: string) => void;
    onPageChange: (pageNumber: number) => void;
    onCancel: () => void;
}) => {
    const totalRecords = contractorData?.totalRecords ?? 0;
    const pagesCount = Math.ceil(totalRecords / pageSize);

    const rows = (contractorData?.items ?? []).map((item: any, index) => ({
        data: {
            ...item,
            // Add empty space after commas to prevent long words in data grid.
            technologies: (item.technologies ?? "").split(",").map(trim).join(", "),
            services: (item.services ?? "").split(",").map(trim).join(", "),
        },
        actions: [
            {
                content: () => (
                    <Button variant="link" size="sm" onClick={() => onSelectContractor(item)}>
                        Select
                    </Button>
                ),
            },
        ],
    }));

    const sortParts = sort.split(" ");
    const columns: DataGridColumnConfig[] = customerSearchColumns.map((c) => ({
        ...c,
        sort: getColumnSortState(c, sortParts[0], sortParts[1] === "ASC"),
    }));

    // Remove word wrap classes from header.
    const headerColumns = columns.map((c) => omit(c, "className"));

    const gridConfig: DataGridConfig = {
        columns,
        pageNumber,
        pagesCount,
        pageSize,
        totalRecords,
        filter: searchAttr,
    };

    return (
        <Offcanvas
            className="contractor-list offcanvas-90"
            show
            placement="end"
            backdrop="false"
            onHide={onCancel}
            aria-labelledby="contractor-search-title"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="contractor-search-title">{contractorLabel} Search</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column p-0 border-top">
                <DataGridFilterChips
                    className="d-lg-none m-3"
                    gridConfig={gridConfig}
                    buttonTitle="Filter"
                    formTitle={`${contractorLabel} Filter`}
                    onFilterChange={onFilterChange}
                />
                <DataGrid className="applications-contractor-list data-grid-in-modal" withWrapper={false}>
                    <DataGridHead>
                        <DataGridHeader columns={headerColumns} onSortChange={onSortChange} />
                        <DataGridFilter columns={columns} onFilterChange={onFilterChange} />
                    </DataGridHead>
                    <DataGridBody rows={rows} columns={columns} nothingFoundMessage="No Results Found." />
                </DataGrid>
                <Container fluid>
                    <DataGridPaging
                        pageNumber={pageNumber}
                        pagesCount={pagesCount}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                    />
                </Container>
                <Stack className="mt-auto justify-content-end p-3" direction="horizontal">
                    <Button variant="secondary" onClick={onCancel}>
                        Cancel
                    </Button>
                </Stack>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

const customerSearchColumns: DataGridColumnConfig[] = [
    {
        key: "fullName",
        name: "Company",
        active: "true",
        filter: "true",
        className: "w-lg-15 text-break",
    },
    {
        key: "fullAddress",
        name: "Address",
        active: "true",
        filter: "true",
        className: "w-lg-15 text-break",
    },
    {
        key: "phone",
        name: "Phone",
        active: "true",
        filter: "true",
    },
    {
        key: "email",
        name: "Email",
        active: "true",
        filter: "true",
        className: "text-break",
        datatype: "email",
    },
    {
        key: "technologies",
        name: "Technologies",
        active: "true",
        filter: "true",
        className: "w-lg-15 text-break",
    },
    {
        key: "services",
        name: "Services",
        active: "true",
        filter: "true",
        className: "w-lg-15 text-break",
    },
];

interface ContractorSearchProps {
    hide?: boolean;
    applicationNumber: string;
    onSelect: (contractor: any) => void;
    isContractorRequired?: boolean;
}
